import { anythingToInt } from "../../data/Calculations";

// ************************************************************************************
// **********************       STABILITÉ EMPLOI       ********************************
// ************************************************************************************

// fonction permettant de transformer le data brut en top5 pour les appellations
// retourne un tableau en ordre décroissant du top5, et pour chacune et retrouve
// une liste d'entreprises et leur contribution au poids du classement
const makeTopListAppellationsForBarChart = (data) => {
  const listeAppellationsParNb = {};

  // Iterate over the data array and update the listeAppellationsParNb and employeurs for each 'appelation' value
  data.forEach((item) => { // on prend d'abord les trois variables qu'on a besoin
    const appellation = item['appelation'].toLowerCase();       // l'appellation
    const posteCount = anythingToInt(item['nombre_de_poste']);  // le nombre de postes
    const employeurName = item['employeur'];                    // l'employeur
    if (listeAppellationsParNb[appellation]) {  // si la liste contient déjà l'appellation
      listeAppellationsParNb[appellation].count += posteCount;  // on ajoute le nb au compte
      const existingEmployeur = listeAppellationsParNb[appellation].employeurs.find((p) => p.name === employeurName);
      if (existingEmployeur) {  // si l'employeur pour cette appellation est déjà inscrit
        existingEmployeur.amount += posteCount; // on ajoute sa contribution
      } else {                  // sinon on ajoute l'employeur et sa contribution à la liste de l'appellation
        listeAppellationsParNb[appellation].employeurs.push({ name: employeurName, amount: posteCount });
      }
    } else {                    // sinon on ajoute l'appelation à la liste
      listeAppellationsParNb[appellation] = {
        count: posteCount,      // avec son compte
        employeurs: [{ name: employeurName, amount: posteCount }], // et l'employeur contribuant
      };
    }
  });

  // Convert the listeAppellationsParNb object into an array of objects with the desired format
  const topList = Object.keys(listeAppellationsParNb).map((appellation) => {
    const employeurs = listeAppellationsParNb[appellation].employeurs
      .sort((a, b) => b.amount - a.amount) // Sort the employeurs array in descending order of amount
      .map((p) => { return { name: p.name, amount: p.amount } });
    return {
      name: appellation,
      value: listeAppellationsParNb[appellation].count,
      participants: employeurs,
    };
  });

  // Sort the topList in descending order by count
  const sortedList = topList.sort((a, b) => b.value - a.value);

  return sortedList;
}

// retourne le pourcentage de la stabilité emploi
// une note est attribuée pour 4 champs sur un total de 11 pts
const calculStabiliteEmploi = (data) => {
  let totalStability = 0;
  const ajustement = 2
  for (let i = 0; i < data.length; i++) {
    const jobPosting = data[i];
    let stabilityScore = 0;
    stabilityScore += jobPosting.type_emploi_note || (2 / 2) // no zero
    stabilityScore += jobPosting.duree_emploi_note || (4 / 2) // no twos
    stabilityScore += jobPosting.horaire_note || (4 / 2) // no twos and threes
    stabilityScore += jobPosting.disponibilite_note || (1 / 2) // no twos
    const stabilityRate = (stabilityScore - ajustement) / (11 - ajustement) * 100
    totalStability += stabilityRate;
  }
  const averageStability = totalStability / data.length;
  return averageStability;
}

// retourne le data pour un pie chart sur les types d'emploi (temps plein/partiel/etc)
const calculerPourcentageTypeEmploiPieChart = (data) => {
  let tempsPlein = 0
  let tempsPartiel = 0
  let tempsPossibilitePlein = 0
  let autre = 0
  data.forEach((item) => {
    if (item.type_emploi === 'temps plein') tempsPlein++
    else if (item.type_emploi === 'temps partiel') tempsPartiel++
    else if (item.type_emploi === 'temps partiel, possibilité de temps plein') tempsPossibilitePlein++
    else autre++
  })

  const totalDesPostings = data.length

  return [ // le tableau qui est retourné est divisé en trois champs :
    { name: 'Temps partiel', value: tempsPartiel / totalDesPostings * 100 },
    { name: 'Possibilité de temps plein', value: tempsPossibilitePlein / totalDesPostings * 100 },
    { name: 'Temps plein', value: tempsPlein / totalDesPostings * 100 },
  ];
}

// retourne le data pour un line chart du nb de poste temps plein par date
const nbTempsPleinParDateForLineChart = (data) => {
  const countsByDate = [];
  for (const date in data) {
    const jobPostings = data[date];
    const count = jobPostings.reduce((acc, jobPosting) => {
      var nbDePostes = parseInt(jobPosting.nombre_de_poste)
      if (isNaN(nbDePostes)) nbDePostes = 1;
      if (jobPosting.type_emploi === "temps plein") {
        return acc + nbDePostes;
      } else {
        return acc;
      }
    }, 0);
    countsByDate.push({ name: date, value: count, weight: 1 });
  }
  return countsByDate;
}

// retourne le data pour un pie chart des horaires disponibles pour les offres (jour/soir/etc.)
const calculerPourcentageTypeHoraire = (data) => {
  let jour = 0; let soir = 0; let nuit = 0; let autre = 0;
  let jourSoir = 0; let jourNuit = 0; let soirNuit = 0;
  let jourSoirNuit = 0;

  data.forEach((item) => {
    if (item.horaire === 'jour') jour++
    else if (item.horaire === 'soir') soir++
    else if (item.horaire === 'nuit') nuit++
    else if (item.horaire === 'jour, soir') jourSoir++
    else if (item.horaire === 'jour, nuit') jourNuit++
    else if (item.horaire === 'soir, nuit') soirNuit++
    else if (item.horaire === 'jour, soir, nuit') jourSoirNuit++
    else autre++
  })

  const totalDesPostings = data.length

  return [ // les 'autres' sont considérés comme 'jour'
    { name: 'Nuit', value: (nuit + (jourNuit / 2) + (soirNuit / 2) + (jourSoirNuit / 3)) / totalDesPostings * 100 },
    { name: 'Soir', value: (soir + (jourSoir / 2) + (soirNuit / 2) + (jourSoirNuit / 3)) / totalDesPostings * 100 },
    { name: 'Jour', value: (jour + (jourSoir / 2) + (jourNuit / 2) + (jourSoirNuit / 3) + autre) / totalDesPostings * 100 },
    //{ name: 'Non-disponible', value: autre / totalDesPostings * 100 },
    //{ name: 'Jour, soir', value: jourSoir / totalDesPostings * 100 },
    //{ name: 'Jour, nuit', value: jourNuit / totalDesPostings * 100 },
    //{ name: 'Soir, nuit', value: soirNuit / totalDesPostings * 100 },
    //{ name: 'Jour, soir, nuit', value: jourSoirNuit / totalDesPostings * 100 },
  ]

}

// retourne le tableau de data pour les horaires === "jour" pour un linechart
const calculerNbHoraireEnJourneeForLineChart = (data) => {
  const countsByDate = []
  for (const date in data) {
    const jobPostings = data[date]
    const count = jobPostings.reduce((acc, jobPosting) => {
      if (jobPosting.horaire === 'jour') {
        return acc + parseInt(jobPosting.nombre_de_poste)
      } else {
        return acc
      }
    }, 0)
    countsByDate.push({ name: date, value: count })
  }
  return countsByDate
}

const calculerDureeEmploiForPieChart = (data) => {
  let occasionnel = 0; let permanent = 0; let saisonnier = 0;
  let freelance = 0; let temporaire = 0; let autre = 0;

  data.forEach((item) => {
    if (item.duree_emploi === 'occasionnel') occasionnel++
    else if (item.duree_emploi === 'permanent') permanent++
    else if (item.duree_emploi === 'saisonnier') saisonnier++
    else if (item.duree_emploi === 'freelance') freelance++
    else if (item.duree_emploi === 'temporaire') temporaire++
    else autre++
  })

  const totalOffres = data.length - autre // les non-disponibles sont retirés du calcul entirely

  return [
    { name: 'Temporaire', value: (temporaire + freelance) / totalOffres * 100 },
    { name: 'Saisonnier', value: saisonnier / totalOffres * 100 },
    { name: 'Permanent', value: permanent / totalOffres * 100 },
    //{ name: 'Freelance', value: freelance / totalOffres * 100  },
    //{ name: 'Non-disponible', value: autre / totalOffres * 100 },
    { name: 'Occasionnel', value: occasionnel / totalOffres * 100 },
  ]
}

const calculerEvolutionEmploiPermanentLineChart = (data) => {
  const countsByDate = []
  for (const date in data) {
    const jobPostings = data[date]
    const count = jobPostings.reduce((acc, jobPosting) => {
      var nbDePostes = parseInt(jobPosting.nombre_de_poste)
      if (isNaN(nbDePostes)) nbDePostes = 1;
      if (jobPosting.duree_emploi === 'permanent') {
        return acc + nbDePostes
      } else {
        return acc
      }
    }, 0)

    countsByDate.push({ name: date, value: count, weight: 1 })
  }
  return countsByDate
}

// ************************************************************************************
// **********************       EXIGENCES DE L'EMPLOYEUR       ************************
// ************************************************************************************

const calculerIndiceExigenceEmployeur = (data) => {
  let totalExigence = 0
  const ajustement = 2 // l'ajustement prend en compte que langue et expérience ne peuvent avoir score de 0

  for (let i = 0; i < data.length; i++) {
    const jobPosting = data[i]
    let exigenceScore = 0
    exigenceScore += jobPosting.langues_note || 2 / 2
    exigenceScore += jobPosting.experience_note || 7 / 2
    exigenceScore += jobPosting.diplome_note || 5 / 2
    const exigenceRate = (exigenceScore - ajustement) / (13 - ajustement) * 100
    totalExigence += exigenceRate
  }

  const averageExigence = totalExigence / data.length
  return averageExigence
}

const classerNiveauEtudesRequis = (data) => {
  const diplomeCount = {};

  let ND = 0

  data.forEach((obj) => {
    const diplome = obj.diplome;
    if (diplomeCount[diplome]) {
      diplomeCount[diplome]++;
    } else {
      diplomeCount[diplome] = 1;
    }
  });

  const categories = {
    'Études collégiales': 0,
    'Études secondaires': 0,
    'Aucun diplôme exigé': 0,
    'Formation professionnelle': 0,
    'Diplôme universitaire': 0,
  }

  Object.keys(diplomeCount).forEach((diplome) => {
    const count = diplomeCount[diplome];

    switch (diplome) {
      case 'Aucun diplôme exigé':
        categories['Aucun diplôme exigé'] += count;
        break;
      case 'Diplôme d’études secondaires':
        categories['Études secondaires'] += count;
        break;
      case "Diplôme d'études professionnelles":
        categories['Formation professionnelle'] += count;
        break;
      case 'Diplôme d’études collégiales':
      case 'Attestation d’études collégiales':
      case 'Attestation de spécialisation professionnelle':
        categories['Études collégiales'] += count;
        break;
      case 'Baccalauréat':
      case "Certificat ou diplôme d'études supérieures spécialisées":
      case 'Maîtrise':
      case 'Doctorat':
        categories['Diplôme universitaire'] += count;
        break;
      default:
        ND += count;
    }
  });

  const result = Object.keys(categories).map((category) => {
    return { name: category, count: categories[category] };
  });

  const totalResults = result.reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);

  const percentageResults = result.map((category) => {
    return {
      name: category.name,
      value: ((category.count / totalResults) * 100)
    };
  });

  return percentageResults;
}

const classerNiveauExperienceRequis = (data) => {
  let aucune = 0; let de1a3 = 0; let de3a5 = 0; let plusDe5 = 0; let autre = 0;

  data.forEach((item) => {
    if (item.experience === 'Aucune expérience') aucune++
    else if (item.experience === '1 à 3 ans') de1a3++
    else if (item.experience === '3 à 5 ans') de3a5++
    else if (item.experience === '5 ans ou plus') plusDe5++
    else autre++
  })

  const totalOffres = data.length

  return [
    { name: '1 à 3 ans', value: de1a3 / totalOffres * 100 },
    { name: '5 ans ou plus', value: plusDe5 / totalOffres * 100 },
    { name: 'Aucune expérience', value: aucune / totalOffres * 100 },
    { name: '3 à 5 ans', value: de3a5 / totalOffres * 100 },
    { name: 'Non-disponible', value: autre / totalOffres * 100 },
  ]
}

const classerLangueDemandeePieChart = (data) => {
  let francais = 0; let anglais = 0; let franglais = 0; let autre = 0;

  data.forEach((item) => {
    if (item.langues_poste === 'français') francais++
    else if (item.langues_poste === 'anglais') anglais++
    else if (item.langues_poste === 'français et anglais') franglais++
    else autre++
  })

  const totalOffres = data.length

  return [
    { name: 'Français', value: (francais / totalOffres) * 100 },
    { name: 'Anglais', value: (anglais / totalOffres) * 100 },
    { name: 'Français/Anglais', value: (franglais / totalOffres) * 100 },
    { name: 'Autre', value: (autre / totalOffres) * 100 }
  ]
}

const calculerTop5CompetencesSoftSkills = (data) => {
  const softSkillCategories = Object.values(data).filter(item => item["Catégorie"] === "SKILL_SOFT")

  const countMap = {};
  softSkillCategories.forEach(item => {
    const skillName = item["Nom de la compétence"];
    const repetition = item["Répétition"];
    if (countMap[skillName]) countMap[skillName] += repetition;
    else countMap[skillName] = repetition;
  });

  const result = Object.entries(countMap).map(([name, value]) => ({ name, value })).sort((a, b) => b.value - a.value);
  return result;
}

const calculerTop5CompetencesHardSkills = (data) => {
  const hardSkillCategories = Object.values(data).filter(item => item["Catégorie"] === "SKILL_HARD")

  const countMap = {};
  hardSkillCategories.forEach(item => {
    const skillName = item["Nom de la compétence"];
    const repetition = item["Répétition"];
    if (countMap[skillName]) countMap[skillName] += repetition;
    else countMap[skillName] = repetition;
  });

  const result = Object.entries(countMap).map(([name, value]) => ({ name, value })).sort((a, b) => b.value - a.value);
  return result;
}

const calculerTop5Qualifications = (data) => {
  const qualificationCategories = Object.values(data).filter(item => item["Catégorie"] === "QUAL")

  const countMap = {};
  qualificationCategories.forEach(item => {
    const skillName = item["Nom de la compétence"];
    const repetition = item["Répétition"];
    if (countMap[skillName]) countMap[skillName] += repetition;
    else countMap[skillName] = repetition;
  });

  const result = Object.entries(countMap).map(([name, value]) => ({ name, value })).sort((a, b) => b.value - a.value);
  return result;
}

const calculerTop5Experiences = (data) => {
  const experienceCategories = Object.values(data).filter(item => item["Catégorie"] === "EXP")

  const countMap = {};
  experienceCategories.forEach(item => {
    const skillName = item["Nom de la compétence"];
    const repetition = item["Répétition"];
    if (countMap[skillName]) countMap[skillName] += repetition;
    else countMap[skillName] = repetition;
  });

  const result = Object.entries(countMap).map(([name, value]) => ({ name, value })).sort((a, b) => b.value - a.value);
  return result;
}

const calculerTop5Outils = (data) => {
  const outilCategories = Object.values(data).filter(item => item["Catégorie"] === "OUTIL")

  const countMap = {};
  outilCategories.forEach(item => {
    const skillName = item["Nom de la compétence"];
    const repetition = item["Répétition"];
    if (countMap[skillName]) countMap[skillName] += repetition;
    else countMap[skillName] = repetition;
  });

  const result = Object.entries(countMap).map(([name, value]) => ({ name, value })).sort((a, b) => b.value - a.value);
  return result;
}

// ************************************************************************************
// **********************       STABILITÉ EMPLOI       ********************************
// ************************************************************************************

const calculTauxAvantagesSociauxPourIndicator = (data) => {
  let count = 0;

  data.forEach((item) => {
    var as = item.avantages_sociaux
    if (as !== 'ND' && as !== '' && as?.$numberDouble !== 'NaN') count++
  });

  const percentage = (count / data.length) * 100

  return percentage
}

const determinerTopAvantagesSociauxPourBarChart = (data) => {
  const valueCounts = new Map();

  data.forEach((item) => {
    const avantages = item.avantages_sociaux
    if (avantages && avantages !== "ND" && avantages.trim() !== '') {
      const values = avantages.split(/,|\n/).map(value => value.trim());
      const companyName = item.employeur;
      values.forEach((value) => {
        if (
          value !== '' &&
          !value.includes('$') &&
          value !== 'et' &&
          !value.includes('etc') &&
          !value.includes('!') &&
          !value.includes('*')) {
          if (valueCounts.has(value)) {
            const countObj = valueCounts.get(value);
            countObj.count++;
            if (countObj.companies.hasOwnProperty(companyName)) {
              countObj.companies[companyName]++;
            } else {
              countObj.companies[companyName] = 1;
            }
          } else {
            const companies = {};
            companies[companyName] = 1;
            valueCounts.set(value, { count: 1, companies: companies });
          }
        }
      });
    }
  });

  const sortedCounts = Array.from(valueCounts).sort((a, b) => b[1].count - a[1].count);
  const filteredCounts = sortedCounts.filter((count) => count[0].length <= 30);
  const result = filteredCounts.map((count) => ({ name: count[0], value: count[1].count, companies: count[1].companies }));
  return result;
}

// ************************************************************************************
// **********************       SALAIRES       ****************************************
// ************************************************************************************

// retourne une moyenne de salaire pour la sélection, format indicator :
const moyenneSalaireIndicator = (data) => {
  const filteredData = data?.filter((item) => { return item['salaire_min'] > 10 && !isNaN(item['salaire_min']) })
  const somme = filteredData?.reduce((acc, item) => { return acc + item['salaire_min'] }, 0)
  const moyenne = somme / filteredData?.length;
  return moyenne // retourne simplement la moyenne pour la liste
}

// retourne le data en format item -> pour -> moyenne
// fait la moyenne d'un champs 'value' pour chaque item
const averageSalaryByDateForLineChart = (data) => {
  const lineChartData = []

  Object.keys(data).forEach((item) => {

    const filteredData = data[item].filter((item) => { // on enlève les entrées en bas de 10$/h ou null
      return item['salaire_min'] > 10 && !isNaN(item['salaire_min'])
    })

    var nbDePostes = 0

    const somme = filteredData.reduce((acc, item) => { // on totalise le salaire * nombre de poste pour l'annonce
      nbDePostes += parseInt(item.nombre_de_poste)      // on keep track du nombre de poste en jeu dans le calcul
      return acc + item.salaire_min * parseInt(item.nombre_de_poste)
    }, 0)

    const averageForItem = somme / nbDePostes // on divise par le nombre de poste

    if (averageForItem) lineChartData.push({ name: item, value: averageForItem.toFixed(2), weight: nbDePostes });
  });

  return lineChartData;
}

const classerTopEmploisPlusPayant = (data) => {
  data.sort((a, b) => b.salaire_min - a.salaire_min);
  const sortedList = data.map(({ salaire_min: value, appelation: name, employeur: participants }) => ({ value, name, participants }));
  const uniqueList = sortedList.reduce((acc, cur) => {
    const { value, name, participants } = cur;
    const hasDuplicate = acc.some((item) => item.value === value && item.name === name && item.participants === participants);
    if (!hasDuplicate) {
      acc.push(cur);
    }
    return acc;
  }, []);

  return uniqueList;
};

const tableauSalaireMoyenParSecteur = (data) => {
}

const filterOutShortStrings = (data, cutLength) => {
  const result = data.filter(item => {
    if (item["Nom de la compétence"]) {
      const trimmedString = item["Nom de la compétence"].trim();
      if (trimmedString.length >= cutLength) {
        return true;
      }
    }
    return false;
  });
  return result;
};


export {
  averageSalaryByDateForLineChart,
  determinerTopAvantagesSociauxPourBarChart,
  calculTauxAvantagesSociauxPourIndicator,
  moyenneSalaireIndicator,
  makeTopListAppellationsForBarChart,
  calculStabiliteEmploi,
  nbTempsPleinParDateForLineChart,
  calculerPourcentageTypeEmploiPieChart,
  calculerPourcentageTypeHoraire,
  calculerNbHoraireEnJourneeForLineChart,
  calculerIndiceExigenceEmployeur,
  classerNiveauEtudesRequis,
  calculerDureeEmploiForPieChart,
  calculerEvolutionEmploiPermanentLineChart,
  classerNiveauExperienceRequis,
  classerLangueDemandeePieChart,
  classerTopEmploisPlusPayant,
  calculerTop5CompetencesHardSkills,
  calculerTop5CompetencesSoftSkills,
  calculerTop5Qualifications,
  calculerTop5Experiences,
  calculerTop5Outils,
  filterOutShortStrings,
}





