import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import {
  addMrcToList,
  removeMrcFromList,
  clearMrcList,
  setLegendValues,
} from "../../../reducers/mapStore";
import { couleursRegions } from "../../../constants/CodesCouleurs";
import { calculEmploisSelonCoordonnees } from "../../../data/CalculsMap";
import {
  formatIdName,
  capitalizeString,
  parseDate,
  logAllLayers,
  formatLargeDollarNumber,
} from "./UtilityFunctions";
import {
  addChoropleth,
  removeChoropleth,
  showRegionColors,
  hideRegionColors,
} from "./Choropleth";
//import Graphique from './Graphique';
import "./Map.css";
import { filterDataBySecteur } from "../../../data/Calculations";
import mrcNamesList from "../../../constants/NomsDesMrcs";
import { useTheme } from "@mui/material"
import { tokens } from "../../../theme";
import { setComputerIsThinking } from "../../../reducers/dataStore";
//import { filterDataByDate } from '../../../data/Calculations';

const Map = (props) => {
  //mapboxgl.accessToken = 'pk.eyJ1IjoiZnJhbmNpc2RvbW9udGUiLCJhIjoiY2xkdnY1emNyMDE5MDNvcWZsZW1wZXM5YyJ9.FPDEhJku9aLVrtCHnXv_RA'; // francis's's
  mapboxgl.accessToken = "pk.eyJ1IjoibmlnbGVzaWFzMTk5NSIsImEiOiJja3R2dnFjdWcyZTE5MnJycjFkOTY5MHA0In0.W2uW3EwEKwAvqoflTfzHWA";
  //mapboxgl.accessToken = 'pk.eyJ1IjoibmlnbGVzaWFzMTk5NSIsImEiOiJja3Bxd2UxNHYwMGt5MnZvNTZ2YzJ4Y3ZqIn0.vOCgBMpRh4eopsvmGcsiVw'
  //const mrcLayerSource = 'Quebec-MRC-Surfaces-Simplifie-b20siz' // francis's's
  const mrcLayerSource = "Quebec-Regions-Surfaces-S-a6iwnb";
  const source = "mapbox://niglesias1995.a2uxfkob";
  // Quebec-MRC-Surfaces-S-11pzvo
  // Quebec-Regions-Surfaces-S-a6iwnb
  // niglesias1995.a2uxfkob
  //const mapStyle = 'mapbox://styles/francisdomonte/cldxj8lj9001u01oe8yop3qz1/draft'
  const mapStyle = "mapbox://styles/niglesias1995/clfwx310m001601pefskui9sy";
  const fadeDuration = 150;
  const [isLoading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch(); // pour utiliser les fonctions de redux
  const [zoomLevel, setZoomLevel] = useState(7.2);
  //const [toggleId, setToggleId] = useState(true)
  const averagesForEachMrcRef = useRef(null);
  const [valuesForPopups, setValuesForPopups] = useState();
  const regionsDisponibles = useSelector((state) => state.mapStore.regionsDisponibles);
  const periode = useSelector((state) => state.mapStore.periode);
  const secteurActivite = useSelector((state) => state.mapStore.secteurActivite);
  const typePoste = useSelector((state) => state.mapStore.typePoste);
  //const donneePopup = useSelector((state) => state.mapStore.donneePopup)
  const choixDeChoropleth = useSelector((state) => state.mapStore.choropleth);
  const mrcList = useSelector((state) => state.mapStore.mrcList);
  const entrepriseId = useSelector((state) => state.dataStore.entrepriseId);
  const jobListings = useSelector((state) => state.dataStore.jobListings);
  const computerIsThinking = useSelector((state) => state.dataStore.computerIsThinking);

  //const popupHideStyle = { display: "none" };

  // ************************************************************************
  // initalise tous les composants de départ lors du chargement de la carte :
  // ************************************************************************

  useEffect(() => {
    createMap(); // crée la map
    dispatch(clearMrcList()); // initialise la liste de mrc à vide
    initRegionLayer(); // ajoute les couleurs aux régions
    initMrcLabels(); // ajoute les noms des mrc à la carte

    // callback pour retirer la carte
    return () => {
      props.mapRef.current?.remove();
    }
  }, []);

  useEffect(() => {
    // initialise les fonctions on-click
    props.mapRef.current?.on("click", "quebec-regions-surfaces", onClickLayer);
    return () => {
      // on doit updater les onClicks pour rafraichir les graphiques de popup
      props.mapRef.current?.off(
        "click",
        "quebec-regions-surfaces",
        onClickLayer
      );
    };
  }, [choixDeChoropleth]);

  // ******************************************************
  // les fonctions d'initialisation :
  // ******************************************************

  const createMap = () => {
    const mapObject = new mapboxgl.Map({
      container: "map", // se colle au div avec id='map'
      style: mapStyle, // style utilisé dans mapbox studio
      center: [-73.31, 47], // position de départ lors du chargement
      pitch: 30, // angle de la caméra
      minZoom: 5, // zoom minimum
      zoom: zoomLevel, // zoom de départ
      doubleClickZoom: false, // le doubleclick-to-zoom est désactivé pour ne pas bousiller la sélection
      attributionControl: false, // retire les liens mapbox au bas de la carte **bonne pratique??**
    });
    mapObject.setMaxBounds([
      [-78, 45], // Southwest coordinates [westbound, southbound]
      [-60, 61]  // Northeast coordinates [eastbound, northbound]
    ]);

    props.setMapRef(mapObject); // pour les manipulations, on render la carte qu'une seule fois et
    mapObject.on('style.load', () => showHiddenLayers(mapObject)); // pour montrer les rues
  }; // ...on utilise sa référence dans le component 'Carte'

  const initRegionLayer = () => {
    // initialise les couleurs des régions
    // fonction permettant de seulement coloriser les régions disponibles
    const modifyCouleursRegions = (couleursRegions, regionsDisponibles) => {
      return couleursRegions.map((couleur) => {
        if (regionsDisponibles.includes(couleur[0])) return couleur;
        else return [couleur[0], `${colors.mapColors[100]}`];
      }); // else = les régions non-disponibles sont grisées
    };
    props.mapRef.current?.on("load", () => {
      const couleurs = modifyCouleursRegions(
        couleursRegions,
        regionsDisponibles
      );
      props.mapRef.current.setPaintProperty(
        "quebec-regions-surfaces",
        "fill-color",
        [
          "match",
          ["get", "MRS_NM_REG"],
          ...couleurs.reduce(
            (acc, region) => [...acc, region[0], region[1]],
            []
          ),
          "transparent",
        ]
      );
      props.mapRef.current.setPaintProperty(
        "quebec-regions-surfaces",
        "fill-opacity-transition",
        { duration: fadeDuration }
      );
      props.mapRef.current.setPaintProperty(
        "quebec-regions-surfaces",
        "fill-opacity",
        1
      );

      setLoading(false);

    });
  };

  const initMrcLabels = () => {
    // initialise les labels des mrcs
    props.mapRef.current?.on("load", () => {
      if (!props.mapRef.current.getLayer("mrc-labels-layer")) {
        props.mapRef.current.addLayer({
          id: "mrc-labels-layer",
          type: "symbol",
          source: source,
          "source-layer": mrcLayerSource,
          layout: {
            "text-field": ["get", "MRS_NM_MRC"],
            "text-font": ["Open Sans Regular"],
            "text-size": 10,
            "text-anchor": "top",
          },
          paint: { "text-color": "#999", "text-opacity": 0.75 },
        });
        // move the layer to be same level as other labels
        props.mapRef.current.moveLayer(
          "mrc-labels-layer",
          "settlement-subdivision-label"
        );
        // define the opacity and transition time of the line layer for mrc divisions
        props.mapRef.current.setPaintProperty(
          "quebec-mrc-lignes",
          "line-opacity",
          0,
          "line-opacity-transition",
          { duration: fadeDuration }
        );
      }
    });
  };
  // ***** ici on gère les onClick events *****
  // ------------------------------------------
  const onClickLayer = (e) => {
    // lorsqu'on click sur une mrc
    const features = e.target.queryRenderedFeatures(e.point); // on recoit les infos
    const mrcLayer = features.find(
      (f) => f.layer.id === "quebec-regions-surfaces"
    ); // de cette layer
    const clickedMrc = mrcLayer?.properties?.MRS_NM_MRC; // on prend le nom de la mrc
    const clickedReg = mrcLayer?.properties?.MRS_NM_REG; // et le nom de la région
    const coor = [e.lngLat.lng, e.lngLat.lat]; // et les coordonnées du click
    const mrc = `mrc-${clickedMrc}-select-pattern`; // on test si sélection existe
    if (props.mapRef.current.getLayer(mrc)) {
      // si mrc déjà sélectionnée
      removeMrcSelection(clickedMrc); // on déselectionne
      removePopupGraph(clickedMrc); // on retire la popup
      dispatch(removeMrcFromList(clickedMrc)); // on ajuste la liste de mrc
    } else {
      // si mrc pas sélectionnée
      addMrcSelection(clickedMrc); // on sélectionne
      addPopupGraph(clickedMrc, clickedReg, coor); // on ajoute un popup
      dispatch(addMrcToList([clickedMrc, clickedReg, coor])); // on ajoute aux listes
    }
  };

  const showHiddenLayers = (map) => {
    const hiddenLayerIds = [
      'tunnel-simple',
      'road-simple',
    ];

    hiddenLayerIds.forEach((layerId) => {
      const layer = map.getLayer(layerId);
      if (layer) {
        map.setLayoutProperty(layerId, 'visibility', 'visible');
        map.setPaintProperty(layerId, 'line-color', '#000000');
        map.setPaintProperty(layerId, 'line-opacity', 0.15);
      }
    });

    map.triggerRepaint();
  };

  // *************************************************************************
  // useEffect pour jobDots:
  // *************************************************************************

  useEffect(() => {
    if (props.showJobDots) dispatch(setComputerIsThinking(true))
    const timeoutId = setTimeout(() => {
      if (props.showJobDots) {
        // on filtre pour filterBar --------------------------------------------------
        const filteredByDate = filterDataByDate(jobListings, periode[1]);
        var filteredBySecteur = {};
        regionsDisponibles.forEach((region) => {
          const regionalData = filteredByDate[region];
          const filteredData = filterDataBySecteur(
            regionalData,
            secteurActivite,
            typePoste
          );
          filteredBySecteur[region] = filteredData;
        }); // ----------------------------------------------------------------------------

        mrcNamesList.forEach((item) => {
          if (item.REG === regionsDisponibles[0]) {
            const mrc = item.MRC;
            removeJobDots(mrc);
            const dataForDots = calculEmploisSelonCoordonnees(
              filteredBySecteur[regionsDisponibles[0]],
              mrc,
              entrepriseId
            );
            addJobDots(dataForDots, mrc, regionsDisponibles[0]);
          }
        });
      } else
        mrcNamesList.forEach((item) => {
          const mrc = item.MRC;
          removeJobDots(mrc);
        });
      dispatch(setComputerIsThinking(false))
    }, 350);

    return () => {
      clearTimeout(timeoutId);
    }
  }, [periode, secteurActivite, typePoste, props.showJobDots]);

  // *************************************************************************
  // useEffect pour popups: (pour update lorsqu'on change les données)
  // *************************************************************************

  useEffect(() => {
    document.querySelectorAll(".popup").forEach((popup) => popup.remove());
    mrcList.forEach((item) => {
      addPopupGraph(item[0], item[1], item[2]);
    });
  }, [valuesForPopups]);

  useEffect(() => {
    if (choixDeChoropleth === undefined) {
      document.querySelectorAll(".popup").forEach((popup) => popup.remove());

    }
  }, [choixDeChoropleth])

  // *************************************************************************
  // useEffect pour choropleths: (pour update lorsqu'on change les données)
  // *************************************************************************

  useEffect(() => {
    // useEffect qui génère les choropleths lorsqu'on change les filtres
    if (props.mapRef.current) handleChoropleth();
  }, [choixDeChoropleth, periode, secteurActivite, typePoste]);

  useEffect(() => {
    // useEffet qui génère les choropleths lorsqu'on arrive sur la carte à partir d'un autre onglet
    props.mapRef.current.on("load", () => {
      props.mapRef.current.getLayer("mrc-choropleth-layer") &&
        props.mapRef.current.removeLayer("mrc-choropleth-layer");
      handleChoropleth();
    });
  }, []);

  // *************************************************************************
  // fonctions pour choropleths:
  // *************************************************************************

  /*   const generateChoroplethId = () => { // on doit générer un id différent pour le choropleth à chaque rendu
      if (toggleId) var id = ['mrc-choropleth-layer1', 'mrc-choropleth-layer2']
      else var id = ['mrc-choropleth-layer2', 'mrc-choropleth-layer1']
      setToggleId(!toggleId) // permet ainsi de fade-in un nouveau choropleth layer pendant que l'ancien fade-out
      return id               // puisqu'on ne peut pas avoir deux layers avec le même id en simultané
    }                          // on toggle alors entre deux id différents dans un array */

  const handleChoropleth = () => {
    //if (choixDeChoropleth === undefined) return; // si c'est le premier chargement
    if (
      choixDeChoropleth === "Div. régionales" ||
      choixDeChoropleth === undefined ||
      choixDeChoropleth === "Retour à l'accueil" ||
      choixDeChoropleth === "Sélections PDF"
    ) {
      // choix par défaut/init
      removeChoropleth(props.mapRef, "mrc-choropleth-layer");
      showRegionColors(props.mapRef);
    } else {
      try {
        hideRegionColors(props.mapRef); // d'abord on enlève la couche par défaut
        // on filtre pour filterBar --------------------------------------------------
        const filteredByDate = filterDataByDate(jobListings, periode[1]);
        var filteredBySecteur = {};
        regionsDisponibles.forEach((region) => {
          const regionalData = filteredByDate[region];
          const filteredData = filterDataBySecteur(
            regionalData,
            secteurActivite,
            typePoste
          );
          filteredBySecteur[region] = filteredData;
        });
        // ---------------------------------------------------------------------------

        const values = addChoropleth(
          // retourne values -> mrc data + les stops pour la légende -> [0] = mrcData [1] = valeurs pour légende
          props.mapRef, // et ajoute un choropleth sur la map
          choixDeChoropleth, // pour ce type de donnee sélectionné
          filteredBySecteur, // à l'aide de ce data
          mrcLayerSource, // se basant sur cette layer
          source,
          "mrc-choropleth-layer",
          colors.mapColors[100],
        );
        dispatch(setLegendValues(values[1])); // set les valeurs de la légende dans le store
        averagesForEachMrcRef.current = values[0]; // set les valeurs calculés pour les popups
        setValuesForPopups(values[0]); // useState setter pour forcer un reredener des popups
      } catch {
        console.error();
      }
    }
  };

  // ******************************************************
  // fonctions pour sélectionner les MRCs
  // ******************************************************

  const addMrcSelection = (mrc) => {
    // function qui crée une layer pour la mrc sélectionnée
    props.mapRef.current.addLayer({
      id: `mrc-${mrc}-select-pattern`,
      type: "fill",
      source: source,
      "source-layer": mrcLayerSource,
      filter: ["==", "MRS_NM_MRC", mrc],
      paint: {
        "fill-color": "rgb(20,20,20)",
        "fill-opacity": 0,
        "fill-opacity-transition": { duration: 125 }, // pour fade-in
      },
    });
    props.mapRef.current.setPaintProperty(
      `mrc-${mrc}-select-pattern`,
      "fill-opacity",
      0.05
    );
  };

  const removeMrcSelection = (mrc) => {
    // function qui delete la layer de mrc sélectionnée
    props.mapRef.current.setPaintProperty(
      `mrc-${mrc}-select-pattern`,
      "fill-opacity",
      0
    );
    setTimeout(() => {
      // pour fade-out
      props.mapRef.current.getLayer(`mrc-${mrc}-select-pattern`) &&
        props.mapRef.current.removeLayer(`mrc-${mrc}-select-pattern`);
    }, 125);
  };

  // ******************************************************
  // fonction pour filtrer le data selon plage horaire
  // ******************************************************

  const filterDataByDate = (data, nbDays) => {
    const dayMs = 86400000;
    const currentDate = new Date();
    var filteredByDate = {};

    regionsDisponibles.forEach((region) => {
      const regionalData = data[region];
      const filteredData = regionalData.filter((item) => {
        const date = parseDate(item.publiee_depuis_le);
        const timeDiff = currentDate.getTime() - date.getTime();
        return timeDiff <= dayMs * nbDays;
      });
      filteredByDate[region] = filteredData;
    });
    return filteredByDate;
  };

  // ******************************************************
  // fonctions pour ajouter un popup
  // ******************************************************

  const setPopupContent = (mrc) => {
    try {
      const value = averagesForEachMrcRef.current?.find(
        (item) => item.mrcName === mrc
      )?.value;
      if (value === -1) return "Non-Disponible";
      switch (choixDeChoropleth) {
        case "Stabilité de l'emploi":
          return `${value.toFixed(1)} %`;
          break;
        case "Exigences de l'employeur":
          return `${value.toFixed(1)} %`;
          break;
        case "Avantages sociaux":
          return `${value.toFixed(1)} %`;
          break;
        case "Salaires":
          return `${formatLargeDollarNumber(value, 3)}`;
          break;
        default:
          return;
      }
    } catch {
      return "Non-disponible";
    }
  };

  const addPopupGraph = (mrc, region, coor) => {
    if (theme.palette.mode === "dark") var popupColor = "darkmode-popup"
    const popupId = formatIdName(mrc);
    let popup = document.querySelector(`.${popupId}`); // au cas où le popup existe déjà
    if (!popup) {
      const content = setPopupContent(mrc);
      popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        anchor: "top", // pour changer l'ancrage du popup
      })
        .setLngLat([coor[0], coor[1]])
        .addClassName(`popup ${popupId} ${choixDeChoropleth === undefined ? 'hidden' : ''}`)
        .setHTML(`<h3 class="popup-text ${popupColor}">${mrc}</h3><hr class="popup-line-seperator"></hr><p>${content}</p>`)
        .addTo(props.mapRef.current);
    }
    props.mapRef.current._popups.forEach((popup) => {
      popup._classList.add("popup-graph-fade"); // pour fade-in animation, voir Map.css
    });
  };

  const removePopupGraph = (mrc) => {
    const popups = document.getElementsByClassName(formatIdName(mrc));
    while (popups.length > 0) {
      // retourne une liste dans le cas d'un doublon
      popups[0].remove();
    }
  };

  // ******************************************************
  // fonctions pour ajouter des points
  // ******************************************************

  /*   const addFeatureToMap = (mrc, region, coor) => { // function qui ajoute un popup ou feature
      if (donneePopup !== "Offres d'emploi") {
        addPopupGraph(mrc, region, coor)
      }
      else {
        if (jobListings[region]) {
          let data = calculEmploisSelonCoordonnees(jobListings[region], mrc, entrepriseId)
          addJobDots(data, mrc)
        }
      }
    } */

  const addJobDots = async (data, mrc, region) => {

    const features = data.map((d) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [d.coordinates[0], d.coordinates[1]],
      },
      properties: {
        employeur: d.employeur,
        nombre_de_poste: d.nombre_de_poste,
        size_bubble: 5 + (Math.log(d.nombre_de_poste) / Math.log(100)) * 12,
      },
    }));

    const sourceId = `dots-source-${mrc}`;
    const clusterLayerId = `dots-cluster-${mrc}`;
    const clusterCountLayerId = `dots-cluster-count-${mrc}`;
    const unclusteredLayerId = `dots-unclustered-${mrc}`;

    // Add source with cluster option
    props.mapRef.current.addSource(sourceId, {
      type: "geojson",
      data: { type: "FeatureCollection", features: features },
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 40, // Radius of each cluster when clustering points
    });

    // Add layer for clustered dots
    props.mapRef.current.addLayer({
      id: clusterLayerId,
      type: "circle",
      source: sourceId,
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#51bbd6", // color for smallest cluster
          10, "#f1f075", // color when cluster has more than 10 points
          30, "#f28cb1", // color when cluster has more than 30 points
          50, "#f0b55d", // color when cluster has more than 50 points
          200, "#f06b6b", // color when cluster has more than 200 points
        ],
        "circle-radius": [
          "step",
          ["get", "point_count"],
          15, // size for smallest cluster
          10, 20, // size when cluster has more than 10 points
          30, 25, // size when cluster has more than 30 points
          50, 30, // size when cluster has more than 50 points
          250, 35, // size when cluster has more than 200 points
        ]
      }
    });

    // Add layer for the clusters' count labels
    props.mapRef.current.addLayer({
      id: clusterCountLayerId,
      type: "symbol",
      source: sourceId,
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });

    // Add layer for unclustered points
    props.mapRef.current.addLayer({
      id: unclusteredLayerId,
      type: "circle",
      source: sourceId,
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": "rgba(75,175,175,0.9)",
        "circle-radius": ["get", "size_bubble"],
        "circle-stroke-width": 1.75,
        "circle-stroke-color": "rgba(255,255,255,0.5)",
      },
    });

    // Move layer priority to not hide city names
    //props.mapRef.current.moveLayer(dotsLayerId, "settlement-subdivision-label");

    // Add popup on hover effect
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "popup-job-fade",
    });

    props.mapRef.current.on("mouseenter", unclusteredLayerId, (e) => {
      props.mapRef.current.getCanvas().style.cursor = "pointer";
      const coordinates = e.features[0].geometry.coordinates.slice();
      const { employeur, nombre_de_poste } = e.features[0].properties;
      const html = `
        <div class="darkmode-popup">
          <h4>${capitalizeString(employeur)}</h4>
          <h5>Postes : ${nombre_de_poste}</h5>
        </div>
      `;
      popup.setLngLat(coordinates).setHTML(html).addTo(props.mapRef.current);
    });

    props.mapRef.current.on("mouseleave", unclusteredLayerId, () => {
      props.mapRef.current.getCanvas().style.cursor = "";
      popup.remove();
    });
  };

  const removeJobDots = (mrc) => {
    const sourceId = `dots-source-${mrc}`;
    const clusterLayerId = `dots-cluster-${mrc}`;
    const clusterCountLayerId = `dots-cluster-count-${mrc}`;
    const unclusteredLayerId = `dots-unclustered-${mrc}`;

    props.mapRef.current.getLayer(clusterLayerId) &&
      props.mapRef.current.removeLayer(clusterLayerId);

    props.mapRef.current.getLayer(clusterCountLayerId) &&
      props.mapRef.current.removeLayer(clusterCountLayerId);

    props.mapRef.current.getLayer(unclusteredLayerId) &&
      props.mapRef.current.removeLayer(unclusteredLayerId);

    props.mapRef.current.getSource(sourceId) &&
      props.mapRef.current.removeSource(sourceId);
  }

  // ******************************************************
  // élément qui contient la carte de mapbox-gl
  // ******************************************************

  return (
    <>
      <div className="spinner" style={{
        color: colors.primary[600],
        backgroundColor: colors.primary[400],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        fontWeight: '300',
        fontSize: '20px',
        letterSpacing: '0.01em',
        animation: 'fade 1s infinite ease-in-out',
        opacity: isLoading ? '1' : '0',
        transition: 'opacity 0.47s ease'
      }}>
        Chargement de la carte
        <span style={{ marginLeft: '10px', fontSize: '1.5em' }}>
          <span style={{ animation: 'dot 1s infinite step-start' }}>.</span>
          <span style={{ animation: 'dot 1s 0.33s infinite step-start' }}>.</span>
          <span style={{ animation: 'dot 1s 0.66s infinite step-start' }}>.</span>
        </span>
      </div>
      <div
        id="map"
        style={{
          backgroundColor: colors.primary[400],
          height: "100%",
          zIndex: 0,
          opacity: isLoading ? '0' : '1',
          transition: 'opacity 0.2s ease'
        }}
      />
    </>
  );
}


export default Map;






    // Animate circles
/*     try {
      for (let i = 0; i < features.length; i++) {
        const data = { type: 'FeatureCollection', features: features.slice(0, i + 1) };
        props.mapRef.current.getSource(sourceId).setData(data);
        await new Promise(resolve => setTimeout(resolve, 0));
      }
    } catch { console.log("populating job offers canceled") } 
    
    
    
    
    
    
    const popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
  className: "popup-job-fade",
});

props.mapRef.current.on("mouseenter", layerId, (e) => {
  props.mapRef.current.getCanvas().style.cursor = "pointer";
  const coordinates = e.features[0].geometry.coordinates.slice();
  const { employeur, nombre_de_poste } = e.features[0].properties;
  const html = `
    <div class="darkmode-popup">
      <h4>${capitalizeString(employeur)}</h4>
      <h5>Postes : ${nombre_de_poste}</h5>
    </div>
  `;
  popup.setLngLat(coordinates).setHTML(html).addTo(props.mapRef.current);
});

props.mapRef.current.on("mouseleave", layerId, () => {
  props.mapRef.current.getCanvas().style.cursor = "";
  popup.remove();
});
};
    
    
     const removeJobDots = (mrc) => {
props.mapRef.current.getLayer(`dots-layer-${mrc}`) &&
  props.mapRef.current.removeLayer(`dots-layer-${mrc}`);
props.mapRef.current.getSource(`dots-source-${mrc}`) &&
  props.mapRef.current.removeSource(`dots-source-${mrc}`);
};
    
    
    
    
    
    */