const nomsRegions = [
  "Lanaudière",
  "Montérégie",
  "Laval",
  "Montréal",
  "Laurentides",
  "Chaudière-Appalaches",
  "Gaspésie--Îles-de-la-Madeleine",
  "Centre-du-Québec",
  "Mauricie",
  "Estrie",
  "Capitale-Nationale",
  "Outaouais",
  "Abitibi-Témiscamingue",
  "Nord-du-Québec",
  "Saguenay--Lac-Saint-Jean",
  "Côte-Nord",
  "Bas-Saint-Laurent"
]

const ajustementZoom = -0.6
const coordonneesRegions = {
  "Tout le Québec": {
    center: [-71.67261368041268, 46.80023946332045],
    zoom: 5.6 + ajustementZoom,
    pitch: 0, //20
    bearing: 0
  },
  "Lanaudière": {
    center: [-73.7042, 46.25],
    zoom: 8 + ajustementZoom,
    pitch: 0, //25
    bearing: 0
  },
  "Montérégie": {
    center: [-73.56, 45.45],
    zoom: 8.25 + ajustementZoom,
    pitch: 0, //5
    bearing: 0
  },
  "Laval": {
    center: [-73.74946946007071, 45.6065943400894],
    zoom: 10.45 + ajustementZoom,
    pitch: 0, //20
    bearing: 0
  },
  "Montréal": {
    center: [-73.66724568687947, 45.51950941745139],
    zoom: 10 + ajustementZoom,
    pitch: 0, //20
    bearing: 0
  },
  "Laurentides": {
    center: [-74.49915302677384, 46.1677896371238],
    zoom: 7.7 + ajustementZoom,
    pitch: 0, //50
    bearing: 0
  },
  "Centre-du-Québec": {
    center: [-72.30621601855115, 46.07354133543899],
    zoom: 8.4 + ajustementZoom,
    pitch: 0, //33.5
    bearing: 0
  },
  "Mauricie": {
    center: [-73.0050443141795, 46.95884957773296],
    zoom: 7.9 + ajustementZoom,
    pitch: 44.5, //44.5
    bearing: 0
  },
  "Capitale-Nationale": {
    center: [-71.25616936964758, 47.23648876975258],
    zoom: 7.8 + ajustementZoom,
    pitch: 0, //50
    bearing: 0 //-12
  },
  "Estrie": {
    center: [-71.97204036395901, 45.53495372301697],
    zoom: 8 + ajustementZoom,
    pitch: 0, //32.5
    bearing: 0
  },
  "Chaudière-Appalaches": {
    center: [-70.79786207596135, 46.36279079078568],
    zoom: 8 + ajustementZoom,
    pitch: 0, //38
    bearing: 0 //-16
  },
  "Saguenay--Lac-Saint-Jean": {
    center: [-71.47572368328412, 49.01870035290307],
    zoom: 7.25 + ajustementZoom,
    pitch: 0, //56.5
    bearing: 0
  },
  "Côte-Nord": {
    center: [-66.50127080418763, 50.47407325895273],
    zoom: 6 + ajustementZoom,
    pitch: 0, //52.5
    bearing: 0 //8.8
  },
  "Bas-Saint-Laurent": {
    center: [-68.75532394228611, 48.35220902658301],
    zoom: 7 + ajustementZoom,
    pitch: 0, //35
    bearing: 0 //-12.8
  },
  "Gaspésie--Îles-de-la-Madeleine": {
    center: [-64.0274798829195, 48.3743678963306],
    zoom: 6.35 + ajustementZoom,
    pitch: 0, //6
    bearing: 0 //8.8
  },
  "Outaouais": {
    center: [-75.97447366563384, 46.032258243198726],
    zoom: 7.5 + ajustementZoom,
    pitch: 0, //27
    bearing: 0
  },
  "Abitibi-Témiscamingue": {
    center: [-77.50752940570739, 47.350373161447294],
    zoom: 7.3 + ajustementZoom,
    pitch: 0, //43.5
    bearing: 0
  },
  "Nord-du-Québec": {
    center: [-74.2238494702655, 52.57443487499788],
    zoom: 5.5 + ajustementZoom,
    pitch: 0, //54.5
    bearing: 0 //-8
  }
}

export { nomsRegions, coordonneesRegions }
