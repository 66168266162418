//styled components
import {
  StyledTextInput,
  StyledFormArea,
  StyledFormButton,
  StyledLabel,
  StyledContainer,
  Avatar,
  StyledTitle,
  colors,
  ButtonGroup,
  ExtraText,
  TextLink,
  CopyrightText,
} from "../../Styles";

//Logo
import Logo from "./../../src-assets/civision-logo-copy.png";

//Formik
import { Formik, Form } from "formik";
import { TextInput } from "./FormLib";
import * as Yup from "yup";

//Icons
import { FiMail, FiLock } from "react-icons/fi";

//Loader
import { ThreeDots } from "react-loader-spinner";

// Auth & Redux
import { connect } from "react-redux";
import { resetPassword } from "../../auth/actions/userActions";
import { useNavigate, useParams } from "react-router-dom";

const PasswordReset = ({ resetPassword }) => {
  const navigate = useNavigate();
  const { userId, resetString } = useParams();
  return (
    <StyledContainer>
      <div>
        <StyledFormArea>
          <StyledTitle color={colors.theme} size={30}>
            Password Reset
          </StyledTitle>
          <Formik
            initialValues={{
              newPassword: "",
              confirmNewPassword: "",
              userId,
              resetString,
            }}
            validationSchema={Yup.object({
              newPassword: Yup.string()
                .min(8, "Password is too short")
                .max(30, "Password is too long")
                .required("Required"),
              confirmNewPassword: Yup.string()
                .required("Required")
                .oneOf([Yup.ref("newPassword")], "Passwords must match"),
            })}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              console.log(values);
              resetPassword(values, navigate, setFieldError, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextInput
                  name="newPassword"
                  type="password"
                  label="New Password"
                  placeholder="*******"
                  icon={<FiLock />}
                />

                <TextInput
                  name="confirmNewPassword"
                  type="password"
                  label="Confirm New Password"
                  placeholder="*******"
                  icon={<FiLock />}
                />

                <ButtonGroup>
                  {!isSubmitting && (
                    <StyledFormButton type="submit">Submit</StyledFormButton>
                  )}

                  {isSubmitting && (
                    <ThreeDots
                      type="ThreeDots"
                      color={colors.theme}
                      height={49}
                      width={100}
                    />
                  )}
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </StyledFormArea>
        <CopyrightText>All rights reserved &copy;2023</CopyrightText>
      </div>
    </StyledContainer>
  );
};

export default connect(null, { resetPassword })(PasswordReset);
