import {
  calculInvestissementPotentielPourMrc,
  calculSalaireMoyenPourMrc,
  calculTauxExigencePourMrc,
  tauxAvantageSociauxPourMrc,
  tauxStabiliteEmploiPourMrc,
} from "../../../data/CalculsMap";
import mrcNamesList from "../../../constants/NomsDesMrcs";
import * as d3 from "d3";
import { couleursChoropleth } from "../../../constants/CodesCouleurs";


// ******************************************************
// pour l'ajout des choropleths
// ******************************************************

const fadeDuration = 0;

const showRegionColors = (mapRef) => {
  // fait apparaître les régions colorisées
  mapRef?.current.getLayer("quebec-regions-surfaces") &&
    mapRef?.current.setPaintProperty(
      "quebec-regions-surfaces",
      "fill-opacity",
      1
    );
};

const hideRegionColors = (mapRef) => {
  // retire la couche de régions colorisées
  mapRef?.current.getLayer("quebec-regions-surfaces") &&
    mapRef?.current.setPaintProperty(
      "quebec-regions-surfaces",
      "fill-opacity",
      0
    );
};

const addChoropleth = (
  mapRef,
  choixDonnee,
  jobListings,
  mrcLayerSource,
  source,
  choroplethId,
  modeColour,
) => {
  var type;
  var titre;
  var inverted = false;

  // fonction qui fait le calcul selon le rendu choropleth sélectionné
  const Calculate = (data, mrc) => {
    let calculatedData; // le type peut être %, $ ou postes
    switch (
    choixDonnee // est tenu dans le redux store
    ) {
      case "Stabilité de l'emploi":
        {
          calculatedData = tauxStabiliteEmploiPourMrc(data, mrc);
          type = "%";
          titre = "Indice de stabilité de l'emploi";
          inverted = false;
        }
        break;
      case "Exigences de l'employeur":
        {
          calculatedData = calculTauxExigencePourMrc(data, mrc);
          type = "%";
          titre = "Score d'exigence des employeurs";
          inverted = true;
        }
        break;
      case "Avantages sociaux":
        {
          calculatedData = tauxAvantageSociauxPourMrc(data, mrc);
          type = "%";
          titre = "Taux d'entreprises offrant des avantages sociaux";
          inverted = false;
        }
        break;
      case "Salaires":
        {
          calculatedData = calculInvestissementPotentielPourMrc(data, mrc);
          type = "$";
          titre = "Salaire annuel moyen";
          inverted = false;
        }
        break;
      default: {
        calculatedData = null;
        titre =
          "Cliquez sur les icônes sur la section «Données» pour afficher les données.";
      }
    }
    return calculatedData;
  };

  // ********* commencement **********

  // ceci traite les données utilisées pour le choropleth à l'aide de Calculate()
  const mrcData = mrcNamesList.map((item) => {
    // pour chaque mrc
    if (jobListings[item.REG])
      // si on a le data de sa région
      var value = Calculate(jobListings[item.REG], item.MRC); // on fait le calcul approprié
    if (isNaN(value))
      // si on a pas de data
      value = -1; // on retourne -1
    return { mrcName: item.MRC, value: value }; // retourne tableau de value/mrc
  });

  // cette partie détermine les limites des valeurs utilisées comme légende (pour 4 stops)
  const mrcDataSorted = mrcData
    .sort((a, b) => a.value - b.value) // on met en ordre alphabétique
    .filter((item) => item.value !== -1); // on filtre out les -1
  const fraction = (mrcDataSorted.length / 3)?.toFixed(0); // on détermine la position 1/3
  const value0 = mrcDataSorted[fraction * 0]?.value; // valeur la plus basse
  const value1 = mrcDataSorted[fraction * 1]?.value; // valeur 1/3
  const value2 = mrcDataSorted[fraction * 2]?.value; // valeur 2/3
  const value3 = mrcDataSorted[mrcDataSorted.length - 1].value; // valeur la plus haute
  const stopValues = [value0, value1, value2, value3];

  for (var i = 0; i < stopValues.length; i++) {
    if (stopValues[i] === undefined) stopValues[i] = 0;
  }

  stopValues.sort((a, b) => a - b);

  const colours = couleursChoropleth;
  colours[0] = modeColour
  const reversedColours = [
    colours[1],
    colours[2],
    colours[3],
    colours[4],
    colours[0],
  ];

  var colorScale;

  if (inverted) {
    // ceci détermine l'échelle des données
    colorScale = d3
      .scalePow() // on place les stops de couleurs
      .domain([stopValues[3], stopValues[2], stopValues[1], stopValues[0], -1])
      .range(reversedColours);
  } else {
    colorScale = d3
      .scalePow()
      .domain([-1, ...stopValues])
      .range(colours);
  }

  // on retire l'ancien choropleth s'il y en a un
  removeChoropleth(mapRef, choroplethId);


    mapRef.current?.addLayer({
      // on ajoute la layer avec les couleurs
      id: choroplethId,
      type: "fill",
      source: source,
      "source-layer": mrcLayerSource,
      paint: {
        "fill-color": [
          "match",
          ["get", "MRS_NM_MRC"],
          ...mrcData.map((d) => [d.mrcName, colorScale(d.value)]).flat(),
          "transparent",
        ],
        "fill-opacity": 0,
        "fill-opacity-transition": { duration: fadeDuration },
      }, // on retourne les couleurs selon les valeurs en dégradé
    });

    mapRef.current?.setPaintProperty(choroplethId, "fill-opacity", 1);
    // on déplace la layer pour que la layer 'water' reste par dessus les couleurs du choropleth
    mapRef.current?.moveLayer(choroplethId, "quebec-regions-surfaces");


  return [mrcData, [titre, type, ...stopValues, inverted]];
};

const removeChoropleth = (mapRef, layer) => {
  // cette fonction retire le choropleth
  mapRef.current?.getLayer(layer) &&
    mapRef.current?.setPaintProperty(layer, "fill-opacity", 0);

  mapRef.current?.getLayer(layer) && mapRef.current?.removeLayer(layer);
};

export { addChoropleth, removeChoropleth, showRegionColors, hideRegionColors };
