//styled components
import {
  StyledTextInput,
  StyledFormArea,
  StyledFormButton,
  StyledLabel,
  StyledContainer,
  Avatar,
  StyledTitle,
  colors,
  ButtonGroup,
  ExtraText,
  TextLink,
  CopyrightText,
} from "../../Styles";

//Logo
import Logo from "./../../src-assets/civision-logo-copy.png";

//Formik
import { Formik, Form } from "formik";
import { TextInput } from "./FormLib";
import * as Yup from "yup";

//Icons
import { FiMail, FiLock } from "react-icons/fi";

//Loader
import { ThreeDots } from "react-loader-spinner";

// Auth & Redux
import { connect, useDispatch } from "react-redux";
import { loginUser } from "../../auth/actions/userActions";
import { useNavigate, useParams } from "react-router-dom";
import { setUserEmail, setUserIsReadyToFetch } from "../../reducers/dataStore";

const Login = ({ loginUser }) => {
  const navigate = useNavigate();
  const { userEmail } = useParams();
  const dispatch = useDispatch();

  return (
    <StyledContainer>
      <div>
        <StyledFormArea>
          <Avatar image={Logo} />
          <StyledTitle color={colors.theme} size={30}>
            Member Login
          </StyledTitle>
          <Formik
            initialValues={{
              email: userEmail || "",
              password: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              password: Yup.string()
                .min(8, "Password is too short")
                .max(30, "Password is too long")
                .required("Required"),
            })}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              // THIS IS WHERE USER GETS SET FOR LOGIN ****************
              //                                       ****************
              //                                       ****************   //  <--
              dispatch(setUserEmail(values.email)); //  <------
              loginUser(values, navigate, setFieldError, setSubmitting); //  <-------------
              dispatch(setUserIsReadyToFetch(true)); //  <-------
              //                                       ****************   //  <--
              //                                       ****************
              //  *****************************************************
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextInput
                  name="email"
                  type="text"
                  label="Email Address"
                  placeholder="nico1@example.com"
                  icon={<FiMail />}
                />

                <TextInput
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="*******"
                  icon={<FiLock />}
                />

                <ButtonGroup>
                  {!isSubmitting && (
                    <StyledFormButton type="submit">Login</StyledFormButton>
                  )}

                  {isSubmitting && (
                    <ThreeDots
                      type="ThreeDots"
                      color={colors.theme}
                      height={49}
                      width={100}
                    />
                  )}
                </ButtonGroup>
              </Form>
            )}
          </Formik>
          <ExtraText>
            New here? <TextLink to="/signup">Signup</TextLink>
          </ExtraText>
          <ExtraText>
            Forgot password?{" "}
            <TextLink to="/forgottenpassword">Reset it</TextLink>
          </ExtraText>
        </StyledFormArea>
        <CopyrightText>All rights reserved &copy;2023</CopyrightText>
      </div>
    </StyledContainer>
  );
};

export default connect(null, { loginUser })(Login);
