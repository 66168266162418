import { Checkbox, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Legende from './components/Legende'
import ZoomCtrl from './components/ZoomCtrl'
import { coordonneesRegions } from '../../constants/NomsDesRegions'
import { selectFlyToRegion } from '../../reducers/mapStore';
import Map from './components/Map'
import { logAllLayers } from "./components/UtilityFunctions";
import "./animateBox.css"
import "../../index.css"
import { map } from "d3";


const Carte = () => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode);
  const [showMrcDivisions, setShowMrcDivisions] = useState(true)
  const [showJobDots, setShowJobDots] = useState(false)
  const [isLegendeReady, setIsLegendeReady] = useState(false);
  const flyToRegion = useSelector((state) => state.mapStore.flyToRegion)
  const choropleth = useSelector((state => state.mapStore.choixDonnee))
  const computerIsThinking = useSelector(state => state.dataStore.computerIsThinking)
  const mapRef = useRef(null)     // la référence à l'objet carte est insérée ici
  const dispatch = useDispatch()  // pour utiliser les fonctions de redux
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const mapContainerStyle = {
    backgroundColor: colors.primary[400],
    position: 'relative',
    border: '3px solid rgba(173, 173, 173, 0.25)',
    margin: '2px 2.5px',
    flex: 1
  }

  const zoomCtrlStyle = {
    position: 'absolute',
    zIndex: 10,
    right: 15,
    top: 110,
    margin: 15
  }

  const jobDotsCheckboxStyle = {
    color: "#222",
    position: 'absolute',
    zIndex: 10,
    top: 0,
    right: 0,
    margin: 22,
    backgroundColor: colors.primary[400],
    border: '0.5px solid rgba(175,175,175,0.5)',
    borderRadius: 2,
    fontSize: 12,
    fontWeight: 500,
    padding: '0px 15px 0px 5px',
    height: 40
  }

  const mrcDivisionsCheckboxStyle = {
    color: '#222',
    position: 'absolute',
    zIndex: 10,
    top: 50,
    right: 0,
    margin: 22,
    backgroundColor: colors.primary[400],
    border: '0.5px solid rgba(175,175,175,0.5)',
    borderRadius: 2,
    fontSize: 12,
    fontWeight: 500,
    padding: '0px 15px 0px 5px',
    height: 40
  }

  const boutonChoroplethStyle = {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,
    margin: 15
  }

  const legendeStyle = {
    position: 'absolute',
    backgroundColor: colors.primary[400],
    border: '0.5px solid rgba(175,175,175,0.4)',
    borderRadius: 2,
    zIndex: 10,
    right: 30,
    bottom: 15,
    opacity: 0
  }

  const indicateurStyle = {
    position: 'absolute',
    zIndex: 10,
    top: 25,
    left: 10,
    margin: 15,
    backgroundColor: colors.primary[400],
    border: '0.5px solid rgba(175,175,175,0.5)',
    borderRadius: 2,
    fontSize: 12,
    fontWeight: 600,
    padding: '20px',
  }

  useEffect(() => { // chaque fois que 'region' change, on se déplace
    if (mapRef.current && flyToRegion !== '') flyTo(flyToRegion)
    dispatch(selectFlyToRegion(''))
  }, [flyToRegion]);

  const flyTo = (region) => { // les coordonnées de chaque région sont dans un fichier
    mapRef.current.flyTo({    // ...du dossier 'data'
      center: coordonneesRegions[region].center,
      zoom: coordonneesRegions[region].zoom,
      pitch: coordonneesRegions[region].pitch,
      bearing: coordonneesRegions[region].bearing
    })
  }

  const toggleMrcDivisions = () => {
    // line color settings for dark or light mode
    if (theme.palette.mode === 'light') var lineSettings = [0.5, "black", 0.5]
    else var lineSettings = [1, "white", 0.7]

    setShowMrcDivisions(!showMrcDivisions)
    if (!showMrcDivisions) {
      mapRef.current.setPaintProperty('quebec-mrc-lignes', 'line-opacity', lineSettings[0]);
      mapRef.current.setPaintProperty('quebec-mrc-lignes', 'line-color', lineSettings[1]);
      mapRef.current.setPaintProperty('quebec-mrc-lignes', 'line-width', lineSettings[2]);
    }
    if (showMrcDivisions)
      mapRef.current.setPaintProperty('quebec-mrc-lignes', 'line-opacity', 0)
  }

  const toggleJobDots = () => {
    setShowJobDots(!showJobDots)
  }

  const zoomInOrOut = (inOrOut) => {
    inOrOut === 'plus' && mapRef.current.zoomIn()
    inOrOut === 'minus' && mapRef.current.zoomOut()
  }

  useEffect(() => {
    // Set the 'isLegendeReady' state to true when the component mounts
    setIsLegendeReady(true);
    setShowMrcDivisions(!showMrcDivisions)
  }, []);

  const showLegende = () => {
    let legendeDiv = document.querySelector('.legende');
    try {
      legendeDiv.style.transition = "opacity .2s cubic-bezier(0.4, 0, 1, 1)"
      legendeDiv.style.opacity = 0
      setTimeout(() => {
        legendeDiv.style.transition = "opacity .3s cubic-bezier(0.4, 0, 1, 1)"
        legendeDiv.style.opacity = 1
      }, 600)
    } catch { console.log("légende not ready") }
    return <Legende />
  }

  const hideLegende = () => {
    try {
      const legendeDiv = document.querySelector('.legende');
      legendeDiv.style.opacity = 0
    } catch { console.log("loading layout") }
  }

  return (
    <>
      <div className={`map-container ${computerIsThinking ? 'loading' : ''}`}
        style={{
          ...mapContainerStyle,
          filter: computerIsThinking ? 'blur(1px) grayscale(50%)' : 'blur(0px) grayscale(0%)',
          transition: 'filter 0.35s ease',
          pointerEvents: computerIsThinking ? 'none' : 'auto',
        }}>
        <Map
          setMapRef={(map) => { mapRef.current = map }}
          mapRef={mapRef}
          flyTo={flyTo}
          showJobDots={showJobDots}
        />
        <div className='legende' style={legendeStyle}>
          <span></span>
          {
            isLegendeReady && choropleth !== 'Div. régionales' && choropleth !== "Retour à l'accueil" && choropleth !== undefined ?
              showLegende() : hideLegende()
          }
        </div>

        <div className='divisions' style={mrcDivisionsCheckboxStyle}>
          <Checkbox sx={{
            '& .MuiSvgIcon-root': { width: 12 },
            '&.Mui-checked': { color: colors.primary[600] },
          }}
            onChange={toggleMrcDivisions} /* defaultChecked */ />
          <span style={{ color: colors.primary[600] }} >Divisions MRC</span>
        </div>

        <div className='job-dots' style={jobDotsCheckboxStyle}>
          <Checkbox sx={{
            '& .MuiSvgIcon-root': { width: 12 },
            '&.Mui-checked': { color: colors.primary[600] },
          }}
            onChange={toggleJobDots} />
          <span style={{ color: colors.primary[600] }}>Voir les emplois</span>
        </div>

        <div className='zoom-controles' style={zoomCtrlStyle}>
          <ZoomCtrl zoomInOrOut={zoomInOrOut} />
        </div>

        {choropleth === "Retour à l'accueil" &&
          <div className='indications' style={indicateurStyle}>
            <p>&lt;- Veuillez choisir une donnée <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; à visualiser sur la carte</p>
          </div>
        }
      </div>

    </>
  )
}

export default Carte;
