import { configureStore } from "@reduxjs/toolkit";
import mapStoreSliceReducer from "../reducers/mapStore";
import dataStoreSliceReducer from "../reducers/dataStore";
import histoStoreSliceReducer from "../reducers/histoStore";

const appStore = configureStore({
  reducer: {
    dataStore: dataStoreSliceReducer,
    mapStore: mapStoreSliceReducer,
    histoStore: histoStoreSliceReducer,
  },
});

export default appStore;
