import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";

//auth and redux
import { connect } from "react-redux";
import { logoutUser } from "./../../auth/actions/userActions";

//react router

const Topbar = ({ logoutUser }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  //const [anchorEl, setAnchorEl] = useState(null);

/*   const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }; */

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={1}
      style={{
        width: "100%",
        backgroundColor: `${colors.primary[400]}`,
      }}
    >
      {/* MAURICIE LOGO */}
      <Box display="flex" borderRadius="3px">
        <a href="https://developpementmauricie.civision.org"
          rel="noopener noreferrer"
          target="_self"
          style={{ display: 'inline-block', margin: 0, padding: 0, lineHeight: 0 }}
        >
          <img
            alt="profile-user"
            width="120px"
            height="50px"
            src={`../../assets/logo-mauricie.png`}
            className="landing-topbar-logo"
          />
        </a>
      </Box>

      {/* ICONS */}
      <Box display="flex">


        {/*  <IconButton
          to="#"
          onClick={() => logoutUser(navigate)}
          fontSize="large"
        >
          <LogoutIcon />
        </IconButton> */}


        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "light" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>


        {/* <IconButton onClick={handleClick}>
          <SettingsOutlinedIcon fontSize="medium" />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Settings</MenuItem>
            <MenuItem onClick={handleClose}>Payments</MenuItem>
            <MenuItem onClick={handleClose}>Questions</MenuItem>
          </Menu>
        </IconButton> */}


        {/* <IconButton onClick={handleClick}>
          <AccountCircleOutlinedIcon fontSize="medium" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu> */}


        <IconButton>

          <a href="https://www.civision.org/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', margin: 0, padding: 0, lineHeight: 0 }}>
            <img
              alt="civision-logo"
              width="40px"
              height="40px"
              src={`../../assets/civision-logo.png`}
            />
          </a>

        </IconButton>
      </Box>
    </Box>
  );
};

export default connect(null, { logoutUser })(Topbar);
