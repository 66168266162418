import {
  StyledTitle,
  StyledSubTitle,
  Avatar,
  StyledContainer,
  StyledButton,
  ButtonGroup,
  StyledFormArea,
  colors,
} from "../../Styles";

import { LinearProgress } from "@mui/material";
import React, { useState, useEffect } from 'react';
import ErrorLoading from './ErrorLoading';
import { useSelector } from "react-redux";
import { set } from "date-fns";

const Loading = () => {
  const [activeBar, setActiveBar] = useState(1);
  const [progressStep1, setProgressStep1] = useState(0);
  const [progressStep2, setProgressStep2] = useState(0);
  const [progressStep3, setProgressStep3] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const loadingProgress = useSelector((state) => state.dataStore.loadingProgress);

  useEffect(() => {
    let intervalId;
    let timeoutId;

    if (progressStep1 < 100) {
      intervalId = setInterval(() => {
        setProgressStep1((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(intervalId);
            return 100;
          }
          return prevProgress + 5.7;
        })
      }, 400);
      if (loadingProgress === 2) setProgressStep1(100);
    } else if (progressStep1 >= 100 && progressStep2 < 100) {
      setTimeout(() => { setActiveBar(2) }, 1000);

      intervalId = setInterval(() => {
        setProgressStep2((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(intervalId);
            return 100;
          }
          return prevProgress + 5.9;
        })
      }, 400);
      if (loadingProgress === 3) setProgressStep2(100);
    } else if (progressStep2 >= 100 && progressStep3 < 100) {
      setTimeout(() => { setActiveBar(3) }, 1000);
      intervalId = setInterval(() => {
        setProgressStep3((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(intervalId);
            return 100;
          }
          return prevProgress + 6.4;
        });
      }, 400);
      if (progressStep3 >= 100) setLoadingComplete(true);
    }

  }, [loadingProgress, progressStep1, progressStep2, progressStep3, isLoadingComplete]);

  useEffect(() => {
    const errorTimeout = setTimeout(() => {
      if (!isLoadingComplete) {
        setHasError(true);
      }
    }, 50000); // 50 seconds

    return () => clearTimeout(errorTimeout);
  }, [isLoadingComplete]);

  if (hasError) {
    return <ErrorLoading />;
  }

  return (
    <StyledContainer>
      <div style={{ width: "34vw" }}>
        <StyledFormArea
          padding={"3px 45px"}
          bg={"rgb(255, 255, 255, 0.3)"}
          filter={"drop-shadow(2px 4px 6px black)"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"absolute"}
        >
          <StyledTitle size={40}>En Chargement &nbsp;
            <span style={{ marginLeft: '10px', fontSize: '1.5em' }}>
              <span style={{ animation: 'dot 1s infinite step-start' }}>.</span>
              <span style={{ animation: 'dot 1s 0.33s infinite step-start' }}>.</span>
              <span style={{ animation: 'dot 1s 0.66s infinite step-start' }}>.</span>
            </span>
          </StyledTitle>
          {activeBar === 1 && (
            <>
              <LinearProgress
                variant="determinate"
                value={progressStep1}
                sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'rgb(85, 125, 65)'
                  }
                }}
                style={{ borderRadius: "5px", width: '100%', height: '20px', margin: '20px 0' }} />
              <p style={{ color: "white", fontSize: 16 }}>
                Chargement des données sur les emplois... {Math.min(progressStep1, 100).toFixed(0)}%
              </p>
            </>
          )}
          {activeBar === 2 && (
            <>
              <LinearProgress
                variant="determinate"
                value={progressStep2}
                sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'rgb(85, 125, 65)'
                  }
                }}
                style={{ borderRadius: "5px", width: '100%', height: '20px', margin: '20px 0' }} />
              <p style={{ color: "white", fontSize: 16 }}>Chargement des données sur les entreprises... {Math.min(progressStep2, 100).toFixed(0)}%</p>
            </>
          )}
          {activeBar === 3 && (
            <>
              <LinearProgress
                variant="determinate"
                value={progressStep3}
                sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'rgb(85, 125, 65)'
                  }
                }}
                style={{ borderRadius: "5px", width: '100%', height: '20px', margin: '20px 0' }} />
              <p style={{ color: "white", fontSize: 16 }}>Initialisation de l'interface... {Math.min(progressStep3, 100).toFixed(0)}%</p>
            </>
          )}
          {activeBar === 4 && (
            <>
              <p style={{ color: "white", fontSize: 16 }}>Démarrage de l'application... </p>
            </>
          )}
        </StyledFormArea>
      </div>
    </StyledContainer>
  );
};

export default Loading;
