import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import { tokens } from "../../theme";
//import { setjobListings } from "../../reducers/dataStore";
//import { useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { setComputerIsThinking } from "../../reducers/dataStore";

const Tableau = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  //const jobListings = useSelector((state) => state.dataStore.jobListings);
  const nettoyage = useSelector((state) => state.dataStore.nettoyage);
  const infoEntreprises = useSelector((state) => state.dataStore.infoEntreprises);

  //choix de filtres
  const periode = useSelector((state) => state.mapStore.periode);
  const secteurActivite = useSelector((state) => state.mapStore.secteurActivite);
  const typePoste = useSelector((state) => state.mapStore.typePoste);
  const zoneAdministrative = useSelector((state) => state.mapStore.zoneAdministrative);
  const computerIsThinking = useSelector((state) => state.dataStore.computerIsThinking);

  const [listeEntreprisesInvestissement, setListeEntreprisesInvestissement] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortingProp, setSortingProp] = useState("null");
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const penurieColorCodes = ['rgba(49,162,97,1)', 'rgba(49,162,97,1)', 'rgba(160, 200, 0,1)', 'rgba(235, 195, 0,1)', 'rgba(215, 145, 0,1)', 'rgba(197,97,60,1)']
  const tempsDeProjectionPourCalculImpactPenurie = ["12 derniers mois"]

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  function filterForPeriode(infoEntreprises, periode) {
    const filteredList = infoEntreprises.filter(item => item.periode === periode[0]);
    return filteredList
  }

  function filterForMRC(infoEntreprises, zoneAdministrative) {
    if (zoneAdministrative === "Toute la région") return infoEntreprises
    const filteredList = infoEntreprises.filter(item => zoneAdministrative.includes(item.MRC));
    return filteredList
  }

  function filterForSecteur(infoEntreprises, secteurActivite) {
    if (secteurActivite === undefined || secteurActivite === "Tous les secteurs") return infoEntreprises
    else return infoEntreprises.filter(item => secteurActivite.includes(item.SecteurActivite));
  }

  function filterForTypePoste(infoEntreprises, typePoste) {
    if (typePoste === undefined || typePoste === "Tous les types") return infoEntreprises
    else return infoEntreprises.filter(item => typePoste.includes(item.TypePoste));
  }

  // merging lists with secteur and type de poste:
  function mergeLists(infoEntreprises, nettoyage) {
    let nettoyageMap = new Map(nettoyage.map(item => [item.Résultat, item]));
    let newInfoEntreprises = infoEntreprises.map(info => {
      if (nettoyageMap.has(info.appelation)) {
        let matchedItem = nettoyageMap.get(info.appelation);
        return {
          ...info,
          SecteurActivite: matchedItem.Famille,
          TypePoste: matchedItem.Groupe
        };
      } else {
        return { ...info };
      }
    });
    return newInfoEntreprises;
  }

  // adding total investments column:
  function addTotalInvestissement(newInfoEntreprises) {
    return newInfoEntreprises.map(info => {
      let emploi_cree_rounded = Math.round(info.emploi_cree);
      return {
        ...info,
        emploi_cree: emploi_cree_rounded,
        total_investissement: Math.round(info.investissement * emploi_cree_rounded)
      };
    });
  }

  function groupAndSum(newInfoEntreprisesWithInvestment) {
    let groups = new Map();

    for (let item of newInfoEntreprisesWithInvestment) {
      let key = `${item.employeur}-${item.periode}`;

      if (!groups.has(key)) {
        groups.set(key, {
          employeur: item.employeur,
          occurrence: item.occurrence,
          occurrence_ajustee: item.occurrence_ajustee,
          total_investissement: item.total_investissement,
          emploi_cree: item.emploi_cree,
        });
      } else {
        let group = groups.get(key);
        group.occurrence += item.occurrence;
        group.occurrence_ajustee += item.occurrence_ajustee;
        group.total_investissement += item.total_investissement;
        group.emploi_cree += item.emploi_cree;
      }
    }

    return Array.from(groups.values());
  }

  function sortByTotalInvestissement(groupedAndSummed) {
    return groupedAndSummed.sort((a, b) => b.total_investissement - a.total_investissement);
  }

  const handleSort = (prop) => {
    let order = "desc";
    if (prop === sortingProp) {
      order = sortOrder === "asc" ? "desc" : "asc";
    }

    setSortingProp(prop);
    setSortOrder(order);

    const orderMultiplier = order === "asc" ? 1 : -1;
    const sortedRows = [...listeEntreprisesInvestissement].sort((a, b) => {
      if (prop === "employeur") {
        return a[prop].localeCompare(b[prop]) * orderMultiplier;
      } else if (prop === "nombre_de_poste") {
        return (a["emploi_cree"] - b["emploi_cree"]) * orderMultiplier;
      } else if (prop === "nombre_heure") {
        return (a["total_investissement"] - b["total_investissement"]) * orderMultiplier;
      } else if (prop === "stability_rate") {
        return (a["occurrence_ajustee"] - b["occurrence_ajustee"]) * orderMultiplier;
      } else {
        return (a[prop] - b[prop]) * orderMultiplier;
      }
    });

    setListeEntreprisesInvestissement(sortedRows);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const ajusterOccurrencePourImpactDeLaPenurie = (infoEntreprises, infoEntreprisesProjeteeSur12Mois) => {
    let infoEntreprisesProjeteeSur12MoisMap = new Map(infoEntreprisesProjeteeSur12Mois.map(item => [`${item.appelation}-${item.employeur}-${item.MRC}`, item]));
    let newInfoEntreprises = infoEntreprises.map(info => {
      let key = `${info.appelation}-${info.employeur}-${info.MRC}`;
      if (infoEntreprisesProjeteeSur12MoisMap.has(key)) {
        let matchedItem = infoEntreprisesProjeteeSur12MoisMap.get(key);
        return {
          ...info,
          occurrence_ajustee: matchedItem.occurrence,
        };
      } else {
        return { ...info };
      }
    }
    );
    return newInfoEntreprises;
  }

  useEffect(() => {
    dispatch(setComputerIsThinking(true))
    const filteredByPeriode = filterForPeriode(infoEntreprises, periode);
    const filteredByMRC = filterForMRC(filteredByPeriode, zoneAdministrative);
    const newInfoEntreprises = mergeLists(filteredByMRC, nettoyage); // merging list with nettoyage pour appelations
    const filteredBySecteur = filterForSecteur(newInfoEntreprises, secteurActivite);
    const filteredByTypePoste = filterForTypePoste(filteredBySecteur, typePoste);

    // now the list is filtered by periode, MRC, secteur and type de poste
    // the next function will replace the occurence for the once present in "12 derniers mois"
    // for projection purposes (impact de la pénurie de main-d'oeuvre)
    const occurrenceAjustee = ajusterOccurrencePourImpactDeLaPenurie(
      filteredByTypePoste, filterForPeriode(
        infoEntreprises, tempsDeProjectionPourCalculImpactPenurie)); // ajuster l'occurrence pour l'impact de la pénurie

    const newInfoEntreprisesWithInvestment = addTotalInvestissement(occurrenceAjustee); // adding total investment column
    const groupedAndSummed = groupAndSum(newInfoEntreprisesWithInvestment); // grouping and summing for 1 entry per employeur
    const infoEntreprisesFiltrees = sortByTotalInvestissement(groupedAndSummed) // reorder by total investment
    setListeEntreprisesInvestissement(Object.values(infoEntreprisesFiltrees));
  }, [periode, zoneAdministrative, typePoste, secteurActivite]);

  useEffect(() => { //useEffect for search
    const filteredBySearch = listeEntreprisesInvestissement.filter(item => item.employeur.toLowerCase().includes(debouncedSearchTerm.toLowerCase()));
    setDisplayList(filteredBySearch);
    dispatch(setComputerIsThinking(false))
  }, [debouncedSearchTerm, listeEntreprisesInvestissement])

  const tableContainerStyles = {
    borderRadius: "8px",
    border: "2px solid #ccc",
    marginLeft: "30px",
    marginRight: "10px",
    width: "85%",
    margin: "auto",
    marginBottom: "200px"
  };

  return (
    <div
      style={{
        height: '80vh',
        overflow: 'auto',
        backgroundColor: `${colors.primary[500]}`,
      }}>
      <Box m="20px">
        <Typography
          variant="h3"
          component="h2"
          fontWeight="bold"
          sx={{ mb: 2, ml: 10, mt: 4 }}
          style={{ letterSpacing: "0.03em" }}
        >
          Liste des entreprises
        </Typography>
      </Box>
      <Box sx={{ mb: 2, ml: "74.1%" }}>
        <TextField
          // make the border of this text field 2px
          sx={{ border: "1px solid #ccc", borderRadius: "8px" }}
          label="Employeur"
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <TableContainer sx={tableContainerStyles}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: colors.primary[400] }}>
              <TableCell style={{ textAlign: "center", width: "30%" }}>
                {" "}
                <Typography variant="h5" fontWeight="bold">
                  Nom de l'entreprise
                  <Button onClick={() => handleSort("employeur")}>
                    {sortingProp === "employeur" && sortOrder === "asc"
                      ? "▲"
                      : "▼"}
                  </Button>
                </Typography>
              </TableCell>
              <TableCell style={{ textAlign: "center", width: "20%" }}>
                {" "}
                <Typography variant="h5" fontWeight="bold">
                  Nombre d'emplois créés
                  <Button onClick={() => handleSort("nombre_de_poste")}>
                    {sortingProp === "nombre_de_poste" && sortOrder === "asc"
                      ? "▲"
                      : "▼"}
                  </Button>
                </Typography>
              </TableCell>
              <TableCell style={{ textAlign: "center", width: "20%" }}>
                {" "}
                <Typography variant="h5" fontWeight="bold">
                  Investissements
                  <Button onClick={() => handleSort("nombre_heure")}>
                    {sortingProp === "nombre_heure" && sortOrder === "asc"
                      ? "▲"
                      : "▼"}
                  </Button>
                </Typography>
              </TableCell>
              <TableCell style={{ textAlign: "center", width: "30%" }}>
                {" "}
                <Typography variant="h5" fontWeight="bold">
                  Impact de la pénurie de main d'oeuvre
                  <Button onClick={() => handleSort("stability_rate")}>
                    {sortingProp === "stability_rate" && sortOrder === "asc"
                      ? "▲"
                      : "▼"}
                  </Button>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              filter: computerIsThinking ? 'blur(1px) grayscale(100%)' : 'blur(0px) grayscale(0%)',
              transition: 'filter 0.5s ease',
              pointerEvents: computerIsThinking ? 'none' : 'auto',
            }}>
            {displayList.map((item, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 1 ? colors.primary[400] : null }}>
                <TableCell
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {item.employeur}
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {" "}
                  {item.emploi_cree}
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {item.total_investissement
                    ? `$${item.total_investissement.toLocaleString()}`
                    : "N/A"}
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  <div style={{
                    width: "30%", margin: "auto", padding: "5px", borderRadius: "5px", color: "white",
                    backgroundColor:
                      item.occurrence_ajustee <= 0 ? penurieColorCodes[0] :
                        item.occurrence_ajustee <= 0.5 ? penurieColorCodes[1] :
                          item.occurrence_ajustee <= 1.5 ? penurieColorCodes[3] :
                            item.occurrence_ajustee <= 2.5 ? penurieColorCodes[4] :
                              penurieColorCodes[5]
                  }}>
                    {
                      item.occurrence_ajustee <= 0 ? "Faible"
                        : item.occurrence_ajustee <= 1.5 ? "Moyen" // 1.5 in case there is a decimal slip up but it shouldn't matter 
                          : "Fort"
                    }
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

  );
};

export default Tableau;
