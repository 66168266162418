import React from 'react';
import { useTheme } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, Cell } from 'recharts';
import { capitalize } from '@mui/material';

const opacity = 0.75;


const COLORS = [
  `rgba(0, 119, 194, ${opacity})`,
  `rgba(194, 162, 0, ${opacity})`,
  `rgba(0, 158, 115, ${opacity})`,
  `rgba(229, 69, 111, ${opacity})`,
  `rgba(160, 60, 120, ${opacity})`,
  `rgba(74, 154, 212, ${opacity})`,
  `rgba(255, 127, 0, ${opacity})`,
  `rgba(127, 127, 127, ${opacity})`
];



const BarChartBox = ({ width, height, data, orientation }) => {

  const theme = useTheme();
  const filteredData = data.filter(entry => entry.value >= 0.05);
  const upperCaseData = filteredData.map((item) => ({
    ...item,
    name: item.name.charAt(0).toUpperCase() + item.name.slice(1)
  }));


  return (
    <div>
      <BarChart width={width * 0.6} height={height * 0.8} data={upperCaseData} layout={orientation} fontSize='11' fontWeight='bold' >
        <CartesianGrid strokeDasharray="10 10" opacity={0.4} />
        {orientation === 'horizontal' &&
          <>
            <XAxis dataKey='name' stroke={theme.palette.text.primary} />
            <YAxis type='number' width={15} stroke={theme.palette.text.primary} />
            <Tooltip formatter={(value, name, props) => [`${value} postes`]} />
          </>
        }
        {orientation === 'vertical' &&
          <>
            <XAxis type="number" stroke={theme.palette.text.primary} />
            <YAxis type="category" tickFormatter={(value) => `${value + 1}`} width={15} stroke={theme.palette.text.primary} />
          </>
        }

        <Bar dataKey='value' fill="#8884d8" barSize={30}>
          {orientation === 'vertical' &&
            <LabelList
              dataKey='name'
              position="top"
              angle={0}
              offset={10}
              fill={theme.palette.text.primary}
              content={<CustomizedLabel2 />}
            />
          }
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar>

      </BarChart>
    </div>
  );
};

const CustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const theme = useTheme();
  
  const words = value.split(' ');
  const maxLength = 60;
  let lineText = '';
  const lines = [];

  words.forEach((word) => {
    if ((lineText + word).length > maxLength) {
      lines.push(lineText);
      lineText = word;
    } else {
      lineText = lineText ? `${lineText} ${word}` : word;
    }
  });

  // Push the last line into lines array
  lines.push(lineText);

  return (
    <text
      x={x + 10}
      y={y + 1 + height / 2 - (lines.length - 1) * 10} // adjust y value based on number of lines
      //fill={theme.palette.text.primary}
      fill="white"
      textAnchor="start"
      dominantBaseline="middle"
      style={{ whiteSpace: 'nowrap' }}
    >
      {lines.map((line, i) => (
        <tspan x={x + 10} dy={i === 0 ? 0 : 20} key={`line-${i}`}> {/* adjust dy for new line */}
          {line}
        </tspan>
      ))}
    </text>
  );
};

const CustomizedLabel2 = (props) => {
  const {
    x, y, width, value
  } = props;

  return (
    <text x={x + 5} y={y - 5} fill="#666" textAnchor="start">
      {value}
    </text>
  );
};



export default BarChartBox;