import {
  StyledTitle,
  StyledSubTitle,
  Avatar,
  StyledButton,
  ButtonGroup,
  StyledFormArea,
  colors,
  ExtraText,
  StyledContainer,
} from "../../Styles";

//Logo
import Logo from "./../../src-assets/civision-logo-copy.png";

//React router
import { useNavigate, useParams } from "react-router-dom";

const EmailSent = () => {
  const navigate = useNavigate();
  const { userEmail, reset } = useParams();

  return (
    <StyledContainer>
      <div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "transparent",
            width: "100%",
            padding: "15px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Avatar image={Logo} />
        </div>
        {reset && userEmail && (
          <StyledFormArea
            bg={"rgb(255, 255, 255, 0.3)"}
            filter={"drop-shadow(2px 4px 6px black)"}
          >
            <StyledTitle size={65}>Password Reset</StyledTitle>
            <ExtraText color={colors.light1}>
              An email with a password reset link has been sent to your email:{" "}
              <b style={{ color: colors.primary }}>{userEmail}</b>{" "}
            </ExtraText>
            <ExtraText color={colors.light1}>
              Check your email and click on the link to proceed!
            </ExtraText>
          </StyledFormArea>
        )}

        {!reset && userEmail && (
          <StyledFormArea
            bg={"rgb(255, 255, 255, 0.3)"}
            filter={"drop-shadow(2px 4px 6px black)"}
          >
            <StyledTitle size={65}>Account Confirmation</StyledTitle>
            <ExtraText color={colors.light1}>
              An email with your account confirmation link has been sent to your
              email: <b style={{ color: colors.primary }}>{userEmail}</b>{" "}
            </ExtraText>
            <ExtraText color={colors.light1}>
              Check your email and come back to proceed!{" "}
            </ExtraText>

            <ButtonGroup>
              <StyledButton to={`/login/${userEmail}`}>Proceed</StyledButton>
            </ButtonGroup>
          </StyledFormArea>
        )}

        {!reset && !userEmail && (
          <StyledFormArea
            bg={"rgb(255, 255, 255, 0.3)"}
            filter={"drop-shadow(2px 4px 6px black)"}
          >
            <StyledTitle size={65}>Password Reset</StyledTitle>
            <ExtraText color={colors.light1}>
              Your password has been reset successfully.
            </ExtraText>
            <ExtraText color={colors.light1}>Your may now login!</ExtraText>

            <ButtonGroup>
              <StyledButton to={`/login`}>Login</StyledButton>
            </ButtonGroup>
          </StyledFormArea>
        )}
      </div>
    </StyledContainer>
  );
};

export default EmailSent;
