import React from 'react';
import {
  StyledTitle,
  StyledSubTitle,
  Avatar,
  StyledContainer,
  StyledButton,
  ButtonGroup,
  StyledFormArea,
  colors,
} from "../../Styles";
import { useNavigate } from "react-router-dom";

const ErrorLoading = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.location.reload();
  }

  return (
    <StyledContainer>
      <div>
        <StyledFormArea
          bg={"rgb(255, 255, 255, 0.3)"}
          filter={"drop-shadow(2px 4px 6px black)"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"absolute"}
        >
          <StyledTitle size={35}>
            Une erreur est survenue, <br />
            cliquez <a onClick={handleClick} style={{cursor: "pointer", textDecoration: "underline", color: "blue"}}>ici</a> 
            &nbsp;pour revenir à la page initiale.
          </StyledTitle>
        </StyledFormArea>
      </div>
    </StyledContainer>
  )
}

export default ErrorLoading
