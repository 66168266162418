// fonction de formattage du className des popups
const formatIdName = (mrc) => {
    return `popup-${mrc
        .replace(/[èé]/g, 'e')
        .replace(/ï/g, 'i')
        .replace(/ù/g, 'u')
        .replace(/[ ']/g, '-')
        .replace(/[^a-zA-Z0-9-]+/g, '-')}`;
};

// fonction de formattage des noms, 1re lettre devient majuscule
function capitalizeString(str) {
    let words = str.toLowerCase().split(' ');
    for (let i = 0; i < words.length; i++) {
        if (words[i].length > 0 && words[i][0].match(/[a-z]/i)) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        if (words[i].includes("-") && words[i][words[i].indexOf("-") + 1].match(/[a-z]/i)) {
            words[i] = words[i].substring(0, words[i].indexOf("-") + 1) + words[i][words[i].indexOf("-") + 1].toUpperCase() + words[i].substring(words[i].indexOf("-") + 2);
        }
    }
    return words.join(' ');
}

// console.log toutes les layers sur la carte
function logAllLayers(map) {
    const style = map.getStyle();
    if (style && style.layers) {
        console.log('All Layers:');
        style.layers.forEach(layer => {
            console.log(layer.id);
        });
    }
}

// formatting de la date de la collection en format pris en charge par javaScript
function parseDate(dateStr) {
    const [month, year, day] = dateStr.replace(',', '').split(' ');
    const monthIndex = {
        'January': 0, 'February': 1, 'March': 2, 'April': 3, 'May': 4, 'June': 5,
        'July': 6, 'August': 7, 'September': 8, 'October': 9, 'November': 10, 'December': 11,
    }[month];
    return new Date(year, monthIndex, day);
}

// pour formatter un nombre de dollar large
const formatLargeDollarNumber = (number, digits) => {
    const value = number / 1000;
    const formattedValue = value.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
      maximumSignificantDigits: digits
    });
    return `${formattedValue}K$`;
  }

export { formatIdName, capitalizeString, parseDate, formatLargeDollarNumber, logAllLayers }