// **********************************************************
// Fonctions de calcul pour les graphiques et choropleths
// **********************************************************

// transforme une valeur non-numérique en int = 1
const anythingToInt = (i) => {
  if (typeof i !== "number") {
    return 1;
  } else {
    return parseInt(i);
  }
};

const tauxStabiliteEmploiPourMrc = (data, mrc) => {
  const filteredData = data.filter((item) => item.MRC === mrc);
  let totalStability = 0;
  const ajustement = 2; // l'ajustement prend en compte que langue et expérience ne peuvent avoir score de 0

  for (let i = 0; i < filteredData.length; i++) {
    const jobPosting = filteredData[i];
    let stabilityScore = 0;
    stabilityScore += jobPosting.type_emploi_note || 2 / 2; // no zero
    stabilityScore += jobPosting.duree_emploi_note || 4 / 2; // no twos
    stabilityScore += jobPosting.horaire_note || 4 / 2; // no twos and threes
    stabilityScore += jobPosting.disponibilite_note || 1 / 2; // no twos
    const stabilityRate =
      ((stabilityScore - ajustement) / (11 - ajustement)) * 100;
    totalStability += stabilityRate;
  }
  const averageStability = totalStability / filteredData.length;
  return averageStability;
};

// calcul le taux d'exigence de l'employeur pour un mrc
const calculTauxExigencePourMrc = (data, mrc) => {
  const filteredData = data.filter((item) => item.MRC === mrc);
  let totalExigence = 0;
  const ajustement = 2; // l'ajustement prend en compte que langue et expérience ne peuvent avoir score de 0

  for (let i = 0; i < filteredData.length; i++) {
    const jobPosting = filteredData[i];
    let exigenceScore = 0;
    exigenceScore += jobPosting.langues_note || 2 / 2; // no zero in db
    exigenceScore += jobPosting.experience_note || 7 / 2; // no even, no zero in db
    exigenceScore += jobPosting.diplome_note || 5 / 2;
    const exigenceRate =
      ((exigenceScore - ajustement) / (13 - ajustement)) * 100;
    totalExigence += exigenceRate;
  }

  const averageExigence = totalExigence / filteredData.length;
  return averageExigence;
};

// calcul le taux d'avantages sociaux pour un mrc
const tauxAvantageSociauxPourMrc = (data, mrc) => {
  const filteredData = data.filter((item) => item.MRC === mrc); // garde le data de la mrc
  let count = 0;

  filteredData.forEach((item) => {
    // on filtre out les champs vides ou NaN
    var as = item.avantages_sociaux; // ***important*** voir ci-bas pour NaN filtering
    if (as !== "ND" && as !== "" && as?.$numberDouble !== "NaN") count++;
  });

  const percentage = (count / filteredData.length) * 100;
  return percentage;
};

// calcule la moyenne de salaire des offres pour un mrc
const calculSalaireMoyenPourMrc = (data, mrc) => {
  const filteredData = data.filter(
    (item) => item.salaire_min >= 10 && item.MRC === mrc
  );
  var nbDePostes = 0;
  const totalSalaireMin = filteredData.reduce((acc, item) => {
    acc += item.salaire_min * parseInt(item.nombre_de_poste);
  }, 0);
  const average = totalSalaireMin / nbDePostes;
  return average; // retourne la moyenne pour le mrc
};

const calculInvestissementPotentielPourMrc = (data, mrc) => {
  const filteredData = data.filter(
    (item) => item.salaire_min >= 10 && item.MRC === mrc
  );
  var totalOffres = 0; // nb de poste total dans le calcul, dénominateur
  var investissement = 0; // nb total d'investissement pour un MRC
  var semaines = 52; // semaines dans un an

  filteredData.forEach((item) => {
    // pour chaque item
    var nb_heure = item.nombre_heure; // on prend son nombre d'heure
    var salaire_horaire = item.salaire_min; // son salaire horaire
    var salaireAnnuel = 0; // on initialise le salaire annuel à 0
    var nbDePostes = parseInt(item.nombre_de_poste);

    if (nb_heure === 0) {
      // si la donnée n'était pas disponible
      if (item.type_emploi === "temps plein")
        nb_heure = 40; // on met 40 pour temps plein
      else if (item.type_emploi === "temps partiel")
        nb_heure = 20; // 20 pour temps partiel
      else if (item.type_emploi === "temps partiel, possibilité de temps plein")
        nb_heure = 20;
      else nb_heure = 0; // sinon ça demeure à 0
    }

    if (nb_heure > 0 && nb_heure < 60) {
      // si le nombre d'heure est calculable
      totalOffres += nbDePostes; // on considère l'offre dans le calcul moyen
      salaireAnnuel += nb_heure * salaire_horaire * semaines * nbDePostes; // on fait le calcul du salaire annuel
    }

    //if (item.duree_emploi === 'saisonnier') salaireAnnuel /= 3  // si l'emploi = saisonnier, on prend 1/3

    investissement += salaireAnnuel; // on totalise le total des salaires annuels
  });

  return investissement / totalOffres; // on retourne le total/offres pour avoir la moyenne
};

// data pour les points sur la carte des offres d'emploi, a besoin du data sur la
// position des entreprises "entreprise_id"
const calculEmploisSelonCoordonnees = (data, mrc, entrepriseId) => {
  const mrcData = data.filter((item) => item.MRC === mrc);
  const emplois = [];
  mrcData.forEach((item) => {
    entrepriseId.forEach((coord) => {
      if (item.adresse === coord.adresse) {
        // Look for existing job with same coordinates and employer
        const existingJob = emplois.find((job) =>
          job.coordinates[0] === coord.longitude &&
          job.coordinates[1] === coord.latitude &&
          job.employeur === item.employeur);

        if (existingJob) {
          // If found, increase the number of posts
          existingJob.nombre_de_poste += parseInt(item.nombre_de_poste, 10);
        } else {
          // If not found, add a new job
          emplois.push({
            coordinates: [coord.longitude, coord.latitude],
            employeur: item.employeur,
            nombre_de_poste: parseInt(item.nombre_de_poste, 10),
          });
        }
      }
    });
  });

  const offset = 0.0001; // Offset by a small amount
  emplois.forEach((job, index) => {
    for (let i = index + 1; i < emplois.length; i++) {
      const otherJob = emplois[i];
      if (job.coordinates[0] === otherJob.coordinates[0] && job.coordinates[1] === otherJob.coordinates[1]) {
        otherJob.coordinates[0] += offset;
        otherJob.coordinates[1] += offset;
      }
    }
  });

  return emplois;
};



/* function nombreDePosteParEmployeur(data, mrc) {
  // Create an object to hold the table data, with keys for each "employeur"
  const tableData = {};

  // Loop through the data and add each item to the tableData object
  data.forEach((item) => {
    if (item.MRC === mrc) {
      const employeur = item.employeur;
      const appelation = item.appelation;
      const publieeDepuisLe = new Date(item.publiee_depuis_le);
      const nombreDePoste = item.nombre_de_poste;

      // If the employeur key does not exist in the tableData object, create it
      if (!tableData.hasOwnProperty(employeur)) {
        tableData[employeur] = {};
      }

      // If the appelation key does not exist under the employeur key, create it
      if (!tableData[employeur].hasOwnProperty(appelation)) {
        tableData[employeur][appelation] = {
          publieeDepuisLe,
          nombreDePoste,
        };
      } else {
        // If the appelation already exists, compare the publieeDepuisLe dates
        const existingPublieeDepuisLe = tableData[employeur][appelation].publieeDepuisLe;
        if (publieeDepuisLe > existingPublieeDepuisLe) {
          tableData[employeur][appelation] = {
            publieeDepuisLe,
            nombreDePoste,
          };
        }
      }
    }
  });

  // Create an empty array to hold the table rows
  const tableRows = [];

  // Loop through the employeur keys in the tableData object and create a row for each employeur
  Object.keys(tableData).forEach((employeur) => {
    // Create a new row array with the employeur name
    const employeurRow = [employeur];

    // Loop through the appelation keys under the employeur key and add a sub-table for each
    Object.keys(tableData[employeur]).forEach((appelation) => {
      // Get the publieeDepuisLe and nombreDePoste values for the current appelation
      const publieeDepuisLe = tableData[employeur][appelation].publieeDepuisLe;
      const nombreDePoste = tableData[employeur][appelation].nombreDePoste;

      // Create a new sub-table array with the header row and the row for the current appelation
      const subTable = [
        ["Appelation", "Publiée depuis le", "Nombre de poste"],
        [appelation, publieeDepuisLe.toLocaleDateString(), nombreDePoste.toString()],
      ];

      // Add the sub-table to the employeur row array
      employeurRow.push(subTable);
    });

    // Add the employeur row to the tableRows array
    tableRows.push(employeurRow);
  });

  // Return the tableRows array
  console.log(tableRows)
  return tableRows;
} */

/* const top5PostesPlusDemandes = (data, mrc) => {
  let compte = {}; // liste qui tient le compte de chaque appellation
  let counts = {}; // liste qui tient le compte de chaque employeur par appellation
  data.forEach(item => {                      // on itère
    if (item.MRC === mrc) {                   // on regarde que pour la mrc
      if (compte[item.appelation]) {          // si l'appelation est dans la liste
        compte[item.appelation] += parseInt(item.nombre_de_poste); // on ajoute le nb de poste
      } else {                                // sinon
        compte[item.appelation] = parseInt(item.nombre_de_poste);  // on ajoute l'appelation à la liste
      }
      if (counts[item.appelation]) {          // si l'appelation est dans la liste
        if (!counts[item.appelation].includes(item.employeur)) {  // si l'employeur n'est pas dans la liste
          counts[item.appelation].push(item.employeur);            // on ajoute l'employeur à la liste
        }
      } else {                                // sinon
        counts[item.appelation] = [item.employeur]; // on ajoute l'appelation et l'employeur à la liste
      }
    }
  })
  // on les place en ordre
  compte = Object.entries(compte).sort((a, b) => b[1] - a[1])
  compte = compte.slice(0, 5)                  // on prend que les 5 premiers
  compte = compte.map(([name, value]) => ({ name, value, employeurs: counts[name].length })); // ajouter le comptage des employeurs
  return compte;
}; */

/* const totalPostesPourMrc = (data, mrc) => {
  const filteredData = data.filter(item => item.MRC === mrc)
  const total = filteredData.reduce((acc, item) => acc + (anythingToInt(item.nombre_de_poste)), 0)
  return total
} */

export {
  tauxStabiliteEmploiPourMrc,
  calculSalaireMoyenPourMrc,
  //calculTypeEmploi,
  calculEmploisSelonCoordonnees,
  //nombreDePosteParEmployeur,
  //top5PostesPlusDemandes,
  //totalPostesPourMrc,
  tauxAvantageSociauxPourMrc,
  calculInvestissementPotentielPourMrc,
  calculTauxExigencePourMrc,
};
