//styled components
import {
  StyledTextInput,
  StyledFormArea,
  StyledFormButton,
  StyledLabel,
  StyledContainer,
  Avatar,
  StyledTitle,
  colors,
  ButtonGroup,
  ExtraText,
  TextLink,
  CopyrightText,
  StyledIcon,
} from "../../Styles";

//Logo
import Logo from "./../../src-assets/civision-logo-copy.png";

//Formik
import { Formik, Form, Field } from "formik";
import { TextInput } from "./FormLib";
import * as Yup from "yup";

//Icons
import { FiMail, FiLock, FiUser, FiMap } from "react-icons/fi";

//Loader
import { ThreeDots } from "react-loader-spinner";

// Auth & Redux
import { connect } from "react-redux";
import { signupUser } from "../../auth/actions/userActions";
import { useNavigate } from "react-router-dom";

const Signup = ({ signupUser }) => {
  const history = useNavigate();
  function handleDropdownChange(event) {
    const selectedOption = event.target.value;
    // selected option for region
  }

  return (
    <StyledContainer>
      <div>
        <StyledFormArea>
          <Avatar image={Logo} />
          <StyledTitle color={colors.theme} size={30}>
            Member Signup
          </StyledTitle>
          <Formik
            initialValues={{
              email: "",
              password: "",
              repeatPassword: "",
              region: "Mauricie",
              name: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              password: Yup.string()
                .min(8, "Password is too short")
                .max(30, "Password is too long")
                .required("Required"),
              name: Yup.string().required("Required"),
              repeatPassword: Yup.string()
                .required("Required")
                .oneOf([Yup.ref("password")], "Passwords must match"),
            })}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              signupUser(values, history, setFieldError, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextInput
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Nicolas"
                  icon={<FiUser />}
                />

                <TextInput
                  name="email"
                  type="text"
                  label="Email Address"
                  placeholder="nico@example.com"
                  icon={<FiMail />}
                />

                <div style={{ position: "relative" }}>
                  <StyledLabel>Choose a region</StyledLabel>
                  <Field
                    as="select"
                    name="region"
                    type="text"
                    style={{
                      width: "280px",
                      padding: "15px",
                      paddingLeft: "50px",
                      fontSize: "17px",
                      letterSpacing: "1px",
                      color: `${colors.dark1}`,
                      backgroundColor: `${colors.light2}`,
                      border: 0,
                      outline: 0,
                      margin: "2px auto 10px auto",
                      transition: "ease-in-out 0.3s",
                    }}
                  >
                    <option value="Abitibi-Témiscamingue">
                      Abitibi-Témiscamingue
                    </option>
                    <option value="Bas-Saint-Laurent">Bas-Saint-Laurent</option>
                    <option value="Capitale-Nationale">
                      Capitale-Nationale
                    </option>
                    <option value="Centre-du-Québec">Centre-du-Québec</option>
                    <option value="Chaudière-Appalaches">
                      Chaudière-Appalaches
                    </option>
                    <option value="Côte-Nord">Côte-Nord</option>
                    <option value="Estrie">Estrie</option>
                    <option value="Gaspésie-Îles-de-la-Madeleine">
                      Gaspésie-Îles-de-la-Madeleine
                    </option>
                    <option value="Lanaudière">Lanaudière</option>
                    <option value="Laurentides">Laurentides</option>
                    <option value="Laval">Laval</option>
                    <option value="Mauricie">Mauricie</option>
                    <option value="Montérégie">Montérégie</option>
                    <option value="Montréal">Montréal</option>
                    <option value="Nord-du-Québec">Nord-du-Québec</option>
                    <option value="Outaouais">Outaouais</option>
                    <option value="Saguenay-Lac-Saint-Jean">
                      Saguenay-Lac-Saint-Jean
                    </option>
                  </Field>
                  <StyledIcon>
                    <FiMap />
                  </StyledIcon>
                </div>

                <TextInput
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="*******"
                  icon={<FiLock />}
                />

                <TextInput
                  name="repeatPassword"
                  type="password"
                  label="Repeat Password"
                  placeholder="*******"
                  icon={<FiLock />}
                />

                <ButtonGroup>
                  {!isSubmitting && (
                    <StyledFormButton type="submit">Signup</StyledFormButton>
                  )}

                  {isSubmitting && (
                    <ThreeDots
                      type="ThreeDots"
                      color={colors.theme}
                      height={49}
                      width={100}
                    />
                  )}
                </ButtonGroup>
              </Form>
            )}
          </Formik>
          <ExtraText>
            Already have an account? <TextLink to="/login">Login</TextLink>
          </ExtraText>
        </StyledFormArea>
        <CopyrightText>All rights reserved &copy;2023</CopyrightText>
      </div>
    </StyledContainer>
  );
};

export default connect(null, { signupUser })(Signup);
