import React from 'react';
import Plot from 'react-plotly.js';

const IndicatorBox = ({ width, height, value, precision, suffix, previous, reverse }) => {

  const outlineWeight = 0.4
  const outlineColour = 'rgba(30,30,30,0.4)'

  var deltaColors = []
  if (reverse) deltaColors = ["#B22222", "green"]
  else deltaColors = ["green", "#B22222"]

  const indicatorBoxStyle = {
    textShadow: `${outlineWeight}px 0 ${outlineColour}, 0 -${outlineWeight}px ${outlineColour}, -${outlineWeight}px 0 ${outlineColour}, 0 ${outlineWeight}px ${outlineColour}`,
    display: 'flex',
    justifyContent: 'center',
  };

  var data = [
    {
      type: "indicator",
      mode: "number+delta",
      value: value,
      number: { valueformat: ',.' + precision + "f", suffix: suffix },
      domain: { x: [0, 0], y: [0, 0] },
      delta: { reference: previous, relative: true, position: "bottom", valueformat: ".1%", increasing: { color: deltaColors[0] }, decreasing: { color: deltaColors[1] } }
    }
  ];

  const layout = {
    height: height * 0.8,
    width: width,
    autosize: true,
    margin: { t: 0, r: 0, l: 0, b: 0 },
    font: { size: 20, color: "#0077c2" },
    paper_bgcolor: 'transparent'

  };

  return (
    <div style={indicatorBoxStyle}>
      <Plot key='4' data={data} layout={layout} config={{ responsive: true, displayModeBar: false }} />
    </div>
  );
};

export default IndicatorBox;
