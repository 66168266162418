import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area } from 'recharts';
import { findRangeOfDays } from '../data/Calculations';

const LineChartBox = ({ width, height, data, typeDonnee, periode }) => {
  const theme = useTheme();

  // to do :
  // ajouter un paramètre pour savoir s'il s'agit d'un total ou d'une moyenne
  // next time just split the data at the half point instead of sending two arrays
  // one for current, one for previous

  const words = periode[0].split(' ');
  const previous_data_words = `${words[0]} ${words[2]}`;

  let precision;

  switch (typeDonnee) {
    case '%': precision = 1; break;
    case '$': precision = 2; break;
    default: precision = 0;
  }

  const rangeOfDaysAndLimits = findRangeOfDays(data)
  const formattedData = groupDataByRange(rangeOfDaysAndLimits[0], data)
  const newData = removeNanEntries(formattedData, 'previous_value')
  const highestValue = Math.max(...newData.map(obj => obj.value))

  function groupDataByRange(rangeOfDays, data) {
    switch (true) {
      case rangeOfDays < 33: return groupDataByDay(data)
      case rangeOfDays < 99: return groupDataByWeek(data)
      default: return groupDataByMonth(data)
    }
  }

  function removeNanEntries(data, field) {
    for (let i = 0; i < data.length; i++) {
      if (isNaN(data[i][field]) || data[i][field] === 0) {
        data[i][field] = null;
      }
    }
    return data;
  }

  // *****************************************************************************
  // *********************** GROUP DATA BY DAY ***********************************
  // *****************************************************************************

  function groupDataByDay(data) {
    const chartData = []

    data.forEach((item) => {
      const date = item.name.substring(4, 7) + ' ' + item.name.substring(8, 10)
      const value = item.value
      const previousValue = item.previous_value
      chartData.push({ name: date, value: value, previous_value: previousValue })
    })

    return chartData
  }

  // *****************************************************************************
  // *********************** GROUP DATA BY WEEK **********************************
  // *****************************************************************************

  function groupDataByWeek(data) {
    const groupedData = {}
    const chartData = []

    const latest = rangeOfDaysAndLimits[2]
    const earliest = rangeOfDaysAndLimits[1]
    const weekInMs = 86400000 * 7

    data.forEach((item) => { // on ajoute la valeur de chaque date en ms à la liste
      const date = Date.parse(item.name)
      item.milliseconds = date
    })

    let currentWeek = latest // on va itérer pour sortir les entrées correspondantes
    // à chaque semaine
    while (currentWeek > earliest) {    // tant qu'on ne regarde pas à l'extérieur de notre range
      data.forEach((item) => {          // on itère
        if (item.milliseconds > currentWeek - weekInMs && item.milliseconds <= currentWeek) {
          if (!groupedData[currentWeek]) groupedData[currentWeek] = [] // on ajoute un array de semaine si l'existe pas
          groupedData[currentWeek].push(item) // on ajoute les items faisant partie de cette semaine
        }
      })
      currentWeek -= weekInMs    // on recule d'une semaine
    }

    // rename each key in groupedData to its equivalent date string
    for (const week in groupedData) {
      const currentWeek = groupedData[week]
      let totalWeight = 0 // on réinitialise pour le calcul
      let totalValues = 0 // ^^^^
      let previousTotalWeight = 0
      let previousTotalValues = 0

      for (const day in currentWeek) {
        const currentDay = currentWeek[day]
        totalValues += currentDay.value * currentDay.weight // on totalise le salaire x poids
        totalWeight += currentDay.weight                    // on prend note du poids total
        previousTotalValues += currentDay.previous_value * currentDay.previous_weight
        previousTotalWeight += currentDay.previous_weight
      }

      let weeklyAmount;
      let previousAmount

      typeDonnee === '$' ? weeklyAmount = totalValues / totalWeight // on fait la moyenne
        : weeklyAmount = totalValues // ou pas si c'est des totaux
      typeDonnee === "$" ? previousAmount = previousTotalValues / previousTotalWeight
        : previousAmount = previousTotalValues

      chartData.push({ name: week, value: weeklyAmount.toFixed(precision), previous_value: previousAmount.toFixed(precision) })
    }

    const sortedChartData = chartData.sort((a, b) => a.name - b.name);
    const renamedChartData = sortedChartData.map(obj => {
      const week = new Date(parseInt(obj.name)).toDateString();
      return { ...obj, name: week.substring(4, 7) + ' ' + week.substring(8, 10) };
    })

    return renamedChartData.slice(1);
  }

  // *****************************************************************************
  // *********************** GROUP DATA BY MONTH *********************************
  // *****************************************************************************

  function groupDataByMonth(data) {
    const groupedData = {}
    const chartData = []

    data.forEach((item) => { // <---- pour chaque item dans data
      // on prend une portion de la date correspondant au mois:
      const dateStr = item.name.substr(4, 3) + ", " + item.name.substr(11, 4)
      // on regarde si le mois est dans groupedData, sinon on l'ajoute:
      if (!groupedData[dateStr]) groupedData[dateStr] = [];
      groupedData[dateStr].push(item)   // on ajoute l'item 
    });

    for (const month in groupedData) { // pour chaque mois
      const currentMonth = groupedData[month]
      let totalWeight = 0 // on réinitialise pour le calcul
      let totalValues = 0 // ^^^^
      let previousTotalWeight = 0
      let previousTotalValues = 0

      for (const day in currentMonth) {
        const currentDay = currentMonth[day]
        totalValues += currentDay.value * currentDay.weight // on totalise le salaire x poids
        totalWeight += currentDay.weight                    // on prend note du poids total
        previousTotalValues += currentDay.previous_value * currentDay.previous_weight
        previousTotalWeight += currentDay.previous_weight
      }

      let monthlyAmount
      let previousAmount

      typeDonnee === '$' ? monthlyAmount = totalValues / totalWeight // on fait la moyenne
        : monthlyAmount = totalValues // ou pas si c'est des totaux
      typeDonnee === "$" ? previousAmount = previousTotalValues / previousTotalWeight
        : previousAmount = previousTotalValues

      chartData.push({ name: month, value: monthlyAmount.toFixed(precision), previous_value: previousAmount.toFixed(precision) })
    }

    // convert the month and year strings to Date objects
    const arrWithDates = chartData.map(obj => {
      const monthYearArr = obj.name.split(',');
      const month = monthYearArr[0];
      const year = monthYearArr[1].trim();
      return { ...obj, date: new Date(`${month} 1, ${year}`) };
    });

    // sort the array based on the date value and rename the months to 3 letters
    const sortedChartData = arrWithDates.sort((a, b) => a.date - b.date);
    const renamedChartData = sortedChartData.map(obj => {
      const month = obj.name.substring(0, 3);
      return { ...obj, name: month };
    });

    return renamedChartData.slice(0, -1);
  }

  return (
    <div style={{ marginTop: height * 0.1, marginLeft: 15, marginRight: 15 }}>
      <AreaChart width={width * 1.125} height={height * 0.8} data={newData} fontSize={9} margin={{ right: 20, left: 20, top: 20 }}>
        <CartesianGrid strokeDasharray="5 5" opacity={0.4} />
        <XAxis dataKey="name" stroke={theme.palette.text.primary}/>
        <YAxis width={25} domain={[0, Math.round((highestValue * 1.25 / 10)) * 10]} tickFormatter={tickValue => `${tickValue}${typeDonnee}`} stroke={theme.palette.text.primary}/>
        {/* <Tooltip formatter={(value, name, props) => [`${value}${typeDonnee}`]} /> */}
        <Tooltip formatter={(value, name, props) => {
          if (value === 'ND') {
            return ['']; // return null to exclude this data point from the tooltip
          }
          return [`${value}${typeDonnee}`];
        }} />
        <Area type="linear" dataKey="value" stroke="#82ca9d" fill="rgba(0, 158, 115, 0.6)"
          animationDuration={400} dot={{ stroke: 'green', strokeWidth: 1, fill: '#82ca9d', r: 2 }}
          animationBegin={0} name="période en cours"
        />
        <Area type="linear" dataKey="previous_value" stroke="rgba(235, 148, 0, 0.75)" fillOpacity={0}
          strokeDasharray="2 2" name={`${previous_data_words} précédents`}
          connectNulls={true}
          animationDuration={400} dot={{ stroke: 'red', strokeWidth: 1, fill: '#82ca9d', r: 2 }}
          animationBegin={0} />
        <Legend verticalAlign="bottom" height={36} />
      </AreaChart>
    </div>
  );
};

export default LineChartBox;