import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import "../../index.css";
import { useState } from "react";
import Select from "react-select";
import { Typography, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setPeriode,
  setSecteurActivite,
  setTypePoste,
  selectFlyToRegion,
  setZoneAdministrative,
  setChoixDonnee,
  selectChoropleth,
} from "../../reducers/mapStore";
import mrcNamesList from "../../constants/NomsDesMrcs";
import {
  filterDataByDate,
  filterDataForChosenMrc,
  deduireListeSecteursActivites,
} from "../../data/Calculations";
import {
  histoSetPeriode,
  histoSetSecteurActivite,
  histoSetTypePoste,
  histoSetZoneAdministrative,
} from "../../reducers/histoStore";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";

function FilterBar() {

  //------------------------------------------------------------------------
  // app version:
  const appVersion = "3.2.3";
  //------------------------------------------------------------------------
  //------------------------------------------------------------------------

  const dispatch = useDispatch();
  const location = useLocation();
  //const [value, setValue] = useState(null);
  const [keySecteur, setKeySecteur] = useState(0);
  const [keyType, setKeyType] = useState(0);
  const [activeButton, setActiveButton] = useState("gallery");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [secteurActiviteOptions, setSecteurActiviteOptions] = useState([{ value: "Tous les secteurs", label: "Tous les secteurs" },]);
  const [typePosteOptions, setTypePosteOptions] = useState([{ value: "Tous les types", label: "Tous les types" },]);
  const regionsDisponibles = useSelector((state) => state.mapStore.regionsDisponibles);
  //const choropleth = useSelector((state) => state.mapStore.choropleth);
  const periode = useSelector((state) => state.mapStore.periode);
  const zoneAdministrative = useSelector((state) => state.mapStore.zoneAdministrative);
  const secteurActivite = useSelector((state) => state.mapStore.secteurActivite);
  //const typePoste = useSelector((state) => state.mapStore.typePoste);
  const jobListings = useSelector((state) => state.dataStore.jobListings);
  const nettoyage = useSelector((state) => state.dataStore.nettoyage);
  const choixDonnee = useSelector((state) => state.mapStore.choixDonnee);
  //const [regionsDisponiblesOptions, setRegionsDisponiblesOptions] = useState([]);
  const mrcsDisponibles = [];

  useEffect(() => {
    // The switch statement checks the current route and sets the active button accordingly
    switch (location.pathname) {
      case "/gallerie":
        setActiveButton("gallery");
        //if (choixDonnee === undefined) setChoixDonnee("Retour à l'accueil")
        break;
      case "/tableau":
        setActiveButton("table");
        break;
      case "/carte":
        setActiveButton("card");
        break;
      default:
        setActiveButton("");
    }
  }, [location]);

  // calcul pour sortir les mrcs selon la région du user et populer la liste du dropdown
  // --------------------------------------------
  regionsDisponibles.forEach((region) => {
    const listOfMrcs = mrcNamesList.filter((mrc) => mrc.REG === region);
    listOfMrcs.forEach((mrc) => mrcsDisponibles.push(mrc.MRC));
  }); // et ensuite en ordre alphabétique:
  mrcsDisponibles.sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
  mrcsDisponibles.unshift(`Toute la région`);
  // --------------------------------------------
  // -------------------------------
  // -----------------
  // ------

  const filterWrapperStyle = {
    margin: 0,
    width: 200,
  };

  const typographyFilterStyle = {
    fontWeight: "bold",
    marginBottom: "10px",
    fontSize: "1.1em",
    display: "flex",
    alignItems: "center",
  };

  const periodeOptions = [
    { value: 7, label: "7 derniers jours" },
    { value: 30, label: "30 derniers jours" },
    { value: 91, label: "3 derniers mois" },
    { value: 182, label: "6 derniers mois" },
    { value: 365, label: "12 derniers mois" },
  ];

  useEffect(() => {
    // initialisation des choix par défaut:
    dispatch(setPeriode([periodeOptions[1].label, periodeOptions[1].value]));
    dispatch(setZoneAdministrative(mrcsDisponibles[0]));
    dispatch(setSecteurActivite(secteurActiviteOptions[0]?.value));
    dispatch(setTypePoste(typePosteOptions[0]?.value));
  }, []);

  useEffect(() => {
    // update
    const listeSecteursActivites = calculerChoixSecteurActivite();
    setSecteurActiviteOptions(listeSecteursActivites);
    dispatch(setSecteurActivite("Tous les secteurs"));
    setKeySecteur(keySecteur + 1); // pour rerender les dropdowns lorsque les choix changent
  }, [periode, zoneAdministrative]);

  useEffect(() => {
    const filteredOptions = secteurActiviteOptions.filter(
      (option) => option.secteur === secteurActivite
    );
    const options = filteredOptions.flatMap((option) =>
      option.type.map((type) => ({ value: type, label: type }))
    );
    setTypePosteOptions(options);
    dispatch(setTypePoste("Tous les types"));
    setKeyType(keyType + 1); // pour rerender les dropdowns lorsque les choix changent
  }, [secteurActivite]);

  const calculerChoixSecteurActivite = () => {
    // retourne la liste des secteurs d'activités pour dropdown
    const dataForRegion = jobListings[regionsDisponibles[0]];
    const dataForMrc = filterDataForChosenMrc(
      dataForRegion,
      zoneAdministrative
    );
    const dataForPeriod = filterDataByDate(dataForMrc, periode[1]);
    const listeSecteursActivites = deduireListeSecteursActivites(
      dataForPeriod.current,
      nettoyage
    );
    return listeSecteursActivites;
  };

  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const handlePeriodeChange = (event) => {
    dispatch(setPeriode([event.label, event.value]));
    const selectedPeriod = event.value;
    dispatch(histoSetPeriode(selectedPeriod));
    setSelectedPeriod(selectedPeriod);
  };
  const handleMrcChange = (event) => {
    //dispatch(selectFlyToRegion(event.value))
    dispatch(setZoneAdministrative(event.value));
    dispatch(histoSetZoneAdministrative(event.value));
  };

  const handleSecteurChange = (event) => {
    dispatch(setSecteurActivite(event.value));
    dispatch(histoSetSecteurActivite(event.value));
  };

  const handleTypePosteChange = (event) => {
    dispatch(setTypePoste(event.value));
    dispatch(histoSetTypePoste(event.value));
  };

  const handleButtonClick = (buttonName) => {
    dispatch(selectFlyToRegion(regionsDisponibles[0]));
    setActiveButton(buttonName);
    dispatch(setChoixDonnee("Retour à l'accueil"));
  };

  const deselectDonnees = () => {
    dispatch(setChoixDonnee(undefined))
    dispatch(selectChoropleth(undefined))
  }

  return (
    <div style={{ backgroundColor: `${colors.primary[500]}`, paddingTop: "15px" }}>
      <Box
        fontSize={13}
        display="flex"
        justifyContent="space-between"
        p={1}
        borderRadius="3px"
        style={{
          transition: "width 1s ease-in-out",
          height: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 2px",
        }}
      >
        {/*         <img
          src={`../../assets/filter-icon.png`}
          alt="filter-icon"
          width="30px"
          height="30px"
          style={{ marginLeft: "45px" }}
        /> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "calc(100% - 70px)",
            marginLeft: "3%",
            fontWeight: "bold",
            zIndex: 1000,
            marginTop: "5px",
          }}
        >
          {/* PERIODE MENU */}
          <div style={filterWrapperStyle}>
            <Typography sx={typographyFilterStyle}>Période</Typography>
            <Box id="filter1">
              <Select
                onChange={handlePeriodeChange}
                options={periodeOptions}
                defaultValue={periodeOptions[1].value}
                placeholder={periodeOptions[1].label}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    backgroundColor: `${colors.primary[500]}`
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: `${colors.primary[200]}`
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: `${colors.primary[500]}`
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? 'rgba(17,93,127,0.9)' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                  }),
                  singleValue: (base, state) => ({
                    ...base,
                    color: state.isSelected ? "black" : `${colors.primary[200]}`

                  }),
                }}
              />
            </Box>
          </div>
          {/* ZONE ADMINISTRATIVE MENU */}
          <div style={filterWrapperStyle}>
            <Typography sx={typographyFilterStyle}>Comté (MRC)</Typography>
            <Box>
              <Select
                isDisabled={
                  location.pathname === "/carte" ||
                  choixDonnee === "Retour à l'accueil"
                }
                onChange={handleMrcChange}
                options={mrcsDisponibles.map((region) => ({
                  value: region,
                  label: region,
                }))}
                defaultValue={mrcsDisponibles[0]}
                placeholder={mrcsDisponibles[0]}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    backgroundColor: `${colors.primary[500]}`,
                    opacity: state.isDisabled ? ".75" : "1",
                  }),
                  placeholder: (base, state) => ({
                    ...base,
                    color: `${colors.primary[200]}`,
                    opacity: state.isDisabled ? ".5" : "1",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: `${colors.primary[500]}`
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? 'rgba(17,93,127,0.9)' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: `${colors.primary[200]}`
                  }),
                }}
              />
            </Box>
          </div>
          {/* SECTEUR D'ACTIVITÉ MENU */}
          <div style={filterWrapperStyle}>
            <Typography sx={typographyFilterStyle}>
              Secteur d'Activité
            </Typography>
            <Box>
              <Select
                key={keySecteur}
                onChange={handleSecteurChange}
                options={secteurActiviteOptions.map((item) => ({
                  value: item.secteur,
                  label: item.secteur,
                }))}
                defaultValue={"Tous les secteurs"}
                placeholder={"Tous les secteurs"}
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: `${colors.primary[500]}`
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: `${colors.primary[200]}`
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: `${colors.primary[500]}`
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? 'rgba(17,93,127,0.9)' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: `${colors.primary[200]}`
                  }),
                }}
              />
            </Box>
          </div>
          {/* TYPE DE POSTE MENU */}
          <div style={filterWrapperStyle}>
            <Typography sx={typographyFilterStyle}>Type de Poste</Typography>
            <Box>
              <Select
                key={keyType}
                onChange={handleTypePosteChange}
                options={typePosteOptions}
                defaultValue={"Tous les types"}
                placeholder={"Tous les types"}
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: `${colors.primary[500]}`
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: `${colors.primary[200]}`
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: `${colors.primary[500]}`
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? 'rgba(17,93,127,0.9)' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: `${colors.primary[200]}`
                  }),
                }}
              />
            </Box>
          </div>
        </div>
      </Box>
      <Box ml="2%" mr="5%" mt="1.5%" mb="0px">
        <div className="button-container">
          <div className="buttons-left">
            <Link to="/gallerie" className="link">
              <Button
                className={`viewButton ${activeButton === "gallery" && "active"
                  }`}
                sx={{
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#CCDB77",
                    opacity: 0.8
                  },
                  backgroundColor:
                    activeButton === "gallery"
                      ? ""
                      : `${colors.primary[400]}`,
                }}
                onClick={() => handleButtonClick("gallery")}
              >
                <Typography
                  variant="h6"
                  style={{ color: `${colors.primary[600]}` }}
                >
                  Gallerie
                </Typography>
                <CollectionsOutlinedIcon
                  sx={{
                    ml: "5px",
                    fontSize: "25px",
                    color: `${colors.primary[600]}`,
                  }}
                />
              </Button>
            </Link>
            <Link to="/carte" className="link">
              <Button
                className={`viewButton ${activeButton === "card" && "active"
                  }`}
                sx={{
                  textTransform: "none",
                  backgroundColor:
                    activeButton === "card"
                      ? ""
                      : `${colors.primary[400]}`,
                  "&:hover": {
                    backgroundColor: "#CCDB77",
                    opacity: 0.8
                  },
                }}
                onClick={() => handleButtonClick("card")}
              >
                <Typography
                  variant="h6"
                  style={{ color: `${colors.primary[600]}` }}
                >
                  Carte
                </Typography>
                <MapOutlinedIcon
                  sx={{
                    ml: "5px",
                    fontSize: "25px",
                    color: `${colors.primary[600]}`,
                  }}
                />
              </Button>
            </Link>
            <Link to="/tableau" className="link">
              <Button
                className={`viewButton ${activeButton === "table" && "active"
                  }`}
                sx={{
                  textTransform: "none",
                  backgroundColor:
                    activeButton === "table"
                      ? ""
                      : `${colors.primary[400]}`,
                  "&:hover": {
                    backgroundColor: "#CCDB77",
                    opacity: 0.8
                  },
                }}
                onClick={() => {
                  handleButtonClick("table")
                  deselectDonnees()
                }}
              >
                <Typography
                  variant="h6"
                  style={{ color: `${colors.primary[600]}` }}
                >
                  Tableau
                </Typography>
                <TableRowsOutlinedIcon
                  sx={{
                    ml: "5px",
                    fontSize: "25px",
                    color: `${colors.primary[600]}`,
                  }}
                />
              </Button>
            </Link>
          </div>
          <div className="buttons-right">
            <div style={{ color: `${colors.primary[600]}`, opacity: 0.5, marginRight: 10 }}>Version {appVersion}</div>
          </div>
        </div>
      </Box>

      <div
        style={{
          height: "6px",
          width: "100%",
          backgroundColor: `${colors.primary[400]}`,
          marginLeft: "0px",
        }}
      ></div>
    </div>
  );
}

export default FilterBar;
