import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import ListIcon from "@mui/icons-material/List";
import { useDispatch, useSelector } from "react-redux";
import { selectChoropleth, setChoixDonnee } from "../../reducers/mapStore";
import { PictureAsPdf } from "@mui/icons-material";

const menuOptions = [
  { value: "Retour à l'accueil" },
  { value: "Stabilité de l'emploi" },
  { value: "Exigences de l'employeur" },
  { value: "Avantages sociaux" },
  { value: "Salaires" },
  { value: "Historique des recherches" },
  { value: "Liste des entreprises" },
  { value: "Sélections PDF" },
];

const Item = ({ title, to, icon, selected, setSelected, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const choixDonnee = useSelector((state) => state.mapStore.choixDonnee);
  const location = useLocation();
  const navigate = useNavigate();
  const isTableauRoute = location.pathname === '/tableau';

  useEffect(() => {
    setSelected(choixDonnee)
  }, [choixDonnee]);

  const handleMenuClick = () => {
    if (isTableauRoute) navigate('/gallerie');
    setSelected(title);
    dispatch(setChoixDonnee(title));
    title === menuOptions[0].value ? dispatch(selectChoropleth(undefined)) : dispatch(selectChoropleth(title));
    setIsCollapsed(true);
  };

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: /* isTableauRoute ? 'gray' :  */`${colors.grey[100]}`,
        '&:hover': {
          color: /* isTableauRoute ? 'gray !important' : */ '#CCDB77',
        },
      }}
      onClick={handleMenuClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("");
  const choixDonnee = useSelector((state) => state.mapStore.choixDonnee);
  const location = useLocation();
  const isTableauRoute = location.pathname === '/tableau';

  useEffect(() => {
    if (choixDonnee === undefined) setSelected("Retour à l'accueil")
    if (choixDonnee === "Sélections PDF") navigate('/gallerie');
  }, [choixDonnee]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-menu-item": {
          padding: "0px 0px 0px 0px !important",
        },
        "& .pro-menu-item:hover": {
          cursor: "not-allowed !important",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: /* isTableauRoute ? "" : */ "#CCDB77 !important",
        },
        "& .pro-menu-item.active": {
          color: "#a8b560 !important",
        },
        "& .pro-menu-item>.pro-inner-item:hover .pro-icon-wrapper .pro-icon": {
          animation: isTableauRoute ? "none !important" : "swing ease-in-out 0.1s 1 normal !important",
        },
        overflow: "hidden",
        backgroundColor: `${colors.primary[400]}`,
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        style={{ minHeight: "100vh" }}
        width="245px"
      >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={
              isCollapsed ? (
                <MenuOutlinedIcon fontSize="large" />
              ) : (
                <MenuOutlinedIcon fontSize="large" />
              )
            }
            style={{
              margin: "15px 0 0 0",
              color: `${colors.grey[100]}`,
            }}
          ></MenuItem>

          <Typography
            variant="h6"
            color={colors.grey[300]}
            sx={{
              m: isCollapsed ? "15px 3px 5px 0px" : "15px 0px 5px 15px",
              paddingTop: "20px",
              textAlign: isCollapsed ? "center" : "left",
            }}
          >
            Accueil
          </Typography>
          <Box>
            <Item
              title={menuOptions[0].value}
              //to="/gallerie"
              icon={
                <HomeOutlinedIcon
                  fontSize="large"
                  sx={{ m: "0px 0px 0px 0px" }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setIsCollapsed={setIsCollapsed}
            />
          </Box>

          <Typography
            variant="h6"
            color={colors.grey[300]}
            sx={{
              m: isCollapsed ? "10px 3px 5px 0px" : "10px 0px 5px 15px",
              textAlign: isCollapsed ? "center" : "left",
              paddingTop: "50px",
            }}
          >
            Données
          </Typography>
          <Box sx={{ m: "10px 0px 0px 0px" }}>
            <Box sx={{ m: "10px 0px 0px 0px" }}>
              <Item
                sx={{ m: "20px 0px 0px 0px" }}
                title={menuOptions[1].value}
                icon={
                  <WorkOutlineIcon
                    fontSize="large"
                    sx={{ m: "0px 0px 0px 0px" }}
                  />
                }
                selected={selected}
                setSelected={setSelected}
                setIsCollapsed={setIsCollapsed}
              />
            </Box>
            <Box sx={{ m: "10px 0px 0px 0px" }}>
              <Item
                sx={{ m: "20px 0px 0px 0px" }}
                title={menuOptions[2].value}
                icon={
                  <BadgeOutlinedIcon
                    fontSize="large"
                    sx={{ m: "0px 0px 0px 0px" }}
                  />
                }
                selected={selected}
                setIsCollapsed={setIsCollapsed}
                setSelected={setSelected}
              />
            </Box>
            <Box sx={{ m: "10px 0px 0px 0px" }}>
              <Item
                sx={{ m: "20px 0px 0px 0px" }}
                title={menuOptions[3].value}
                icon={
                  <VolunteerActivismOutlinedIcon
                    fontSize="large"
                    sx={{ m: "0px 0px 0px 0px" }}
                  />
                }
                selected={selected}
                setIsCollapsed={setIsCollapsed}
                setSelected={setSelected}
              />
            </Box>
            <Box sx={{ m: "10px 0px 0px 0px" }}>
              <Item
                sx={{ m: "50px 0px 0px 0px" }}
                title={menuOptions[4].value}
                icon={
                  <RequestQuoteOutlinedIcon
                    fontSize="large"
                    sx={{ m: "0px 0px 0px 0px" }}
                  />
                }
                selected={selected}
                setIsCollapsed={setIsCollapsed}
                setSelected={setSelected}
              />
            </Box>
            <br />
            <br />
            <Box sx={{ m: "10px 0px 0px 0px" }}>
              <Item
                sx={{ m: "50px 0px 0px 0px" }}
                title={menuOptions[7].value}
                icon={
                  <PictureAsPdf
                    fontSize="large"
                    sx={{ m: "0px 0px 0px 0px" }}
                  />
                }
                selected={selected}
                setIsCollapsed={setIsCollapsed}
                setSelected={setSelected}
              />
            </Box>
          </Box>
        </Menu>
      </ProSidebar>
    </Box >
  );
};

export default Sidebar;
