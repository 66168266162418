import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flyToRegion: "",
  zoneAdministrative: "",
  mrcList: [], // format [mrc, region, coordonnées] pour map
  legendValues: [null, null, null, null, null, null],
  donneePopup: "Salaire moyen",
  choropleth: undefined,
  choixDonnee: "Retour à l'accueil",
  periode: [],
  secteurActivite: "Tous les secteurs",
  typePoste: "Tous les types",
  selectedMrcs: [], // pour gallerie
  regionsDisponibles: [
    "Lanaudière",
    "Montérégie",
    "Laval",
    "Montréal",
    "Laurentides",
    "Centre-du-Québec",
    "Mauricie",
    "Estrie",
    "Capitale-Nationale",
  ],
};

export const mapStoreSlice = createSlice({
  name: "mapStore",
  initialState,
  reducers: {
    selectFlyToRegion: (state, action) => {
      state.flyToRegion = action.payload;
    },
    addMrcToList: (state, action) => {
      state.mrcList = [...state.mrcList, action.payload];
    },
    removeMrcFromList: (state, action) => {
      state.mrcList = state.mrcList.filter((mrc) => mrc[0] !== action.payload);
    },
    clearMrcList: (state) => {
      state.mrcList = [];
    },
    selectDonneePopup: (state, action) => {
      state.donneePopup = action.payload;
    },
    selectChoropleth: (state, action) => {
      state.choropleth = action.payload;
    },
    setZoneAdministrative: (state, action) => {
      state.zoneAdministrative = action.payload;
    },
    setChoixDonnee: (state, action) => {
      state.choixDonnee = action.payload;
    },
    setPeriode: (state, action) => {
      state.periode = action.payload;
    },
    setSecteurActivite: (state, action) => {
      state.secteurActivite = action.payload;
    },
    setTypePoste: (state, action) => {
      state.typePoste = action.payload;
    },
    setLegendValues: (state, action) => {
      state.legendValues = action.payload;
    },
    selectMrc: (state, action) => {
      state.selectedMrcs = [...state.selectedMrcs, action.payload];
    },
    removeMrc: (state, action) => {
      state.selectedMrcs = state.selectedMrcs.filter(
        (mrc) => mrc !== action.payload
      );
    },
    clearMrc: (state, action) => {
      state.selectedMrcs = [];
    },
    setRegionsDisponibles: (state, action) => {
      state.regionsDisponibles = action.payload;
    },
  },
});

export const {
  selectFlyToRegion,
  addMrcToList,
  removeMrcFromList,
  clearMrcList,
  selectDonneePopup,
  selectChoropleth,
  setZoneAdministrative,
  setChoixDonnee,
  setPeriode,
  setSecteurActivite,
  setTypePoste,
  setLegendValues,
  selectMrc,
  removeMrc,
  clearMrc,
  setRegionsDisponibles,
} = mapStoreSlice.actions;

export default mapStoreSlice.reducer;
