/*****************************************************************/
/*************** THIS IS THE HEART OF THE PLATFORM! **************/
/*****************************************************************/

import { useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
//import History from "./scenes/history";
import Carte from "./scenes/carte";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import FilterBar from "./scenes/filter-bar/FilterBar";
import Login from "./scenes/login/Login";
import Signup from "./scenes/login/Signup";
import Dashboard from "./scenes/dashboard";
import EmailSent from "./scenes/login/EmailSent";
import Loading from "./scenes/login/Loading";
import {
  setEntrepriseId,
  setjobListings,
  setNettoyage,
  setInfoEntreprises,
  setTopCompetences,
  setLoadingProgress,
} from "./reducers/dataStore";
//import entreprise_salarie from "./data/dbBackup/entreprise_salarie.json";
//import entreprise_id from "./data/dbBackup/entreprise_id.json";
//import nettoyage from "./data/Nettoyage.json";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ForgottenPassword from "./scenes/login/ForgottenPassword";
import PasswordReset from "./scenes/login/PasswordReset";
import { selectFlyToRegion, setRegionsDisponibles } from "./reducers/mapStore";
import { useTheme } from "@mui/material";
import { tokens } from "./theme";
import mrcNamesList from "./constants/NomsDesMrcs";
import DirectRoute from "./scenes/login/DirectRoute";
import Tableau from "./scenes/tableau";
//import Landing from "./scenes/landing";
//import Home from "./scenes/login/Home";
import { HelmetProvider, Helmet } from 'react-helmet-async';

function App() {
  const [theme, colorMode] = useMode();
  const myTheme = useTheme();
  const colors = tokens(myTheme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(false);
  //const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isAppReady, setIsAppReady] = useState(false);
  const dispatch = useDispatch();
  const isDataFetched = useRef(false); // is true after one fetch to avoid double fetches
  const userIsReadyToFetch = useSelector((state) => state.dataStore.userIsReadyToFetch);


  //const loadingProgress = useSelector((state) => state.dataStore.loadingProgress);

  //const [isTryingToAccessRouteDirectly, setIsTryingToAccessRouteDirectly] = useState(true);

  // use pre-defined email for skipping login:

  //const userEmail = useSelector((state) => state.dataStore.userEmail);
  const userEmail = "nicolas.iglesias@civision.org"

  // also need to change <DirectRoute> for <Home> if you want login
  // also in dataStore.js, change userIsReadyToFetch to true if you want no login or false if you want login
  // also set the redirect to <Home> component for login, else <DirectRoute> component

  /*****************************************************************/
  // serverUrl is where the data collection happens
  /*****************************************************************/

  //const serverUrl = "https://blooming-badlands-08079.herokuapp.com/";
  //https://server-data.herokuapp.com/ // this is the old one

  //const serverUrl = 'http://localhost:3001/'
  const serverUrl = "https://dev-mauricie-server-349e7187b7d7.herokuapp.com/"

  const connectToDatabase = async () => {
    return axios.get(`${serverUrl}civision/connect`);
  };

  const fetchDataFromDb = async (collection, regions) => {
    regions = JSON.stringify(regions);
    //console.log(regions)
    return axios.get(
      `${serverUrl}civision/${collection}?regions=${regions}`
      //`${serverUrl}/civision/${collection}?regions=${regions}`
    );
  };

  const fetchRegionsDisponibles = async () => {
    return axios.get(
      `${serverUrl}civision/getRegions/${userEmail}`
      //`${serverUrl}/civision/getRegions/${userEmail}`
    );
  };

  const FiltrerPourLesRegionsDisponibles = (listeEntreprises, region) => {
    const MRCsDisponibles = mrcNamesList.filter(item => item.REG === region).map(item => item.MRC);
    const filteredList = listeEntreprises.filter(entreprise => MRCsDisponibles.includes(entreprise.MRC));
    return filteredList
  }

  /*************************************************************/
  // Each user has a 'region' attached to his/her account.
  // This useEffect fetches the data in the database matching the user's 'region'
  // Once all the data is fetched, the loading screen ends
  // and the user has access to the platform
  /*************************************************************/

  /*   // this is to keep client from navigating manually to a route
    const navigate = useNavigate();
    useEffect(() => {
      if (isTryingToAccessRouteDirectly) {
        navigate("/landing"); // redirect to "/" if you want login, "/landing" if you don't
      }
      setIsTryingToAccessRouteDirectly(false);
    }, [isTryingToAccessRouteDirectly, navigate]); */

  // this is to fetch the data upon login
  useEffect(() => {
    const fetchData = async () => {
      isDataFetched.current = true;
      try {
        await connectToDatabase(); // fetch la collection salarié
        const regions = await fetchRegionsDisponibles();
        const resNettoyage = await fetchDataFromDb("Nettoyage", ["all"]);
        dispatch(setNettoyage(resNettoyage.data));
        dispatch(setLoadingProgress(0))

        //dispatch(setNettoyage(nettoyage));
        dispatch(setRegionsDisponibles([regions.data.region]));
        const resEntrepriseSalarie = await fetchDataFromDb("entreprise_salarie", [regions.data.region]);
        dispatch(setjobListings(resEntrepriseSalarie.data));
        dispatch(setLoadingProgress(1))

        const resEntrepriseId = await fetchDataFromDb("entreprise_id", ["all"]);
        dispatch(setEntrepriseId(resEntrepriseId.data));
        dispatch(setLoadingProgress(2))
        
        const resInfoEntreprises = await fetchDataFromDb("liste_entreprises.liste_info_entreprises", [regions.data.region]);
        //const listeInfoEntreprisesPourMRC = FiltrerPourLesRegionsDisponibles(resInfoEntreprises.data, regions.data.region)
        dispatch(setInfoEntreprises(resInfoEntreprises.data));
        const topCompetences = await fetchDataFromDb("jobTopCompetences", [regions.data.region]);
        dispatch(setTopCompetences(topCompetences.data))
        dispatch(setLoadingProgress(3))
        console.log("data all fetched");
        dispatch(selectFlyToRegion(regions.data.region));
        setIsAppReady(true);
      } catch (error) {
        console.error(error);
      }
    };
    if (userIsReadyToFetch) {
      if (!isDataFetched.current) {
        fetchData();
        //dispatch(setjobListings(entreprise_salarie))
        //dispatch(setEntrepriseId(entreprise_id))
      }
    }
  }, [userIsReadyToFetch]);

  /*************************************************************/
  // Here is the basic layout. Until the app is ready, the loading screen is shown.
  // Once the data is loaded, the basic 'layout' of the platform is displayed.
  // The 'layout' contains the following three elements:
  // - sidebar, topbar, filterbar
  // All users have the same basic 'layout' regardless of region
  /*************************************************************/

  const Layout = ({ children }) => {
    return (
      <>
        <HelmetProvider>
          <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-1LR9WL1TGW"></script>
            <script>
              {`
                     window.dataLayer = window.dataLayer || [];
                     function gtag(){dataLayer.push(arguments);}
                     gtag('js', new Date());
                     gtag('config', 'G-1LR9WL1TGW');
                  `}
            </script>
          </Helmet>
          {!isAppReady ? (
            <Loading />
          ) : (
            <div
              className="app"
              style={{
                overflow: "hidden",
                height: "100%"
              }}
            >
              <div
                style={{
                  backgroundColor: `${colors.primary[500]}`,
                  height: "100vh",
                }}
              >
                <Sidebar isSidebar={isSidebar} />
              </div>
              <main
                className="content"
                style={{
                  height: "0vh", //does it break anything?
                  backgroundSize: "cover",
                  backgroundColor: `${colors.primary[500]}`,
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                  <div className="top-bar">
                    <Topbar setIsSidebar={setIsSidebar} />
                  </div>
                  <div className="filter-bar">
                    <FilterBar />
                  </div>
                  <div
                    style={{
                      flex: 1, // This will make the div take up all remaining space
                      display: 'flex',
                      flexDirection: 'column',
                      zIndex: "1",
                      overflow: "hidden",
                      backgroundColor: `${colors.primary[500]}`,
                    }}
                  >
                    {children}
                  </div>
                </div>

              </main>
            </div>
          )}
        </HelmetProvider>
      </>
    );
  };

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path="/gallerie"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
            {/* This is the historique */}
            {/*             <Route
              path="/historique"
              element={
                <Layout>
                  <History />
                </Layout>
              }
            /> */}
            {/* This is the tableau */}
            {/* This tableau needs to be updated with Elizabeth's newest changes */}
            <Route
              path="/tableau"
              element={
                <Layout>
                  <Tableau />
                </Layout>
              }
            />

            {/* This is the map */}
            <Route
              path="/carte"
              element={
                <Layout>
                  <Carte />
                </Layout>
              }
            />

            {/* The following components are all login stuff! */}
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/emailsent/:userEmail?/:reset?"
              element={<EmailSent />}
            />
            <Route
              path="/passwordreset/:userId/:resetString"
              element={<PasswordReset />}
            />
            <Route path="/login/:userEmail?" element={<Login />} />
            {/* comment this following line to remove the login */}
            <Route path="/" element={<DirectRoute />} /> {/* use Home for login, DirectRoute for no login */}
            <Route path="/landing" element={<DirectRoute />} /> {/* use Landing for login, DirectRoute for no login */}
            <Route path="/forgottenpassword" element={<ForgottenPassword />} />

            <Route path="*" element={<DirectRoute />} /> {/* so false routes redirect to mainpage */}
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

const mapStateToProps = ({ session }) => ({
  checked: session.checked,
});

export default connect()(App);
