import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers/rootReducer";

import { sessionService } from "redux-react-session";

const initialState = {};
const middlewares = [];

const loginStore = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middlewares))
);

sessionService.initSessionService(loginStore);

export default loginStore;
