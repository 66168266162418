//styled components
import {
  StyledTextInput,
  StyledFormArea,
  StyledFormButton,
  StyledLabel,
  StyledContainer,
  Avatar,
  StyledTitle,
  colors,
  ButtonGroup,
  ExtraText,
  TextLink,
  CopyrightText,
} from "../../Styles";

//Logo
import Logo from "./../../src-assets/civision-logo-copy.png";

//Formik
import { Formik, Form } from "formik";
import { TextInput } from "./FormLib";
import * as Yup from "yup";

//Icons
import { FiMail, FiLock } from "react-icons/fi";

//Loader
import { ThreeDots } from "react-loader-spinner";

// Auth & Redux
import { connect } from "react-redux";
import { forgottenPassword } from "../../auth/actions/userActions";
import { useNavigate, useParams } from "react-router-dom";

const ForgottenPass = ({ forgottenPassword }) => {
  const navigate = useNavigate();
  const { userEmail } = useParams();
  return (
    <StyledContainer>
      <div>
        <StyledFormArea>
          <Avatar image={Logo} />
          <StyledTitle color={colors.theme} size={30}>
            Password Reset
          </StyledTitle>
          <Formik
            initialValues={{
              email: userEmail,
              //should be the url where this is hosted
              redirectUrl: "http://localhost:3000/passwordreset",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
            })}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              forgottenPassword(values, navigate, setFieldError, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextInput
                  name="email"
                  type="text"
                  label="Enter your email address"
                  placeholder="nico1@example.com"
                  icon={<FiMail />}
                />

                <ButtonGroup>
                  {!isSubmitting && (
                    <StyledFormButton type="submit">Submit</StyledFormButton>
                  )}

                  {isSubmitting && (
                    <ThreeDots
                      type="ThreeDots"
                      color={colors.theme}
                      height={49}
                      width={100}
                    />
                  )}
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </StyledFormArea>
        <CopyrightText>All rights reserved &copy;2023</CopyrightText>
      </div>
    </StyledContainer>
  );
};

export default connect(null, { forgottenPassword })(ForgottenPass);
