import React from 'react'
import App from './App'
import "./index.css"
import { useSelector } from 'react-redux'

const AppWrapper = () => {
  const computerIsThinking = useSelector(state => state.dataStore.computerIsThinking)
  return (
    <div className={computerIsThinking ? 'loading' : ''}>
      <App />
    </div>
  )
}

export default AppWrapper