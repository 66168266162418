import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { couleursChoropleth } from "../../../constants/CodesCouleurs";
import { formatLargeDollarNumber } from "./UtilityFunctions";
import { useTheme } from "@mui/material"
import { tokens } from "../../../theme";

const Legende = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const legendValues = useSelector((state) => state.mapStore.legendValues);
  const [title, setTitle] = useState("");
  const [labels, setLabels] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [couleurs, setCouleurs] = useState(couleursChoropleth);
  // format: [titre, type, value0, value1, value2, value3]

  useEffect(() => {
    const type = legendValues[1];
    const inverted = legendValues[6];
    let contents;

    if (inverted)
      setCouleurs([
        couleursChoropleth[0],
        couleursChoropleth[4],
        couleursChoropleth[3],
        couleursChoropleth[2],
        couleursChoropleth[1],
      ]);
    else setCouleurs(couleursChoropleth);

    if (type === "%") {
      contents = [
        "Aucune donnée",
        Math.ceil(legendValues[2]?.toFixed(2) * Math.pow(10, 1)) /
          Math.pow(10, 1) +
          type +
          " à " +
          Math.ceil(legendValues[3]?.toFixed(2) * Math.pow(10, 1)) /
            Math.pow(10, 1) +
          type,
        Math.ceil(legendValues[3]?.toFixed(2) * Math.pow(10, 1)) /
          Math.pow(10, 1) +
          type +
          " à " +
          Math.ceil(legendValues[4]?.toFixed(2) * Math.pow(10, 1)) /
            Math.pow(10, 1) +
          type,
        Math.ceil(legendValues[4]?.toFixed(2) * Math.pow(10, 1)) /
          Math.pow(10, 1) +
          type +
          " et +", // il va falloir rajouter (if 100 {doSomething})
      ];
    } else if (type === "$") {
      /*contents = [
             "Non-disponible",
             (Math.round(legendValues[2]?.toFixed(2) * 4) / 4).toFixed(0) + type + ' à ' + (Math.round(legendValues[3]?.toFixed(2) * 4) / 4).toFixed(0) + type,
             (Math.round(legendValues[3]?.toFixed(2) * 4) / 4).toFixed(0) + type + ' à ' + (Math.round(legendValues[4]?.toFixed(2) * 4) / 4).toFixed(0) + type,
             (Math.round(legendValues[4]?.toFixed(2) * 4) / 4).toFixed(0) + type + ' et +'
           ] */
      contents = [
        "Aucune donnée",
        formatLargeDollarNumber(legendValues[2], 2) +
          " à " +
          formatLargeDollarNumber(legendValues[3], 2),
        formatLargeDollarNumber(legendValues[3], 2) +
          " à " +
          formatLargeDollarNumber(legendValues[4], 2),
        formatLargeDollarNumber(legendValues[4], 2) + " et +",
      ];
    } else if (type === "offres") {
      contents = [
        "Aucune donnée",
        legendValues[2] + " à " + legendValues[3] + " " + type,
        legendValues[3] + " à " + legendValues[4] + " " + type,
        legendValues[4] + " " + type + " et +",
      ];
    } else {
      contents = [null, null, null, null, null];
    }

    setTimeout(() => {
      setTitle(legendValues[0]);
      setLabels(contents);
    }, 400);
  }, [legendValues]);

  const labelWidth = "60%";
  const colorboxWidth = "40%";

  const legendCellStyle = {
    flexDirection: "row",
    alignItems: "center",
    padding: "5px 5px",
    fontSize: 12,
    fontWeight: "500",
    textAlign: "right",
    color: colors.primary[600]
  };

  const colorBoxStyle = {
    height: "20px",
    width: "80%",
    border: `0.5px solid ${colors.primary[600]}`,
    borderRadius: 2,
  };

  const generateGradient = (startColor, endColor) =>
    `linear-gradient(to right, ${startColor}, ${endColor})`;

  return (
    <table
      style={{
        width: "240px",
        marginRight: 0,
        padding: 10,
      }}
    >
      <thead>
        <tr>
          <th
            colSpan="2"
            style={{
              textAlign: "center",
              fontSize: 14,
              fontWeight: 600,
              paddingBottom: 12,
              paddingTop: 5,
              color: colors.primary[600],
            }}
          >
            {title}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ ...legendCellStyle, width: labelWidth }}>{labels[0]}</td>
          <td style={{ ...legendCellStyle, width: colorboxWidth }}>
            <div style={{ ...colorBoxStyle, background: colors.primary[400] === "#1F2A40" ? "rgb(100,100,125)" : null }}></div>
          </td>
        </tr>
        <tr>
          <td style={{ ...legendCellStyle, width: labelWidth }}>{labels[1]}</td>
          <td style={{ ...legendCellStyle, width: colorboxWidth }}>
            <div
              style={{
                ...colorBoxStyle,
                backgroundImage: generateGradient(couleurs[1], couleurs[2]),
              }}
            ></div>
          </td>
        </tr>
        <tr>
          <td style={{ ...legendCellStyle, width: labelWidth }}>{labels[2]}</td>
          <td style={{ ...legendCellStyle, width: colorboxWidth }}>
            <div
              style={{
                ...colorBoxStyle,
                backgroundImage: generateGradient(couleurs[2], couleurs[3]),
              }}
            ></div>
          </td>
        </tr>
        <tr>
          <td style={{ ...legendCellStyle, width: labelWidth }}>{labels[3]}</td>
          <td style={{ ...legendCellStyle, width: colorboxWidth }}>
            <div
              style={{
                ...colorBoxStyle,
                backgroundImage: generateGradient(couleurs[3], couleurs[4]),
              }}
            ></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Legende;
