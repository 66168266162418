import React from 'react';
import { useTheme } from '@mui/material/styles';
import { PieChart, Pie, Cell } from 'recharts';

const PieChartBox = ({ width, height, data }) => {

  const opacity = 0.8;

  const COLORS = [
    `rgba(0, 119, 194, ${opacity})`,
    `rgba(194, 162, 0, ${opacity})`,
    `rgba(0, 158, 115, ${opacity})`,
    `rgba(229, 69, 111, ${opacity})`,
    `rgba(160, 60, 120, ${opacity})`,
    `rgba(74, 154, 212, ${opacity})`,
    `rgba(255, 127, 0, ${opacity})`,
    `rgba(127, 127, 127, ${opacity})`
  ];


  // Filter data to exclude entries with a value of 0
  const filteredData = data.filter(entry => entry.value >= 0.05);
  const theme = useTheme();

  return (
    <>
      <PieChart width={width} height={height} fontSize='11' fontWeight='bold' style={{margin: "-15px 0"}}>
        <Pie
          data={filteredData}
          outerRadius={width / 5}
          fill={theme.palette.text.primary}
          dataKey="value"
          animationDuration={1000}
          animationBegin={0}
          label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value }) => {
            const RADIAN = Math.PI / 180;
            const isAbove = midAngle < 135 && midAngle > 45;
            const radius = (isAbove ? 30 : 60) + innerRadius + (outerRadius - innerRadius);
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            const words = name.split(' ');
            const lines = [];
            let line = '';

            for (let i = 0; i < words.length; i++) {
              const newLine = `${line} ${words[i]}`.trim();
              const newLineWidth = document.createElement('canvas').getContext('2d').measureText(newLine).width;

              if (newLineWidth > 90) {
                lines.push(line);
                line = words[i];
              } else {
                line = newLine;
              }
            }

            lines.push(line);

            return (
              <>
                {lines.map((line, i) => (
                  <text
                    key={`line-${i}`}
                    x={x}
                    y={y - 20 + i * 10}
                    fill={theme.palette.text.primary}
                    textAnchor="middle"
                    dominantBaseline="central"
                  >
                    {line}
                    {i === lines.length - 1 && line !== '' ? <tspan x={x} dy="1.5em">{value.toFixed(1)}%</tspan> : null}
                  </text>
                ))}
              </>
            );
          }}
        >
          {filteredData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </>
  );
}

export default PieChartBox;
