import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";

//authorization [login] stuff
import { Provider } from "react-redux";
import loginStore from "./auth/loginStore";
import appStore from "./stores/appStore";
import AppWrapper from "./AppWrapper";

const root = ReactDOM.createRoot(document.getElementById("root"));

// The '<App/>' is the CIVISION app found in the 'App.js' file
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={loginStore}>
        <Provider store={appStore}>
          <AppWrapper />
        </Provider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
