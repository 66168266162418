import { Button, Typography, useTheme, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { tokens } from "../../theme";
import "../../index.css";
import { useEffect, useState, useRef } from "react";
import BarChartBox from "../../graphs/BarChartBox";
import { useDispatch, useSelector } from "react-redux";
import {
  filterDataByDate,
  calculateDataOrderedByDate,
  cleanUpData,
  filterDataForChosenMrc,
  filterDataBySecteur,
  addPreviousToCurrent,
} from "../../data/Calculations";
import LineChartBox from "../../graphs/LineChartBox";
import IndicatorBox from "../../graphs/IndicatorBox";
import {
  nbTempsPleinParDateForLineChart,
  calculStabiliteEmploi,
  averageSalaryByDateForLineChart,
  moyenneSalaireIndicator,
  calculerPourcentageTypeEmploiPieChart,
  calculerPourcentageTypeHoraire,
  //calculerNbHoraireEnJourneeForLineChart,
  calculerIndiceExigenceEmployeur,
  classerNiveauEtudesRequis,
  calculerDureeEmploiForPieChart,
  calculerEvolutionEmploiPermanentLineChart,
  classerNiveauExperienceRequis,
  classerLangueDemandeePieChart,
  makeTopListAppellationsForBarChart,
  classerTopEmploisPlusPayant,
  calculTauxAvantagesSociauxPourIndicator,
  determinerTopAvantagesSociauxPourBarChart,
  calculerTop5CompetencesSoftSkills,
  calculerTop5CompetencesHardSkills,
  calculerTop5Qualifications,
  calculerTop5Experiences,
  calculerTop5Outils,
  filterOutShortStrings,
} from "./chartFunctions";
import PieChartBox from "../../graphs/PieChartBox";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import mrcNamesList from "../../constants/NomsDesMrcs";
import { addGraphsForPDF, setComputerIsThinking } from "../../reducers/dataStore";
import rasterizeHTML from "rasterizehtml";
import Plotly from 'plotly.js-dist-min';
import Selections from "./Selections";
import { Tooltip as Tippy } from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';




// dashboard === gallerie <------------- ||| /// --- \\\ ||| there's a lizard on the page
// note à moi-même : il aurait été mieux de process le data pour le nb de temps maximum et de
// choisir d'utiliser une partie du data au niveau des graphs plutôt que de fragmenter le data
// avant de les passer au graphs et aux fonctions de calcul pour simplifier l'obtention du data
// de la période précédente pour l'outil comparatif

const Dashboard = () => {
  // Dark mode and change-of-view buttons stuff
  const selectedPeriod = useSelector((state) => state.histoStore.histoPeriode);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  //const dispatch = useDispatch();
  const choixDonnee = useSelector((state) => state.mapStore.choixDonnee);
  const nettoyage = useSelector((state) => state.dataStore.nettoyage);
  const infoEntreprises = useSelector((state) => state.dataStore.infoEntreprises);
  const jobListings = useSelector((state) => state.dataStore.jobListings);
  const topCompetences = useSelector((state) => state.dataStore.topCompetences);
  const regionsDisponibles = useSelector((state) => state.mapStore.regionsDisponibles);
  const periode = useSelector((state) => state.mapStore.periode);
  const secteurActivite = useSelector((state) => state.mapStore.secteurActivite);
  const typePoste = useSelector((state) => state.mapStore.typePoste);
  const zoneAdministrative = useSelector((state) => state.mapStore.zoneAdministrative);
  const selectedMrcs = useSelector((state) => state.mapStore.selectedMrcs);
  const computerIsThinking = useSelector((state) => state.dataStore.computerIsThinking);
  const graphsForPDF = useSelector((state) => state.dataStore.graphsForPDF);

  //const [isSidebar, setIsSidebar] = useState(false);
  const [titre, setTitre] = useState(""); // set le titre de chaque vue
  //const [mrcsDisponibles, setMrcsDisponibles] = useState([]) // set MRCs disponibles dans les calculs
  const [graphs, setGraphs] = useState(); // set les graphiques de la vue

  const [jiggleGeneratePDF, setJiggleGeneratePDF] = useState(false);

  const boxWidth = 400;
  const boxHeight = (boxWidth * 7) / 8;
  const buttonAjoutPDF = "+PDF";
  const infoIconPath = "../../assets/info-icon.svg"
  //const graphBoxBorder = "0.5px solid black";

  // ******************************************************************
  // *************** STYLES CSS FORMAT JSX ****************************
  // ******************************************************************

  const titleStyle = {
    padding: "40px 0",
    fontSize: "200%",
    textAlign: "center",
    margin: "30px auto",
    borderRadius: "100%",
    marginTop: "0px",
    letterSpacing: "0.03em",
  };

  const subtitleStyle = {
    margin: "20px 60px",
    fontSize: "140%",
  };

  const subsubtitleStyle = {
    textAlign: "center",
    fontSize: 16,
  };

  const graphsContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: "0 50px",
  };

  const mrcButtonsStyle = {
    borderRadius: 50,
    padding: "2px 10px",
    margin: 0.5,
  };

  const tableContainerStyles = {
    maxWidth: "500px",
    overflowY: "auto",
    borderRadius: "8px",
    border: "2px solid #ccc",
    marginLeft: "30px",
    marginRight: "10px",
  };

  const graphBoxStyle = {
    //border: "1px solid #ccc",
    alignItems: 'center',
    height: "100%",
    margin: "0 10px"
  };

  const pdfButton = {
    position: "relative",
    borderRadius: "6px",
    marginLeft: 10,
    padding: 5,
    zIndex: 1000,
    animation: 'none',
  }

  const infoIconStyles = { width: 20, height: 20, marginRight: 10, top: 10 }

  const tippyProps = {
    theme: "light",
    trigger: "click",
    arrow: true,
    interactive: false,
    maxWidth: 10,
    size: "small",
    multiple: true
  };

  const tippyTextStyles = {
    width: 240
  }

  const flashAnimation = {
    animation: 'flashGreen 0.4s', // runs the animation for 600ms
  }

  // ******************************************************************
  // *************** USE EFFECTS / FONCTIONS **************************
  // ******************************************************************

  useEffect(() => {
    if (jiggleGeneratePDF) {
      const timeout = setTimeout(() => {
        setJiggleGeneratePDF(false);
      }, 500); // 500ms matches the jiggle animation duration

      return () => clearTimeout(timeout);
    }
  }, [jiggleGeneratePDF]);

  const getMRCsByRegion = (region) => {
    return mrcNamesList
      .filter(entry => entry.REG === region)
      .map(entry => entry.MRC);
  }

  function createToast(graphTitle) {
    toast(
      <div>
        <strong style={{ fontWeight: 300 }}>
          {graphTitle}
        </strong>
        <br />
        <span style={{ color: "green" }}>
          ajouté au PDF
        </span>
      </div>,
      {
        style: {
          textAlign: 'center',
          background: '#efeeef',
          fontSize: '14px',

        },
        position: "top-right"
      });
  }

  function ajouterAuPDF(event, chartType) {

    setJiggleGeneratePDF(true);
    const button = event.currentTarget;
    Object.assign(button.style, flashAnimation);

    setTimeout(() => {
      button.style.animation = 'none';
    }, 250);

    const parentDiv = event.currentTarget.parentElement.parentElement;

    if (chartType === 'indicator') {

      const plotlyDiv = parentDiv.querySelector('.js-plotly-plot');
      Plotly.toImage(plotlyDiv, { format: 'png' }).then((dataUrl) => {
        const graphTitle = parentDiv.querySelector('h3').textContent.replace(buttonAjoutPDF, '').trim();
        dispatch(addGraphsForPDF({
          png: dataUrl,
          chart: chartType,
          titre: graphTitle,
          periode: periode[0],
          comte: zoneAdministrative,
          secteurActivite: secteurActivite,
          typePoste: typePoste
        }));

        createToast(graphTitle);
      });

    } else {

      const graphTitleElement = parentDiv.querySelector('h3');
      const graphTitle = graphTitleElement.textContent.replace(buttonAjoutPDF, '').trim(); // Remove the "+PDF" text

      const originalHeight = parentDiv.offsetHeight;
      const originalWidth = parentDiv.offsetWidth;

      // Calculate total height of elements to remove
      const buttonHeight = parentDiv.querySelector('button')?.offsetHeight || 0;
      const titleHeight = parentDiv.querySelector('h3')?.offsetHeight || 0;
      const spacerHeight = parentDiv.querySelector('.spacer')?.offsetHeight || 0;
      const legendHeight = parentDiv.querySelector('.table-emplois-crees-legende')?.offsetHeight || 0;

      const totalRemovedHeight = buttonHeight + titleHeight + spacerHeight + legendHeight;

      // Final height
      const height = originalHeight - totalRemovedHeight;

      // Clone and remove elements as you are doing
      var htmlElement = parentDiv.cloneNode(true);
      htmlElement.querySelector('button')?.remove();
      htmlElement.querySelector('h3')?.remove()
      htmlElement.querySelector('.table-emplois-crees-legende')?.remove()

      // Apply height and width to the canvas
      var canvas = document.createElement('canvas');
      var scale = window.devicePixelRatio || 1;
      canvas.width = originalWidth * scale + 20; // Add any padding or margin here
      canvas.height = height * scale + 50; // Add any padding or margin here      

      htmlElement.innerHTML = `
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
        * {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 11px;
          font-weight: normal;
        }
      </style>
      ` + htmlElement.innerHTML;

      canvas.getContext('2d').scale(scale, scale);
      var html = htmlElement.outerHTML;

      rasterizeHTML.drawHTML(html, canvas).then(function (renderResult) {
        const imgData = canvas.toDataURL('image/png');
        dispatch(addGraphsForPDF({
          png: imgData,
          chart: chartType,
          titre: graphTitle,
          periode: periode[0],
          comte: chartType === "table" || chartType === "mrc-bar" ? "-" : zoneAdministrative,
          secteurActivite: secteurActivite,
          typePoste: typePoste
        }));
      });

      createToast(graphTitle);
    }
  }

  function generatePDF() {
    const pdf = new jsPDF();
    pdf.setFont("Helvetica");
    let yPosition = 30; // Start Y position for the images

    // Load the cover image first
    const coverImg = new Image();
    coverImg.src = '/assets/pagegarde-2.png'
    console.log(coverImg.src)

    coverImg.onload = () => {
      const pageWidth = pdf.internal.pageSize.getWidth();
      const coverAspectRatio = coverImg.width / coverImg.height;
      const coverWidth = pageWidth;
      const coverHeight = coverWidth / coverAspectRatio;
      pdf.addImage(coverImg, 'PNG', 0.1, -0.5, coverWidth * 1.01, coverHeight * 1.01);
      pdf.addPage()

      graphsForPDF.forEach((graph, index) => {
        const imgData = graph.png;
        const img = new Image();
        img.src = imgData;

        img.onload = () => {
          var chartSizeAdjustment = 1;
          if (graph.chart === 'pie') chartSizeAdjustment = 0.6;
          if (graph.chart === 'line') chartSizeAdjustment = 0.8;
          if (graph.chart === 'bar') chartSizeAdjustment = 0.9;
          if (graph.chart === 'indicator') chartSizeAdjustment = 0.6;
          if (graph.chart === 'table') chartSizeAdjustment = 0.75
          if (graph.chart === 'mrc-bar') chartSizeAdjustment = 0.85

          // Calculate the width and height, maintaining the aspect ratio
          const aspectRatio = img.width / img.height;
          const width = 180 * chartSizeAdjustment; // Adjust this to fit within the A4 page width
          const height = width / aspectRatio;

          // Check if the image will go beyond the page, add a new page if needed
          if (yPosition + height > 295) { // 275 is approximate end of A4 page in points
            pdf.addPage();
            yPosition = 30;
          }

          const pageWidth = pdf.internal.pageSize.getWidth(); // Get the width of the page
          const x = (pageWidth - width) / 2; // Centering the x coordinate
          const maxLineWidth = 100
          const typePosteLines = pdf.splitTextToSize(`Type de poste: ${graph.typePoste}`, maxLineWidth);

          const title = graph.titre;
          pdf.setFontSize(16)
          const titleWidth = pdf.getTextWidth(title);
          const titleX = (pageWidth - titleWidth) / 2;
          pdf.text(title, titleX, yPosition);

          pdf.setFontSize(9).setFont(undefined, "normal")
          const leftX = 25; // Left alignment X position
          const rightX = pageWidth - 25; // Right alignment X position 

          yPosition += 16; // Increment yPosition for the subsequent lines
          pdf.text(`Période: Les ${graph.periode}`, leftX, yPosition);
          pdf.text(`Secteur: ${graph.secteurActivite}`, rightX, yPosition, { align: 'right' }); // Right-aligned sector

          yPosition += 4; // Increment yPosition for the next line
          pdf.text(`Comté: ${graph.comte}`, leftX, yPosition);
          //pdf.text(`Type de poste: ${graph.typePoste}`, rightX, yPosition, { align: 'right' }); // Right-aligned post type
          typePosteLines.forEach((line, i) => { pdf.text(line, rightX, yPosition + (i * 4), { align: 'right' }); });

          pdf.addImage(imgData, 'JPG', x, yPosition, width, height, undefined, 'FAST'); // Using the centered x coordinate
          yPosition += height + 20

          if (index === graphsForPDF.length - 1) {
            pdf.save("report.pdf");
          }
        };
      });
    }
  }


  const mrcsDisponibles = [];

  // calcul pour sortir les mrcs selon la région du user et populer la liste du dropdown
  // --------------------------------------------
  regionsDisponibles.forEach((region) => {
    const listOfMrcs = mrcNamesList.filter((mrc) => mrc.REG === region);
    listOfMrcs.forEach((mrc) => mrcsDisponibles.push(mrc.MRC));
  }); // et ensuite en ordre alphabétique:
  mrcsDisponibles.sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
  mrcsDisponibles.unshift(`Toute la région`);

  useEffect(() => {
    // ce useEffect permet de filtrer selon les choix du user
    //const mrcDisponibles = mrcNamesList.filter(mrc => mrc.REG === zoneGeographique);
    //setMrcsDisponibles(mrcDisponibles.map(mrc => mrc.MRC)) // set les noms des bouttons MRCs
    dispatch(setComputerIsThinking(true));
    const timeoutId = setTimeout(() => {
      if (jobListings) {
        try {
          const dataForRegion = jobListings[regionsDisponibles[0]]; // filtre pour la région
          const dataForMrc = filterDataForChosenMrc(
            dataForRegion,
            zoneAdministrative
          );
          const dataForPeriod = filterDataByDate(dataForMrc, periode[1]); // filtre pour la date
          const dataForSecteur = filterDataBySecteur(
            dataForPeriod.current,
            secteurActivite,
            typePoste
          );
          const dataForSecteurPrevious = filterDataBySecteur(
            dataForPeriod.previous,
            secteurActivite,
            typePoste
          );

          //const dataForMrcs = filterDataForSelectedMrcs(dataForPeriod, selectedMrcs) // filtre pour les MRCs

          const cleanedUpData = cleanUpData(dataForSecteur); // pour nettoyager les anomalies
          const cleanedUpDataPrevious = cleanUpData(dataForSecteurPrevious);

          // même filtrage mais pour les compétences
          const competencesForMrc = filterDataForChosenMrc(
            topCompetences,
            zoneAdministrative
          );

          const competencesForSecteur = filterDataBySecteur(
            competencesForMrc,
            secteurActivite,
            typePoste
          );

          const competencesFiltrees = competencesForSecteur

          calculateGraphData(cleanedUpData, cleanedUpDataPrevious, competencesFiltrees);
        } catch {
          console.log("error while calculating the data");
        }
      }

      dispatch(setComputerIsThinking(false));
    }, 350);

    return () => clearTimeout(timeoutId);
  }, [
    choixDonnee,
    periode,
    zoneAdministrative,
    selectedMrcs,
    secteurActivite,
    typePoste,
  ]);

  /*   const handleMrcClick = (mrc) => {
      if (selectedMrcs.includes(mrc)) {
        dispatch(removeMrc(mrc))
      } else {
        dispatch(selectMrc(mrc))
      }
    }; */

  //-----------------------------------------------------------------------------------
  //
  //                                                   )/_
  //                                         _.--..---"-,--c_
  //                                    \L..'           ._O__)_
  //                            ,-.     _.+  _  \..--( /           a:f
  //                              `\.-''__.-' \ (     \_
  //                                `'''       `\__   /\
  //                                            ')
  //

  const calculateGraphData = (data, previousData, topCompetences) => {
    // data par date:
    const dataOrderedByDate = calculateDataOrderedByDate(
      data,
      "publiee_depuis_le"
    );
    const dataOrderedByDataPrevious = calculateDataOrderedByDate(
      previousData,
      "publiee_depuis_le"
    );

    // switch correspondant à la catégorie de données sélectionnée dans le sidebar menu
    switch (choixDonnee) {
      // ******************************************************************
      // *************** CATÉGORIE STABILITÉ DE L'EMPLOI ******************
      // ******************************************************************

      case "Stabilité de l'emploi":
        {
          const nbTempsPleinParDateLineChartDataCurrent =
            nbTempsPleinParDateForLineChart(dataOrderedByDate);
          const nbTempsPleinParDateLineChartDataPrevious =
            nbTempsPleinParDateForLineChart(dataOrderedByDataPrevious);
          const nbTempsPleinParDateLineChartData = addPreviousToCurrent(
            nbTempsPleinParDateLineChartDataPrevious,
            nbTempsPleinParDateLineChartDataCurrent,
            periode[1]
          );

          const nbEmploiPermanentLineChartCurrent =
            calculerEvolutionEmploiPermanentLineChart(dataOrderedByDate);
          const nbEmploiPermanentLineChartPrevious =
            calculerEvolutionEmploiPermanentLineChart(
              dataOrderedByDataPrevious
            );
          const nbEmploiPermanentLineChart = addPreviousToCurrent(
            nbEmploiPermanentLineChartPrevious,
            nbEmploiPermanentLineChartCurrent,
            periode[1]
          );

          const tauxStabiliteIndicatorData = calculStabiliteEmploi(data);
          const tauxStabiliteIndicatorPrevious =
            calculStabiliteEmploi(previousData);

          const topAppellationsBarChartData =
            makeTopListAppellationsForBarChart(data);
          const typeEmploiPieChartData =
            calculerPourcentageTypeEmploiPieChart(data);
          const typeHorairePieChartData = calculerPourcentageTypeHoraire(data);
          //const nbPosteHoraireJourLineChartData = calculerNbHoraireEnJourneeForLineChart(dataOrderedByDate);
          const tauxDureeEmploiPourPieChart =
            calculerDureeEmploiForPieChart(data);

          setTitre("Stabilité de l'emploi");
          setGraphs(
            <>
              <div key="graphs" style={graphsContainerStyle}>
                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    <Tippy
                      {...tippyProps}
                      html={
                        <div style={tippyTextStyles}>
                          L'indice de stabilité d'emploi est calculé en fonction du type, de
                          la durée et de l'horaire offerts et est ensuite comparé à une même
                          période précédente.
                        </div>}
                    >
                      <img src={infoIconPath} alt="info icon" style={infoIconStyles} />
                    </Tippy>
                    Indice de stabilité de l'emploi
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'indicator')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <IndicatorBox
                    width={boxWidth}
                    height={boxHeight}
                    value={tauxStabiliteIndicatorData.toFixed(1)}
                    previous={tauxStabiliteIndicatorPrevious.toFixed(1)}
                    suffix={"%"}
                    precision={1}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Horaires des offres d'emplois
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'pie')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <PieChartBox
                    data={typeHorairePieChartData}
                    width={boxWidth}
                    height={boxHeight}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Types d'emplois demandés
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'pie')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <PieChartBox
                    data={typeEmploiPieChartData}
                    width={boxWidth}
                    height={boxHeight}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Évolution de l'offre d'emplois à temps plein
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'line')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <LineChartBox
                    data={nbTempsPleinParDateLineChartData}
                    width={boxWidth}
                    height={boxHeight}
                    typeDonnee=" postes"
                    periode={periode}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Évolution du nombre de postes permanents
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'line')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <LineChartBox
                    data={nbEmploiPermanentLineChart}
                    width={boxWidth}
                    height={boxHeight}
                    typeDonnee=" postes"
                    periode={periode}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Aperçu de la durée des emplois
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'pie')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <PieChartBox
                    data={tauxDureeEmploiPourPieChart}
                    width={boxWidth}
                    height={boxHeight}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <br />
                  <h3 style={subsubtitleStyle}>
                    Les postes les plus en demande:
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'bar')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <br />
                  <BarChartBox
                    width={boxWidth * 2.1}
                    height={boxHeight}
                    data={topAppellationsBarChartData.slice(0, 8)}
                    orientation="vertical"
                  />
                  <br />
                </div>
              </div>
            </>
          );
        }
        break;

      // ******************************************************************
      // *************** CATÉGORIE EXIGENCES DE L'EMPLOYEUR ***************
      // ******************************************************************

      case "Exigences de l'employeur":
        {
          const tauxExigenceEmployeurIndicator =
            calculerIndiceExigenceEmployeur(data);
          const tauxExigenceEmployeurIndicatorPrevious =
            calculerIndiceExigenceEmployeur(previousData);
          const niveauFormationRequisPieData = classerNiveauEtudesRequis(data);
          const niveauExperienceRequisPieData =
            classerNiveauExperienceRequis(data);
          const nbLanguesRequises = classerLangueDemandeePieChart(data);
          topCompetences = filterOutShortStrings(topCompetences, 5);
          const top5CompetencesSoftSkills = calculerTop5CompetencesSoftSkills(topCompetences);
          const top5CompetencesHardSkills = calculerTop5CompetencesHardSkills(topCompetences);
          const top5Qualifications = calculerTop5Qualifications(topCompetences);
          const top5Experiences = calculerTop5Experiences(topCompetences);
          //const top5Outils = calculerTop5Outils(topCompetences);
          /* 
                    console.log(top5CompetencesSoftSkills)
                    console.log(top5CompetencesHardSkills)
                    console.log(top5Qualifications)
                    console.log(top5Experiences)
                    console.log(top5Outils) */

          setTitre("Exigences de l'employeur");
          setGraphs(
            <>
              <div key="graphs" style={graphsContainerStyle}>
                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    <Tippy
                      {...tippyProps}
                      html={
                        <div style={tippyTextStyles}>
                          L'indice d'exigence de l'employeur est calculé en fonction du niveau de
                          formation et de l'expérience demandés et est ensuite comparé à une même
                          période précédente. Un score élevé signifie des exigences plus élevées.
                        </div>}
                    >
                      <img src={infoIconPath} alt="info icon" style={infoIconStyles} />
                    </Tippy>
                    Indice d'exigence de l'employeur
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'indicator')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <IndicatorBox
                    width={boxWidth}
                    height={boxHeight}
                    value={tauxExigenceEmployeurIndicator.toFixed(1)}
                    previous={tauxExigenceEmployeurIndicatorPrevious.toFixed(1)}
                    reverse={true}
                    suffix={"%"}
                    precision={1}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Le français en milieu professionnel
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'pie')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <PieChartBox
                    data={nbLanguesRequises}
                    width={boxWidth}
                    height={boxHeight}
                  />
                </div>


                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Les qualifications les plus recherchées
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'bar')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <br />
                  <BarChartBox
                    width={boxWidth * 2.1}
                    height={boxHeight}
                    data={top5Qualifications.slice(0, 5)}
                    orientation="vertical"
                  />
                  <br />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Niveau de formation demandé
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'pie')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <PieChartBox
                    data={niveauFormationRequisPieData}
                    width={boxWidth}
                    height={boxHeight}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Niveau d'expérience demandé{" "}
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'pie')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <PieChartBox
                    data={niveauExperienceRequisPieData}
                    width={boxWidth}
                    height={boxHeight}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Les expériences les plus recherchées
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'bar')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <br />
                  <BarChartBox
                    width={boxWidth * 2.1}
                    height={boxHeight}
                    data={top5Experiences.slice(0, 5)}
                    orientation="vertical"
                  />
                  <br />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Les compétences techniques les plus recherchées
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'bar')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <br />
                  <BarChartBox
                    width={boxWidth * 2.1}
                    height={boxHeight}
                    data={top5CompetencesHardSkills.slice(0, 5)}
                    orientation="vertical"
                  />
                  <br />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Les compétences relationnelles les plus recherchées
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'bar')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <br />
                  <BarChartBox
                    width={boxWidth * 2.1}
                    height={boxHeight}
                    data={top5CompetencesSoftSkills.slice(0, 5)}
                    orientation="vertical"
                  />
                  <br />
                </div>

              </div>
            </>
          );
        }
        break;

      // ******************************************************************
      // *************** CATÉGORIE AVANTAGES SOCIAUX **********************
      // ******************************************************************

      case "Avantages sociaux":
        {
          const tauxEntreprisesOffrantAvantagesIndicator =
            calculTauxAvantagesSociauxPourIndicator(data);
          const tauxEntreprisesOffrantAvantagesIndicatorPrevious =
            calculTauxAvantagesSociauxPourIndicator(previousData);
          const topAvantagesSociauxPourBarChart =
            determinerTopAvantagesSociauxPourBarChart(data);

          setTitre("Avantages sociaux");
          setGraphs(
            <>
              <div key="graphs" style={graphsContainerStyle}>
                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    <Tippy
                      {...tippyProps}
                      html={
                        <div style={tippyTextStyles}>
                          Ce taux prend en compte les entreprises offrant au moins un avantage
                          social publié dans une offre d'emploi, il est ensuite comparé au taux
                          pour une même période précédente.
                        </div>}
                    >
                      <img src={infoIconPath} alt="info icon" style={infoIconStyles} />
                    </Tippy>
                    Taux d'entreprises offrant des avantages sociaux
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'indicator')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <IndicatorBox
                    width={boxWidth}
                    height={boxHeight}
                    value={tauxEntreprisesOffrantAvantagesIndicator.toFixed(1)}
                    previous={tauxEntreprisesOffrantAvantagesIndicatorPrevious.toFixed(1)}
                    reverse={false}
                    suffix={"%"}
                    precision={1}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Les avantages sociaux les plus courants
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'bar')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <br />
                  <BarChartBox
                    width={boxWidth * 2.1}
                    height={boxHeight}
                    data={topAvantagesSociauxPourBarChart.slice(0, 5)}
                    orientation="vertical"
                  />
                  < br />
                </div>
              </div>
            </>
          );
        }
        break;

      // ******************************************************************
      // ****************** CATÉGORIE SALAIRES ****************************
      // ******************************************************************

      case "Salaires":
        {
          const salaireMoyenIndicator = moyenneSalaireIndicator(data);
          const salaireMoyenIndicatorPrevious =
            moyenneSalaireIndicator(previousData);
          const topEmploisPlusPayant = classerTopEmploisPlusPayant(data);

          const salaireMoyenParDateCurrent =
            averageSalaryByDateForLineChart(dataOrderedByDate);
          const salaireMoyenParDatePrevious = averageSalaryByDateForLineChart(
            dataOrderedByDataPrevious
          );
          const salaireMoyenParDate = addPreviousToCurrent(
            salaireMoyenParDatePrevious,
            salaireMoyenParDateCurrent,
            periode[1]
          );

          setTitre("Données sur les salaires");
          setGraphs(
            <>
              <div key="graphs" style={graphsContainerStyle}>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    <Tippy
                      {...tippyProps}
                      html={
                        <div style={tippyTextStyles}>
                          L'indice de l'offre salariale moyenne prend en compte le salaire de départ
                          offert par les entreprises pour une période donnée, il est ensuite comparé
                          à la moyenne pour une même période précédente.
                        </div>}
                    >
                      <img src={infoIconPath} alt="info icon" style={infoIconStyles} />
                    </Tippy>
                    Indice de l'offre salariale moyenne
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'indicator')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <IndicatorBox
                    width={boxWidth}
                    height={boxHeight}
                    value={salaireMoyenIndicator.toFixed(2)}
                    previous={salaireMoyenIndicatorPrevious.toFixed(2)}
                    reverse={false}
                    suffix={"$"}
                    precision={2}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <h3 style={subsubtitleStyle}>
                    Évolution du salaire d'embauche moyen
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'line')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <LineChartBox
                    data={salaireMoyenParDate}
                    width={boxWidth}
                    height={boxHeight}
                    typeDonnee="$"
                    periode={periode}
                  />
                </div>

                <div style={graphBoxStyle}>
                  <br />
                  <h3 style={subsubtitleStyle}>
                    Offres d'emplois les plus payantes
                    <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'bar')}>
                      {buttonAjoutPDF}
                    </button>
                  </h3>
                  <br />
                  <BarChartBox
                    width={boxWidth * 2}
                    height={boxHeight}
                    data={topEmploisPlusPayant.slice(0, 5)}
                    orientation="vertical"
                  />
                  <br />
                </div>
              </div>
            </>
          );
        }
        break;

      // ******************************************************************
      // ****************** TAB DE SÉLECTIONS PDF *************************
      // ******************************************************************

      case "Sélections PDF":
        {
          return (
            setTitre("Sélections PDF"),
            setGraphs(<Selections />)
          )
        }
        break;

      // ******************************************************************
      // ****************** PAR DÉFAUT ************************************
      // ************** VUE-GLOBALE DATA **********************************
      // ******************************************************************

      default:
        const tempsDeProjectionPourCalculImpactPenurie = ["12 derniers mois"]
        const motsClesLegende = ["Faible", "", "Moyen", "", "Fort", ""]

        {
          const filtrerInfoEntreprisesPourPeriode = (infoEntreprises, periode) => {
            return infoEntreprises.filter(item => item.periode === periode[0]);
          }

          function filterForSecteur(infoEntreprises, secteurActivite) {
            if (secteurActivite === undefined || secteurActivite === "Tous les secteurs") return infoEntreprises
            else return infoEntreprises.filter(item => secteurActivite.includes(item.secteur_activite));
          }

          function filterForTypePoste(infoEntreprises, typePoste) {
            if (typePoste === undefined || typePoste === "Tous les types") return infoEntreprises
            else return infoEntreprises.filter(item => typePoste.includes(item.sous_secteur));
          }

          function copyArrayObject(infoEntreprises) {
            let newInfoEntreprises = infoEntreprises.map(info => { return { ...info }; });
            return newInfoEntreprises;
          }

          const tranformerInfoEntreprisesEnMoyenneOccurrence = (infoEntreprisesFiltrees, infoEntreprisesProjeteeSur12Mois) => {

            const infoEntreprises = infoEntreprisesFiltrees.map(item => {
              infoEntreprisesProjeteeSur12Mois.forEach(listing => {
                if (item.appelation === listing.appelation && item.employeur === listing.employeur) {
                  item.occurrence = listing.occurrence;
                }
              })
              return item;
            })

            let MRCs = infoEntreprises.reduce((acc, jobListing) => {
              if (!acc[jobListing.MRC]) {
                acc[jobListing.MRC] = { MRC: jobListing.MRC, occurrence: 0, count: 0, weight: 0 };
              }

              //var occ = 0
              //if (jobListing.occurrence > 2) occ = 2 
              //else occ = jobListing.occurrence;

              // Multiply the occurrence by the weight and add to the total
              acc[jobListing.MRC].occurrence += jobListing.occurrence * jobListing.emploi_cree;

              // Add the weight to the count and to the weight
              acc[jobListing.MRC].count += jobListing.emploi_cree;
              acc[jobListing.MRC].weight += jobListing.emploi_cree;

              return acc;
            }, {});

            let result = Object.values(MRCs).map(({ MRC, occurrence, count, weight }) => {
              return { MRC, occurrence: occurrence / count, weight };
            });

            return result;
          }

          const calculerInvestissementParMrc = (infoEntreprises) => {
            const listeMRCdisponible = getMRCsByRegion(regionsDisponibles[0]);

            let MRCs = infoEntreprises.reduce((acc, jobListing) => {
              if (!acc[jobListing.MRC]) {
                acc[jobListing.MRC] = { name: jobListing.MRC, Investissements: 0 };
              }

              acc[jobListing.MRC].Investissements += jobListing.investissement * jobListing.emploi_cree;

              return acc;
            }, {});

            // Ensure all MRCs from listeMRCdisponible are present in the MRCs object
            listeMRCdisponible.forEach(mrc => {
              if (!MRCs[mrc]) {
                MRCs[mrc] = { name: mrc, Investissements: 0 };
              }
            });

            let result = Object.values(MRCs)
              .map(({ name, Investissements }) => {
                return { name, Investissements };
              })
              .sort((a, b) => b.name.localeCompare(a.name));

            return result;
          }


          const infoEntreprisesPourPeriode = filtrerInfoEntreprisesPourPeriode(infoEntreprises, periode)
          const infoEntreprisesFiltreesPourSecteur = filterForSecteur(infoEntreprisesPourPeriode, secteurActivite)
          const infoEntreprisesFiltreesPourType = filterForTypePoste(infoEntreprisesFiltreesPourSecteur, typePoste)

          const infoEntreprisesFiltreesCopy = copyArrayObject(infoEntreprisesFiltreesPourType, nettoyage)

          const investissementTotalParMrc = calculerInvestissementParMrc(infoEntreprisesFiltreesCopy)
          const moyennesOccurrencesInfoEntreprises = tranformerInfoEntreprisesEnMoyenneOccurrence(infoEntreprisesFiltreesCopy, filtrerInfoEntreprisesPourPeriode(infoEntreprises, tempsDeProjectionPourCalculImpactPenurie))

          // ******************************************************************
          // ************** NOMBRE DE POSTES CALCULATIONS *********************
          // ******************************************************************

          const addTotalForTouteLaRegion = (moyennesOccurrencesInfoEntreprises) => {
            const total = moyennesOccurrencesInfoEntreprises.reduce((acc, mrc) => {
              acc.occurrence += mrc.occurrence * mrc.weight;
              acc.weight += mrc.weight;
              return acc;
            }, { MRC: 'Toute la région', occurrence: 0, weight: 0 });

            total.occurrence /= total.weight;

            return [total, ...moyennesOccurrencesInfoEntreprises];
          }

          const moyennesOccurrencesInfoEntreprisesWithTotal = addTotalForTouteLaRegion(moyennesOccurrencesInfoEntreprises);
          const penurieColorCodes = ['rgba(49,162,97,1)', 'rgba(49,162,97,1)', 'rgba(160, 200, 0,1)', 'rgba(235, 195, 0,1)', 'rgba(215, 145, 0,1)', 'rgba(197,97,60,1)']

          const tableRows = mrcsDisponibles.map((mrc, index) => {
            let matchingObject = moyennesOccurrencesInfoEntreprisesWithTotal.find(obj => obj.MRC === mrc);

            // If there's no matching object, create a default one with 0 values
            if (!matchingObject) {
              matchingObject = {
                MRC: mrc,
                occurrence: 0,
                weight: 0
              };
            }

            const occurrence = matchingObject.occurrence; // this can't be 'N/A' anymore since you're handling the default value
            const totalEmplois = matchingObject.weight; // likewise for this

            const tableCellStyles = {
              textAlign: "center",
              verticalAlign: "middle",
              margin: 0,
              padding: 5,
              height: 7
            }

            return (
              <TableRow key={mrc} style={{ backgroundColor: index % 2 === 1 && colors.primary[400] }}>
                <TableCell
                  style={tableCellStyles}
                >
                  {mrc}
                </TableCell>
                <TableCell
                  style={tableCellStyles}
                >
                  {totalEmplois.toFixed(0) > 0 ? totalEmplois.toFixed(0) : "-"}
                </TableCell>
                <TableCell
                  style={tableCellStyles}
                >
                  {/* code couleurs pour l'impact de la pénurie de main d'oeuvre en background du % */}
                  <div style={{
                    backgroundColor:
                      totalEmplois.toFixed(0) <= 0 ? "" :
                        occurrence < 0.25 ? penurieColorCodes[1] :
                          occurrence < 0.5 ? penurieColorCodes[2] :
                            occurrence < 1 ? penurieColorCodes[3] :
                              occurrence < 1.5 ? penurieColorCodes[4] :
                                penurieColorCodes[5],
                    width: "40%",
                    padding: 3,
                    color: "white",
                    borderRadius: "10px",
                    margin: "0px auto",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    {totalEmplois.toFixed(0) <= 0 ? "-" :
                      <strong>
                        {occurrence < 0.5 ? "Faible" :
                          occurrence < 1 ? "Moyen" :
                            "Fort"}
                      </strong>
                    }
                  </div>
                </TableCell>
              </TableRow>
            );
          });

          // ******************************************************************
          // ********ACTUAL MISE EN PLACE FOR VUE GLOBALE *********************
          // ******************************************************************

          setTitre("Vue globale");
          setGraphs(
            <div key="graphs" style={graphsContainerStyle}>

              <div style={graphBoxStyle}>
                <h3 style={subsubtitleStyle}>
                  <Tippy
                    {...tippyProps}
                    html={
                      <div style={tippyTextStyles}>
                        Le nombre d'emplois créés est calculé en fonction du total des postes
                        à combler pour les entreprises de chaque zone administrative pour une
                        période donnée.
                      </div>}
                  >
                    <img src={infoIconPath} alt="info icon" style={infoIconStyles} />
                  </Tippy>
                  Emplois créés par MRC
                  <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'table')}>
                    {buttonAjoutPDF}
                  </button>
                </h3>
                <br />
                <TableContainer sx={tableContainerStyles}>
                  <Table style={{ border: "#064c6d" }}>
                    <TableHead>
                      <TableRow style={{ backgroundColor: colors.primary[400] }}>
                        <TableCell style={{ textAlign: "center", width: "35%" }}>
                          <Typography variant="h5" fontWeight="bold">
                            MRC
                          </Typography>
                        </TableCell>
                        <TableCell style={{ textAlign: "center", width: "30%" }}>
                          <Typography variant="h5" fontWeight="bold">
                            Nombre d'emplois créés
                          </Typography>
                        </TableCell>
                        <TableCell style={{ textAlign: "center", width: "35%", padding: "0 5px" }}>
                          <Typography variant="h5" fontWeight="bold">
                            Impact de la pénurie de main d'oeuvre
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>{tableRows}</TableBody>
                  </Table>
                </TableContainer>
                <br />


                <div style={{ margin: 'auto', width: '80%' }} className='table-emplois-crees-legende'>
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 3 }}>
                    Légende : &nbsp;&nbsp;
                    {penurieColorCodes.slice(1, 6).map((color, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          backgroundColor: color,
                          borderRadius: '8px',
                          width: '15%',
                          height: '20px',
                          margin: 'auto',
                          fontWeight: 'bold',
                          fontSize: '10px',
                        }}
                      >
                        {motsClesLegende[index]}
                      </div>
                    ))}
                  </div>
                  <div className="spacer" style={{ height: 50 }} />
                </div>
              </div>

              <div style={graphBoxStyle}>
                <h3 style={subsubtitleStyle}>
                  <Tippy
                    {...tippyProps}
                    html={
                      <div style={tippyTextStyles}>
                        L'investissement total est calculé en fonction de la projection
                        des salaires versés par les entreprises lors de l'embauche pour
                        une période donnée.
                      </div>}
                  >
                    <img src={infoIconPath} alt="info icon" style={infoIconStyles} />
                  </Tippy>
                  Investissements par MRC
                  <button style={pdfButton} onClick={(e) => ajouterAuPDF(e, 'mrc-bar')}>
                    {buttonAjoutPDF}
                  </button>
                </h3>
                <br />
                <br />
                <BarChart width={700} height={400} data={investissementTotalParMrc} margin={{ right: 20, left: 10 }}>
                  <CartesianGrid width={700} strokeDasharray="2 2" />
                  <XAxis
                    interval={0}
                    dataKey="name"
                    height={60}
                    textAnchor="middle"
                    tick={props => (
                      <g transform={`translate(${props.x},${props.y + (props.index % 2) * 20})`}>
                        <line
                          x1={0}
                          y1={0}
                          x2={0}
                          y2={-30}
                          stroke="#666"
                        />
                        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                          {props.payload.value}
                        </text>
                      </g>
                    )}
                  />
                  <YAxis
                    width={90}
                    tick={{ fontSize: 10 }}
                    tickFormatter={(value) =>
                      new Intl.NumberFormat("fr-CA", {
                        style: "currency",
                        currency: "CAD",
                      }).format(value)
                    }
                  />

                  <Tooltip
                    formatter={(value) =>
                      new Intl.NumberFormat("fr-CA", {
                        style: "currency",
                        currency: "CAD",
                      }).format(value)
                    }
                  />
                  <Legend />
                  <Bar
                    dataKey="Investissements"
                    fill="#064c6d"
                    barSize={40}
                    name="Investissements par MRC ($)"
                  />
                </BarChart>
              </div>
            </div >
          );
        }
        break;
    }
  };

  // ******************************************************************
  // ******************** STUFF FOR PRINTING **************************
  // ******************************************************************
  const printRef = useRef(null);

  return (
    <div
      style={{
        position: "relative",
        filter: computerIsThinking ? 'blur(1px) grayscale(50%)' : 'blur(0px) grayscale(0%)',
        transition: 'filter 0.35s ease',
        pointerEvents: computerIsThinking ? 'none' : 'auto',
      }}
    >
      <Button
        onClick={() => generatePDF()}
        className="pdfButton"
        sx={{
          textTransform: "none",
          backgroundColor: `${colors.primary[400]}`,
          "&:hover": {
            backgroundColor: "#CCDB77",
          },
        }}
        style={{
          position: "absolute",
          right: "30px",
          marginBottom: "2px",
          marginTop: "5px",
          zIndex: 4000,
          animation: jiggleGeneratePDF ? 'jiggle 0.5s, flashGreen 0.3s' : 'none'
        }}
      >
        <Typography variant="h6" style={{ color: `${colors.primary[600]}` }}>
          Télécharger un rapport
        </Typography>

        <PictureAsPdfIcon
          sx={{
            ml: "5px",
            fontSize: "25px",
            color: `${colors.primary[600]}`,
          }}
        />
      </Button>

      <div
        className="print-wrapper"
        style={{ maxHeight: "80vh", overflowY: "auto" }}
      >
        <div
          style={{
            backgroundColor: `${colors.primary[500]}`,
          }}
          ref={printRef}
        >
          {/* ------------------------- boutons ------------------------------ */}

          {/*       <div style={{ padding: "10px 25px", marginTop: 5 }}>
        {mrcsDisponibles.map((mrc, index) => (
          <Button
            key={index}
            onClick={() => handleMrcClick(mrc)}
            sx={{
              ...mrcButtonsStyle,
              backgroundColor: selectedMrcs.includes(mrc) ? 'rgb(215,215,215)' : 'rgb(204, 219, 119)',
              "&:hover": { backgroundColor: selectedMrcs.includes(mrc) ? 'rgb(215,215,215)' : 'rgb(204, 219, 119)', },
              textTransform: 'none',
            }}>
            {mrc}
          </Button>
        ))}
      </div> */}

          {/* -------------------------- graphs ------------------------------ */}
          <Toaster
            containerStyle={{
              top: 50,
              right: 25,
            }}
          />
          <h1 style={titleStyle}>{titre}</h1>
          {graphs}
          <br />
          <br />
          <br /><br /><br /><br />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
