import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const DirectRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/gallerie');
  }, []); 

  return null; 
}

export default DirectRoute;
