import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  histoPeriode: "30",
  histoSecteurActivite: "Tous les secteurs",
  histoTypePoste: "Tous les types",
  histoZoneAdministrative: "Toute la région", // pour gallerie
};

export const histoStoreSlice = createSlice({
  name: "historiqueStore",
  initialState,
  reducers: {
    histoSetPeriode: (state, action) => {
      state.histoPeriode = action.payload;
    },
    histoSetSecteurActivite: (state, action) => {
      state.histoSecteurActivite = action.payload;
    },
    histoSetTypePoste: (state, action) => {
      state.histoTypePoste = action.payload;
    },
    histoSetZoneAdministrative: (state, action) => {
      state.histoZoneAdministrative = action.payload;
    },
  },
});

export const {
  histoSetPeriode,
  histoSetSecteurActivite,
  histoSetTypePoste,
  histoSetZoneAdministrative,
} = histoStoreSlice.actions;

export default histoStoreSlice.reducer;
