import React from 'react'
import Button from '@mui/material/Button';
import plus from './assets/plus.png'
import minus from './assets/minus.png'
import { tokens } from '../../../theme';
import { useTheme } from '@mui/material';

const ZoomCtrl = (props) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dropdownButtonColor = '#f2f0f0'
  const menuItemTextColor = colors.primary[600]

  const plusDiv = () => {
    return <img src={plus} width={15} style={{ margin: '7px 7px 1px 7px', filter: colors.primary[600] === "white" && 'brightness(0) invert(1)' }} />
  }
  const minusDiv = () => {
    return <img src={minus} width={11} style={{ margin: '9px 8.5px 2px 9px', filter: colors.primary[600] === "white" && 'brightness(0) invert(1)' }} />
  }


  const zoomControl = (sign) => {

    return (
      <>
        <Button
          id="zoom-control"
          aria-controls="zoom-control"
          aria-haspopup="true"
          onClick={() => props.zoomInOrOut(sign)}
          sx={{
            color: menuItemTextColor,
            border: '0.5px solid rgb(133, 132, 132)',
            borderRadius: '1px',
            backgroundColor: colors.primary[400],
            '&:hover': { backgroundColor: dropdownButtonColor },
            textTransform: 'capitalize',
            textShadow: '0.5px 0.5px 0.5px 0.5px #040404',
            border: '0.5px solid rgba(175,175,175,0.6)',
            borderRadius: 0.5,
            minWidth: 0,
            padding: 0
          }}
        >
          <div style={{ opacity: 0.8 }}>
            {sign === 'minus' ? minusDiv() : plusDiv()}
          </div>
        </Button>
      </>
    )
  }

  return (
    <div className='zoom-container'>
      {zoomControl('plus')}
      <p style={{ margin: 10 }} />
      {zoomControl('minus')}
    </div>
  )
}

export default ZoomCtrl