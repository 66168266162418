import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { removeGraphFromPDF } from "../../reducers/dataStore"
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';

const Selections = () => {

  const [previewImage, setPreviewImage] = useState(null);
  const tableContainerRef = useRef(null);

  const dispatch = useDispatch();
  const graphsForPDF = useSelector((state) => state.dataStore.graphsForPDF);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const buttonStyles = {
    marginLeft: "10px",
    width: "25px",
    height: "25px",
    backgroundColor: colors.redAccent[600],
    border: "0px solid grey",
    borderRadius: "50%",
  }

  const previewButtonStyles = {
    width: "80px",
    height: "25px",
    border: "0px solid grey",
    borderRadius: "5px",
    backgroundColor: colors.greenAccent[600],
  }

  const tableStyles = {
    margin: '20px 10%',
    position: 'relative',
    border: "2px solid #ccc",
    borderRadius: "8px",
  }

  const removeSelection = (index) => {
    dispatch(removeGraphFromPDF(index));
    console.log(graphsForPDF)
  }

  return (
    <div style={tableStyles} ref={tableContainerRef}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: colors.primary[400] }}>
              <TableCell style={{ width: "25%" }}><Typography fontWeight="bold" variant="h5">Graphique</Typography></TableCell>
              <TableCell style={{ width: "15%" }}><Typography fontWeight="bold" variant="h5">Période</Typography></TableCell>
              <TableCell style={{ width: "15%" }}><Typography fontWeight="bold" variant="h5">Comté</Typography></TableCell>
              <TableCell style={{ maxWidth: "20%" }}><Typography fontWeight="bold" variant="h5">Secteur</Typography></TableCell>
              <TableCell style={{ maxWidth: "20%" }}><Typography fontWeight="bold" variant="h5">Type de poste</Typography></TableCell>
              <TableCell style={{ maxWidth: "10%" }}><Typography fontWeight="bold" variant="h5"></Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {graphsForPDF.map((graph, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 1 && colors.primary[400] }}>
                <TableCell>{graph.titre}</TableCell>
                <TableCell>{graph.periode}</TableCell>
                <TableCell>{graph.comte}</TableCell>
                <TableCell>{graph.secteurActivite}</TableCell>
                <TableCell>{graph.typePoste}</TableCell>
                <TableCell style={{ display: "flex" }}>
                  <Tooltip
                    theme="light"
                    trigger="mouseenter"
                    arrow={true}
                    interactive={false}
                    maxWidth={500}
                    position="left-end"
                    html={
                      <div style={{
                        borderRadius: "8px",
                        backgroundColor: "white",
                      }}>
                        <div style={{
                          padding: '10px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          background: 'rgba(255, 255, 255, 0.8)',
                        }}>
                          {graph.titre}
                        </div>
                        <div style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: "8px",
                        }}>
                          <img
                            src={graph.png}
                            alt={graph.titre}
                            style={{
                              maxWidth: '80%',
                              maxHeight: '80%',
                            }}
                          />
                        </div>
                      </div>
                    }
                  >
                    <button style={previewButtonStyles}>Aperçu</button>
                  </Tooltip>

                  <button style={buttonStyles} onClick={() => { removeSelection(index) }}>x</button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

  );
}

export default Selections;