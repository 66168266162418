const regionsOpacity = 0.04
const couleursRegions = [
  ["Lanaudière", `rgba(0, 0, 230, ${regionsOpacity + 0.08})`], // blue
  ["Montérégie", `rgba(230, 0, 0, ${regionsOpacity + 0.13})`], // red
  ["Laval", `rgba(80, 200, 180, ${regionsOpacity + 0.17})`], // green-blue
  ["Montréal", `rgba(0, 160, 0, ${regionsOpacity + 0.15})`], // green
  ["Laurentides", `rgba(230, 160, 0, ${regionsOpacity + 0.17})`], // orange
  ["Centre-du-Québec", `rgba(80, 180, 200, ${regionsOpacity + 0.17})`], // turquoise
  ["Mauricie", `rgba(0, 140, 0, ${regionsOpacity + 0.25})`], // green
  ["Estrie", `rgba(230, 160, 0, ${regionsOpacity + 0.16})`], // orange
  ["Capitale-Nationale", `rgba(230, 0, 0, ${regionsOpacity + 0.13})`], // red
  ["Outaouais", `rgba(230, 0, 0, ${regionsOpacity + 0.15})`], // red
  ["Abitibi-Témiscamingue", `rgba(230, 140, 0, ${regionsOpacity + 0.16})`], // orange
  ["Nord-du-Québec", `rgba(80, 200, 180, ${regionsOpacity + 0.17})`], // green-blue
  ["Saguenay--Lac-Saint-Jean", `rgba(230, 140, 0, ${regionsOpacity + 0.16})`], // orange
  ["Côte-Nord", `rgba(0, 130, 0, ${regionsOpacity + 0.15})`], // dark green
  ["Bas-Saint-Laurent", `rgba(80, 180, 200, ${regionsOpacity + 0.17})`], // turquoise
  ["Chaudière-Appalaches", `rgba(150, 0, 150, ${regionsOpacity + 0.15})`], // purple
  ["Gaspésie--Îles-de-la-Madeleine", `rgba(0, 0, 230, ${regionsOpacity + 0.08})`], // blue
];

const choroplethOpacity = 0.5555555555555555
const couleursChoropleth = [
  `rgba(245, 245, 245, ${choroplethOpacity})`, // Light Gray
  `rgba(235, 115, 0, ${choroplethOpacity -0.1})`, // Orange
  `rgba(225, 185, 0, ${choroplethOpacity - 0.1})`, // Gold (a transition color closer to orange)
  `rgba(180, 205, 10, ${choroplethOpacity})`, // Green Yellow (closer to green)
  `rgba(64, 210, 60, ${choroplethOpacity})` // Yellow Green (less light/dark green)
]

export { couleursRegions, couleursChoropleth }

/* 

// archive:

`rgba(245, 245, 245, ${choroplethOpacity})`, // Light Gray
`rgba(255, 165, 0, ${choroplethOpacity})`, // Orange
`rgba(255, 215, 0, ${choroplethOpacity})`, // Gold (a transition color closer to orange)
`rgba(173, 255, 47, ${choroplethOpacity})`, // Green Yellow (closer to green)
`rgba(154, 205, 50, ${choroplethOpacity - 0.1})` // Yellow Green (less light/dark green)
 */