const mrcNamesList = [
    {
        "MRC": "Bellechasse",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "Antoine-Labelle",
        "REG": "Laurentides"
    },
    {
        "MRC": "La Vallée-de-la-Gatineau",
        "REG": "Outaouais"
    },
    {
        "MRC": "Rivière-du-Loup",
        "REG": "Bas-Saint-Laurent"
    },
    {
        "MRC": "Le Haut-Richelieu",
        "REG": "Montérégie"
    },
    {
        "MRC": "Lotbinière",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "La Nouvelle-Beauce",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "Beauce-Sartigan",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "Rouville",
        "REG": "Montérégie"
    },
    {
        "MRC": "Le Granit",
        "REG": "Estrie"
    },
    {
        "MRC": "La Vallée-du-Richelieu",
        "REG": "Montérégie"
    },
    {
        "MRC": "Les Jardins-de-Napierville",
        "REG": "Montérégie"
    },
    {
        "MRC": "Le Haut-Saint-Laurent",
        "REG": "Montérégie"
    },
    {
        "MRC": "Arthabaska",
        "REG": "Centre-du-Québec"
    },
    {
        "MRC": "L'Érable",
        "REG": "Centre-du-Québec"
    },
    {
        "MRC": "Marguerite-D'Youville",
        "REG": "Montérégie"
    },
    {
        "MRC": "Abitibi",
        "REG": "Abitibi-Témiscamingue"
    },
    {
        "MRC": "Beauce-Centre",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "Les Etchemins",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "Montréal",
        "REG": "Montréal"
    },
    {
        "MRC": "Témiscouata",
        "REG": "Bas-Saint-Laurent"
    },
    {
        "MRC": "Drummond",
        "REG": "Centre-du-Québec"
    },
    {
        "MRC": "Les Basques",
        "REG": "Bas-Saint-Laurent"
    },
    {
        "MRC": "Les Appalaches",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "Les Sources",
        "REG": "Estrie"
    },
    {
        "MRC": "Les Laurentides",
        "REG": "Laurentides"
    },
    {
        "MRC": "Québec",
        "REG": "Capitale-Nationale"
    },
    {
        "MRC": "La Jacques-Cartier",
        "REG": "Capitale-Nationale"
    },
    {
        "MRC": "Lévis",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "Les Collines-de-l'Outaouais",
        "REG": "Outaouais"
    },
    {
        "MRC": "Gatineau",
        "REG": "Outaouais"
    },
    {
        "MRC": "Argenteuil",
        "REG": "Laurentides"
    },
    {
        "MRC": "Roussillon",
        "REG": "Montérégie"
    },
    {
        "MRC": "La Rivière-du-Nord",
        "REG": "Laurentides"
    },
    {
        "MRC": "La Mitis",
        "REG": "Bas-Saint-Laurent"
    },
    {
        "MRC": "Rimouski-Neigette",
        "REG": "Bas-Saint-Laurent"
    },
    {
        "MRC": "Manicouagan",
        "REG": "Côte-Nord"
    },
    {
        "MRC": "Papineau",
        "REG": "Outaouais"
    },
    {
        "MRC": "Avignon",
        "REG": "Gaspésie--Îles-de-la-Madeleine"
    },
    {
        "MRC": "Kamouraska",
        "REG": "Bas-Saint-Laurent"
    },
    {
        "MRC": "Matawinie",
        "REG": "Lanaudière"
    },
    {
        "MRC": "La Matanie",
        "REG": "Bas-Saint-Laurent"
    },
    {
        "MRC": "Bécancour",
        "REG": "Centre-du-Québec"
    },
    {
        "MRC": "Portneuf",
        "REG": "Capitale-Nationale"
    },
    {
        "MRC": "La Matapédia",
        "REG": "Bas-Saint-Laurent"
    },
    {
        "MRC": "La Haute-Côte-Nord",
        "REG": "Côte-Nord"
    },
    {
        "MRC": "Charlevoix-Est",
        "REG": "Capitale-Nationale"
    },
    {
        "MRC": "Le Fjord-du-Saguenay",
        "REG": "Saguenay--Lac-Saint-Jean"
    },
    {
        "MRC": "Lac-Saint-Jean-Est",
        "REG": "Saguenay--Lac-Saint-Jean"
    },
    {
        "MRC": "Memphrémagog",
        "REG": "Estrie"
    },
    {
        "MRC": "Brome-Missisquoi",
        "REG": "Estrie"
    },
    {
        "MRC": "Le Val-Saint-François",
        "REG": "Estrie"
    },
    {
        "MRC": "Acton",
        "REG": "Montérégie"
    },
    {
        "MRC": "La Haute-Yamaska",
        "REG": "Estrie"
    },
    {
        "MRC": "Les Maskoutains",
        "REG": "Montérégie"
    },
    {
        "MRC": "Le Haut-Saint-François",
        "REG": "Estrie"
    },
    {
        "MRC": "Coaticook",
        "REG": "Estrie"
    },
    {
        "MRC": "Longueuil",
        "REG": "Montérégie"
    },
    {
        "MRC": "La Côte-de-Beaupré",
        "REG": "Capitale-Nationale"
    },
    {
        "MRC": "L'Île-d'Orléans",
        "REG": "Capitale-Nationale"
    },
    {
        "MRC": "Les Chenaux",
        "REG": "Mauricie"
    },
    {
        "MRC": "Montmagny",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "L'Islet",
        "REG": "Chaudière-Appalaches"
    },
    {
        "MRC": "Maskinongé",
        "REG": "Mauricie"
    },
    {
        "MRC": "Shawinigan",
        "REG": "Mauricie"
    },
    {
        "MRC": "Charlevoix",
        "REG": "Capitale-Nationale"
    },
    {
        "MRC": "Les Pays-d'en-Haut",
        "REG": "Laurentides"
    },
    {
        "MRC": "Le Domaine-du-Roy",
        "REG": "Saguenay--Lac-Saint-Jean"
    },
    {
        "MRC": "Pontiac",
        "REG": "Outaouais"
    },
    {
        "MRC": "Témiscamingue",
        "REG": "Abitibi-Témiscamingue"
    },
    {
        "MRC": "Saguenay",
        "REG": "Saguenay--Lac-Saint-Jean"
    },
    {
        "MRC": "Jamésie",
        "REG": "Nord-du-Québec"
    },
    {
        "MRC": "Beauharnois-Salaberry",
        "REG": "Montérégie"
    },
    {
        "MRC": "Joliette",
        "REG": "Lanaudière"
    },
    {
        "MRC": "Sherbrooke",
        "REG": "Estrie"
    },
    {
        "MRC": "La Tuque",
        "REG": "Mauricie"
    },
    {
        "MRC": "La Vallée-de-l'Or",
        "REG": "Abitibi-Témiscamingue"
    },
    {
        "MRC": "D'Autray",
        "REG": "Lanaudière"
    },
    {
        "MRC": "Montcalm",
        "REG": "Lanaudière"
    },
    {
        "MRC": "L'Assomption",
        "REG": "Lanaudière"
    },
    {
        "MRC": "Laval",
        "REG": "Laval"
    },
    {
        "MRC": "Les Moulins",
        "REG": "Lanaudière"
    },
    {
        "MRC": "Abitibi-Ouest",
        "REG": "Abitibi-Témiscamingue"
    },
    {
        "MRC": "Vaudreuil-Soulanges",
        "REG": "Montérégie"
    },
    {
        "MRC": "Mékinac",
        "REG": "Mauricie"
    },
    {
        "MRC": "Nicolet-Yamaska",
        "REG": "Centre-du-Québec"
    },
    {
        "MRC": "Mirabel",
        "REG": "Laurentides"
    },
    {
        "MRC": "Thérèse-De Blainville",
        "REG": "Laurentides"
    },
    {
        "MRC": "Deux-Montagnes",
        "REG": "Laurentides"
    },
    {
        "MRC": "Maria-Chapdelaine",
        "REG": "Saguenay--Lac-Saint-Jean"
    },
    {
        "MRC": "Pierre-De Saurel",
        "REG": "Montérégie"
    },
    {
        "MRC": "Rouyn-Noranda",
        "REG": "Abitibi-Témiscamingue"
    },
    {
        "MRC": "Nouveau toponyme à venir",
        "REG": "Nord-du-Québec"
    },
    {
        "MRC": "Administration régionale Kativik",
        "REG": "Nord-du-Québec"
    },
    {
        "MRC": "Trois-Rivières",
        "REG": "Mauricie"
    },
    {
        "MRC": "La Haute-Gaspésie",
        "REG": "Gaspésie--Îles-de-la-Madeleine"
    },
    {
        "MRC": "Le Rocher-Percé",
        "REG": "Gaspésie--Îles-de-la-Madeleine"
    },
    {
        "MRC": "Bonaventure",
        "REG": "Gaspésie--Îles-de-la-Madeleine"
    },
    {
        "MRC": "La Côte-de-Gaspé",
        "REG": "Gaspésie--Îles-de-la-Madeleine"
    },
    {
        "MRC": "Sept-Rivières",
        "REG": "Côte-Nord"
    },
    {
        "MRC": "Caniapiscau",
        "REG": "Côte-Nord"
    },
    {
        "MRC": "Le Golfe-du-Saint-Laurent",
        "REG": "Côte-Nord"
    },
    {
        "MRC": "Communauté maritime des Îles-de-la-Madeleine",
        "REG": "Gaspésie--Îles-de-la-Madeleine"
    },
    {
        "MRC": "Minganie",
        "REG": "Côte-Nord"
    }
]

export default mrcNamesList