import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  jobListings: undefined,
  entrepriseId: undefined,
  infoEntreprises: undefined,
  nettoyage: undefined,
  topCompetences: undefined,
  userEmail: "",
  userIsReadyToFetch: true,
  isTryingToAccessRouteDirectly: false,
  loadingProgress: 0,
  computerIsThinking: false,
  graphsForPDF: []
}

// lorsque le data est loadé, cette fonction ajoute des champs secteur_activite
// et type_de_poste au data
const addSecteurAndSousSecteurToData = (data, nettoyage) => {
  nettoyage.forEach((n) => {
    n['Résultat'] = n['Résultat'].toLowerCase();
  });
  
  data.forEach((d) => {
    const appelationLower = d['appelation'].toLowerCase();
    const nettoyageMatch = nettoyage.find(n => n['Résultat'].toLowerCase() === appelationLower);

    if (nettoyageMatch) {
      d.secteur_activite = nettoyageMatch.Famille;
      d.sous_secteur = nettoyageMatch.Groupe;
    }
  });

  return data
};

export const dataStoreSlice = createSlice({
  name: 'dataStore',
  initialState,
  reducers: {
    setIsTryingToAccessRouteDirectly: (state, action) => {
      state.isTryingToAccessRouteDirectly = action.payload
    },
    setInfoEntreprises: (state, action) => {
      const entreprises = addSecteurAndSousSecteurToData(action.payload, state.nettoyage)
      state.infoEntreprises = entreprises
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload
    },
    setUserIsReadyToFetch: (state, action) => {
      state.userIsReadyToFetch = action.payload;
    },
    setjobListings: (state, action) => {
      const listings = addSecteurAndSousSecteurToData(action.payload, state.nettoyage)
      state.jobListings = listings.reduce((acc, item) => {
        if (!acc[item.region])
          acc[item.region] = []
        acc[item.region].push(item)
        return acc;
      }, {})
    },
    setEntrepriseId: (state, action) => {
      state.entrepriseId = action.payload
    },
    setNettoyage: (state, action) => {
      state.nettoyage = action.payload
    },
    setTopCompetences: (state, action) => {
      const competences = addSecteurAndSousSecteurToData(action.payload, state.nettoyage)
      state.topCompetences = competences
    },
    setLoadingProgress: (state, action) => {
      state.loadingProgress = action.payload
    },
    setComputerIsThinking: (state, action) => {
      state.computerIsThinking = action.payload
    },
    addGraphsForPDF: (state, action) => {
      state.graphsForPDF.push(action.payload)
    },
    removeGraphFromPDF: (state, action) => {
      // action.payload is the index of the graph to remove
      state.graphsForPDF.splice(action.payload, 1)
    }
  }
})

export const {
  setIsTryingToAccessRouteDirectly,
  setUserEmail,
  setUserIsReadyToFetch,
  setjobListings,
  setEntrepriseId,
  setNettoyage,
  setInfoEntreprises,
  setTopCompetences,
  setLoadingProgress,
  setComputerIsThinking,
  addGraphsForPDF,
  removeGraphFromPDF
} = dataStoreSlice.actions;

export default dataStoreSlice.reducer;