// **********************************************************
// Fonctions de calculs pour le dashboard
// **********************************************************

// fonction pour filtrer la période ciblée:
const filterDataByDate = (data, nbDays) => {
  const dayMs = 86400000 //milliseconds
  const currentDate = new Date();
  const current = data?.filter((item) => {
    const date = parseDate(item.publiee_depuis_le);
    const timeDiff = currentDate.getTime() - date.getTime();
    return timeDiff <= dayMs * nbDays;
  }); // retourne le data dans le même format mais seulement pour la période cible

  const previous = data?.filter((item) => {
    const date = parseDate(item.publiee_depuis_le);
    const timeDiff = currentDate.getTime() - date.getTime();
    return timeDiff > dayMs * nbDays && timeDiff <= dayMs * (nbDays * 2);
  }); // retourne le data dans le même format mais seulement pour la période précédent la période cible

  return { current, previous };
};

const addPreviousToCurrent = (previous, current, nbDays) => {
  const updatedCurrent = current.map(obj => ({...obj})); // create a copy of the current array

  for (let i = 0; i < updatedCurrent.length; i++) {
    const currentDate = new Date(updatedCurrent[i].name); // convert the current object's date string to a Date object
    const previousDate = new Date(currentDate.getTime() - nbDays * 24 * 60 * 60 * 1000); // calculate the previous date by subtracting nbDays from the current date and convert it to a Date object
    const previousName = previousDate.toDateString(); // convert the previous date object to a string format that matches the `name` property in the `previous` array
    const previousObj = previous.find(obj => obj.name === previousName); // find the object in the `previous` array that has a `name` property equal to the calculated `previousName`

    if (previousObj) { // if such an object exists, add its values to the current period data
      updatedCurrent[i].previous_date = previousObj.name
      updatedCurrent[i].previous_value = previousObj.value
      updatedCurrent[i].previous_weight = previousObj.weight
    } else {
      updatedCurrent[i].previous_date = previousName
      updatedCurrent[i].previous_value = 0
      updatedCurrent[i].previous_weight = 0
    }
  }

  return updatedCurrent;
};

const filterDataForChosenMrc = (data, zoneAdministrative) => {
  if (zoneAdministrative !== 'Toute la région')
    return data?.filter(item => item.MRC === zoneAdministrative)
  else return data
}

const filterDataBySecteur = (data, secteur, typePoste) => {
  var filteredData = []

  if (secteur !== 'Tous les secteurs')
    filteredData = data.filter(d => d.secteur_activite === secteur);
  else filteredData = data

  if (typePoste !== 'Tous les types' && typePoste !== undefined)
    filteredData = filteredData.filter(d => d.sous_secteur === typePoste)

  return filteredData
}

// fonction pour calculer la moyenne d'un champs, recoit une liste d'objets
const calculerMoyennePourChamps = (data, champs, ignoreNumber) => {
  const filteredData = data.filter((item) => { return item[champs] !== ignoreNumber && !isNaN(item[champs]) })
  const somme = filteredData.reduce((acc, item) => { return acc + item[champs] }, 0)
  const moyenne = somme / filteredData.length;
  return moyenne // retourne simplement la moyenne pour la liste
}

// fonction qui retourne une liste d'objets des items classés par date
const calculateDataOrderedByDate = (data, champsDate) => {

  const dataByDate = {};
  data?.forEach((item) => {
    const date = parseDate(item[champsDate]).toDateString();
    if (!dataByDate[date]) dataByDate[date] = []
    dataByDate[date].push(item)
  })

  return dataByDate
}

// fonction qui trouve les secteurs d'activités existant dans le data pour le dropdown
const deduireListeSecteursActivites = (data) => {
  const familles = {};

  data.forEach((d) => {
    const famille = d['secteur_activite'];
    const groupe = d['sous_secteur'];

    if (famille) {
      if (!familles[famille]) { familles[famille] = { secteur: famille, Groupes: [] } }
      if (groupe && !familles[famille].Groupes.includes(groupe)) { familles[famille].Groupes.push(groupe) }
    }
  });

  const result = Object.values(familles);
  result.forEach((r) => { r.secteur = r.secteur ? r.secteur : 'Autre' });
  result.sort((a, b) => a.secteur.localeCompare(b.secteur));
  result.unshift({ secteur: 'Tous les secteurs', Groupes: [] })

  return result.map((r) => { return { secteur: r.secteur, type: ['Tous les types', ...r.Groupes] } });
};

// fonction qui retire des datapoints non-conformes
const cleanUpData = (data) => {
  const filteredData = data.filter((item) => {
    return item.salaire_min <= 99;
  });
  return filteredData;
};

/* const filterDataForSelectedMrcs = (data, selectedMrcs) => {
  return data?.filter(item => !selectedMrcs.includes(item.MRC));
} */

// -------------------------------------------------------------
// -------------------- utility functions ----------------------
// -------------------------------------------------------------

// transforme une valeur non-numérique en int = 1
const anythingToInt = (i) => { if (typeof i !== 'number') { return 1 } else { return parseInt(i) } }

// formatting de la date de la collection en format pris en charge par javaScript
function parseDate(dateStr) {
  const [month, year, day] = dateStr.replace(',', '').split(' ');
  const monthIndex = {
    'January': 0, 'February': 1, 'March': 2, 'April': 3, 'May': 4, 'June': 5,
    'July': 6, 'August': 7, 'September': 8, 'October': 9, 'November': 10, 'December': 11,
  }[month];
  return new Date(Date.UTC(year, monthIndex, day));
}

// returns the range of days between the earliest and latest values present in the data and the limits
function findRangeOfDays(data) {
  const dateRange = data.reduce((acc, curr) => {
    const dateString = curr.name;
    const date = new Date(dateString); // parse the string date to a Date object
    if (date < acc.earliest) {
      acc.earliest = date;
    }
    if (date > acc.latest) {
      acc.latest = date;
    }
    return acc;
  }, { earliest: new Date(), latest: new Date(0) });

  const rangeInDays = Math.round((dateRange.latest.getTime() - dateRange.earliest.getTime()) / (24 * 60 * 60 * 1000));
  return [rangeInDays, dateRange.earliest.getTime(), dateRange.latest.getTime()]
}

export {
  cleanUpData,
  findRangeOfDays,
  filterDataByDate,
  parseDate,
  addPreviousToCurrent,
  //filterDataForSelectedMrcs,
  deduireListeSecteursActivites,
  filterDataBySecteur,
  filterDataForChosenMrc,
  calculerMoyennePourChamps,
  calculateDataOrderedByDate,
  anythingToInt
}